import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import './Login.css';


 const RefundAndCancellation = () => {
return(
 <div>
    <Header />
     <main className="wrapper">
      {/* Breadcrumb Start */}
      <section
        className="aai-breadcrumb"
        // style={{
        //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="text-center">
                <h2 className="aai-page-title"> Cancellation and Refund </h2>
                <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                  <li>
                  <a href="/" className="aai-breadcrumb-link text-decoration-underline">
                      Home
                    </a>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 16L18 12M18 12L14 8M18 12L6 12"
                        stroke="#DADADA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>

                  <li>
                    <a href="" className="aai-breadcrumb-link">  Cancellation and Refund </a>
                  </li>
                </ul>
    <div className="refund-cancel-class">

        <h>OneAIChat Cancellation and Refund</h>
          <p className="section-desc">The following terms and conditions (the “Subscriber Terms”) apply when you subscribe to a paid Kaletech subscription service, including a Kaletech+ or OneAIChat paid subscription (the “Subscription Services”). By subscribing, you consent to these Subscriber Terms.

          Use of the Kaletech or OneAIChat services are also subject to the Kaletech Terms of Service, OneAIChat Terms of Service (collectively, the “Terms of Service”), as applicable, and our Privacy Policy. All capitalized terms in these Subscriber Terms have the same meaning as set forth in the Terms of Service, except as otherwise noted.

          Please read the Subscriber Terms carefully. If you do not agree with or accept any part of these Subscriber Terms, you should not sign up for a Subscription.

          IMPORTANT ARBITRATION NOTICE: IF YOU ARE IN THE INDIA, YOU AGREE THAT DISPUTES BETWEEN YOU AND Kaletech WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. WE EXPLAIN SOME EXCEPTIONS AND HOW YOU CAN OPT OUT OF ARBITRATION IN SECTION 10 OF THE Kaletech TERMS OF SERVICE.</p>

      <ol className="section-desc">
        <li><h>Subscription Services & Conditions</h>
        <div>
        <strong>1.1 Subscription Services:</strong> 
        <p className="section-desc">Kaletech+: a Subscription to Kaletech+ enables you, the subscriber, to access premium content (“Subscription Content”) from and reward participating Kaletech writers or Space owners
          (“Participating Creators”). Subscription Content is deemed “Our Content and Materials” under the Terms of Service. All rights, title, and interest in a Subscription (including any Subscription Content)
            not expressly granted to you in these Subscriber Terms are reserved by Kaletech, Participating Creators, and their respective licensors.
            OneAIChat: a Subscription to OneAIChat enables you certain privileges such as increased messaging capacity beyond what is offered for the free version of the OneAIChat service. Kaletech has a set monthly message usage limit for certain bots on OneAIChat.
            Subscribers will receive 600 GPT-4 and 1000 Claude-2-100k messages per month, after these message limits are reached messaging speed, bot availability or quality may be reduced. The monthly message limit will be displayed at the time you purchase 
            a OneAIChat Subscription. You can check your monthly message usage by visiting Settings in the OneAIChat app. Message usage limits reset monthly on the anniversary of your OneAIChat Subscription purchase.</p>
          </div>
          <div>
            <strong>1.2. Conditions:</strong> 
            <p className="section-desc">Your access and use of Subscription Services is subject to the following conditions:</p>
            <ul className="section-desc">
              <li>Receipt by Kaletech of timely payment of any Subscription fees. As described in Section 2, you will not have access to Subscription Services if you cancel or fail to pay for a Subscription, including if your payment method becomes invalid.</li>
              <li>You will maintain adequate security measures for your account and may not share your Kaletech account password with someone else to allow them to access the Subscription.</li>
              <li>Except as permitted by the Terms of Service and Participating Creators, you may not sell, rent, sub-license, or lend Subscription Content.</li>
              <li>You are responsible for maintaining the security and accuracy of your Kaletech account, and you will be charged for any Subscriptions placed on or through your Kaletech account, including without limitation Subscriptions placed without sufficient authorization, or through mistake or error.</li>
              <li>You will not present any false, inaccurate or misleading information in an effort to misrepresent your location or place of residence, and you will not attempt to circumvent any restrictions on access to or availability of the Subscription Services or content available within the Subscription Services.</li>
            </ul>
          </div>
        </li>      

        <li><h>Payments and Billing</h>
        <div>
        <strong>2.1 General:</strong> 
        <div><p>Fees. All fees and charges will be described to you when you sign-up for a Subscription Service. You agree to pay the price stated at the time of purchase, as well 
          as any applicable taxes. You also agree to the billing frequency as stated at the time of purchase.</p></div>
          <div>
            <p>Taxes. If Kaletech is required to collect or pay any taxes in connection with your purchase of a Subscription, such taxes will be charged to you at the time of each purchase transaction or other 
            appropriate time as applicable. The Subscription fees will not include Value Added Tax (“VAT”) or Goods & Services Tax (“GST”). If you subscribed via a third party, such as Apple or GooglePlay, taxes, including VAT or GST,
            may be collected upon purchase. Additionally, if required by law, you are responsible for reporting and paying certain taxes in connection with your purchase and use of Subscriptions. Such taxes may include duties, customs fees, or 
            other taxes (other than income tax), along with any related penalties or interest, as applicable to your purchase or country of purchase.
          </p>
          </div>
          <div>
            <p>Payment Methods. In order to purchase for a Subscription Service, you must provide account information for at least one valid debit or credit card or other payment method through or to the Kaletech Platform. 
              You may receive a temporary authorization charge to validate your payment method. Kaletech uses this payment method account information as described in our privacy policy. If you subscribed via a third party, such as Apple or GooglePlay, your provision of such debit or credit card shall be subject to the terms and conditions specified by such third party. Accepted payment methods 
              may vary based on your location. Kaletech takes no responsibility and assumes no liability for any actions or omissions of such third party.</p>
          </div>
          <div>
            <p>You represent and warrant that you have the legal right to use any payment method used by you in connection with any Subscription.

              Payment Processors. Kaletech works with third-party service providers to perform payment processing, including card processing and currency exchange (“Payment Agent”). Any payment processing will be subject to the terms and privacy policy of the Payment Agent. Kaletech may share transaction information (namely, the amount of payment due) with our Payment Agent, but not credit card information, which you provide to the Payment Agent directly and not to Kaletech.

              Currencies. All prices are in India Rupees or another currency specified by Kaletech at the time of purchase. The currency used to charge you for Subscription payments depends on the billing country where your payment method is located. You agree to and acknowledge that Kaletech cannot control any currency conversion or foreign transaction fees that may be charged to you by your card issuer or bank for your Subscription payment.

              Restricted Countries. There may be countries that restrict or prohibit your ability to make payments through the Kaletech Platform or third-parties, such as Apple or GooglePlay. Nothing in these Subscriber Terms should be interpreted to override or circumvent any such foreign laws.

              Unauthorized Charges. Without limitation of the above terms, if you believe that a problematic transaction has taken place under your account, you agree to notify us immediately, so that we may take action to prevent financial loss.

              Data Charges. When you use your Subscription, you may incur other additional charges from third party service providers, such as telecommunications fees or data fees. You are responsible for paying any additional charges.
              </p>
               
              <strong>2.2 Recurring Charges and Refunds:</strong>
              <p>
              Recurring Charges. If you purchase an annual or monthly Subscription, you authorize Kaletech to charge you the annual or monthly Subscription fee as selected by you in the course of the transaction at purchase and each year or month thereafter on the annual or monthly anniversary of the start of your Subscription, respectively, using the payment method you provided at the then-current rate.

              You acknowledge that a Subscription has recurring payment features. You accept responsibility for all recurring payment obligations prior to cancellation of your Subscription. Your Subscription continues until canceled by you in the manner set forth in Section 2.5 below or until we terminate your access to, or use of, a Subscription in accordance with these Subscriber Terms.

              Refunds. All Subscription fees or other final charges are non-refundable, other than as set forth in our Refund Policy or as determined by Kaletech. If you subscribed via a third party, such as Apple or GooglePlay, please contact such service to understand their refund policies.

              Third Party Purchases. Subscriptions and other purchases through a third party (e.g., Google Play, iOS or PayPal) are managed directly by the applicable third party. You should consult with the appropriate third party to determine if the price charged includes all applicable taxes and currency exchange settlements. You are solely responsible for paying such taxes or other charges. Those third parties collect one-time and subscription fees, manage the subscription, and report to us on the status of subscription accounts that have been purchased from them. We do not have the ability to initiate, cancel, or refund such a third-party subscription on your behalf, but we do have the ability to make changes to your subscription entitlements in 
              connection with such third-party subscriptions (for example, by turning off your entitlement to a subscription if you canceled it through a third-party).</p>
                </div>          
          </div>
          <div>
            <strong>2.3 Changes to the Price and Subscription Plans:</strong> 
            <p>We may change our subscription plans and the price of our Subscription Services from time to time; however, any price changes or changes to your subscription plans will apply no earlier than 30 days following notice to you. 
              If you do not wish to accept the price change or change to your subscription plan, you can cancel your subscription before the change takes effect.</p>
          </div>

          <div>
            <strong>2.4 Free Trials and Promotional Codes:</strong> 
            <p>Kaletech may offer free trial Subscriptions for a specified period (“Free Trials”). If we offer you a Free Trial, specific terms will be provided at signup or any promotional materials. Unless you cancel your Subscription prior to its end, we will begin charging your payment method after the Free Trial for the applicable monthly or annual recurring Subscription fees selected by you as described in Section 2.1 above. You can cancel your Free Trial or Subscription at any time as described in Section 2.5 below. Limit one Free Trial per person.
              Kaletech may offer promotional codes from time to time. Promotional codes will expire on the date provided in any promotional materials. Only one promotional code may be redeemed per account during each promotion. Promotional codes are not transferable and are not redeemable for cash.</p>
          </div>

          <div>
            <strong>2.5 Cancellation and Termination:</strong> 
            <p>You may cancel a Subscription, including Free Trials, through your account settings at any time. The cancellation will take effect at the end of the Free Trial or the current billing cycle (the “Cancellation Effective Date”). You are responsible for all Subscription fees (plus any applicable taxes or charges) incurred before the Cancellation Effective Date. You will have access to Subscription Content until the Cancellation Effective Date.

              Kaletech may terminate your Subscription for non-payment of Subscription fees or violating these Subscriber Terms or Terms of Service, including the Acceptable Use Policy. If Kaletech terminates your Subscription, your cancellation will be effective immediately, and you will not be able to access Subscription Content.

              In the event of any Subscription cancellation, these Subscriber Terms will terminate, the Terms of Service will survive and continue to apply to your use of the Kaletech Platform. Sections 2, 3, 4, 5 and 6 survive the expiration or termination of these Subscriber Terms.

              In the event you purchase a Subscription through a third party (e.g., Apple or Google), such Subscriptions, billing and cancellation are managed directly by the applicable third party. You can manage subscriptions purchased via Apple on your Apple mobile device, by visiting the Settings App on your device, selecting your Apple ID and then Subscriptions. You can manage subscriptions purchased via Google by visiting the Google Play Store.</p>
          </div>
        </li>
        <li>
        <h>Privacy and Communications</h>
        <p>These Subscriber Terms and our Terms of Service are subject to our Privacy Policy.

          By Subscribing, you consent to receiving email communications from Kaletech about the Subscription to your registered email address or other methods. If you no longer want to receive communications from Kaletech, you can change your communication preferences in your Kaletech account settings.

          You agree that we may communicate with you electronically about any important information regarding your Subscription. We may also provide notices to you by posting them on our website or by sending them to an email address that you previously provided to us. Website and email notices will be considered received by you within 24 hours of the time posted or sent.

          Unless stated otherwise, you must send notices to us relating to these Subscriber Terms to: support@oneaichat.com.</p>
      </li>
        <li>
          <h>Indemnification</h>
          <p>In addition to the indemnification obligations in the Terms of Service, you will indemnify and hold us harmless for any claim, cost, or expenses (including reasonable attorneys’ fees and investigative costs) that arises from an allegation by any third party that: (a) you have committed any act (or omission) that is a violation of these Subscriber Terms; and (b) you have failed to remit any taxes to the applicable governmental authority or to provide accurate information as described in Section 2.1.
        .</p>
        </li>
        <li>
          <h>Miscellaneous</h>
          <p>These Subscriber Terms constitute the entire agreement about your access to a Subscription and supersede any other agreement, oral or written, about this subject matter, except for any prior agreement by you to the Terms of Service. If any provision in these Subscriber Terms is found to be unenforceable, then that provision will not affect the enforceability of the remaining provisions of these Subscriber Terms, which will remain in full force and effect. In the event of any conflict between the Terms of Service and these Subscriber Terms, these Subscriber Terms will govern with respect to your use of a Subscription.

          You may not modify these Subscriber Terms without written agreement from us.

          We may modify these Subscriber Terms, including the Terms of Service, at any time. Unless otherwise required by law, if we amend material terms to these Subscriber Terms, we will notify you via the email address provided by you in your account to give you an opportunity to review any material changes to these Subscriber Terms before they go into effect. Your continued use of a Subscription after non-material changes, or after reasonable notice to you of any material changes to the Subscriber Terms, constitutes acceptance of those changes. If you do not agree to the amendments or to any of the terms in these Subscriber Terms, your only remedy is to cease use of and cancel your Subscription.

          </p>
            </li>
          {/* <h>Contact Us</h>
          <p>If you have any questions about Subscriptions, you can reach us at support@oneaichat.com.
          </p> */}
          </ol>
            </div>
            <h5>Contact Us</h5>
                    <div style={{ width: "100%", float: "left" }} className="section-desc">
                    Please contact our Data Protection Officer at
                    support@oneaichat.com if you have questions about this
                    Privacy Policy.
                    </div>
          </div>
        </div>
      </div>
    </div>
  </section>a
  {/* Breadcrumb End */}
  </main>
  <Footer />
 </div>
    )
 }
 export default RefundAndCancellation;