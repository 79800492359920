import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
const BlogD = () => {
  return (
    <div>
      <Header />
      <main className="wrapper">
        {/* Breadcrumb Start */}
        <section
          className="aai-breadcrumb"
          // style={{
          //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover"
          // }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-6">
                <div className="text-center d-flex flex-column gap-3">
                  <h2 className="section-title">
                    Balancing Innovation and Responsibility With Oneaichat.
                  </h2>
                  <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                    <li>
                      <a
                        href="/"
                        className="aai-breadcrumb-link text-decoration-underline"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 16L18 12M18 12L14 8M18 12L6 12"
                          stroke="#DADADA"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </li>
                    <li>
                      <a href="" className="aai-breadcrumb-link">
                        {" "}
                        Oneaichat Revolution{" "}
                      </a>
                    </li>
                  </ul>
                  <div className="aai-post-meta justify-content-center d-flex flex-wrap gap-3">
                    <a href="#" className="d-flex align-items-center gap-2">
                      <i className="fa-regular fa-user"></i>
                      <span>Admin</span>
                    </a>
                    <a href="#" className="d-flex align-items-center gap-2">
                      <i className="fa-regular fa-clock"></i>
                      <span>13 July, 2023</span>
                    </a>
                    <a href="#" className="d-flex align-items-center gap-2">
                      <i className="fa-regular fa-folder"></i>
                      <span>Oneaichat Revolution</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Breadcrumb End */}
        {/* Blog Details Start */}
        <section className="aai-blog-posts pb-120">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-7 col-xl-8">
                <div className="aai-post-details-wrapper">
                  {/* Details Content */}
                  <div className="aai-post-details-top mb-4">
                    <div>
                      <img
                        src="assets/img/blog/bp-1.webp"
                        className="img-fluid aai-blog-details-thumb mb-5"
                        alt=""
                      />
                      <p className="aai-post-details-text mb-4">
                        Oneaichat is a groundbreaking field of computer science
                        that aims to create intelligent machines capable of
                        performing tasks that typically require human
                        intelligence.
                      </p>
                      <p className="aai-post-details-text">
                        By simulating human cognitive processes, Oneaichat
                        systems can analyze vast amounts of data, identify
                        patterns, make predictions, and learn from experiences,
                        enabling them to continuously improve their performance.
                      </p>
                      <blockquote className="aai-post-blockquote">
                        <p>
                          Oneaichat is a groundbreaking field of computer
                          science that aims to create intelligent machines
                          capable of performing tasks.
                        </p>
                      </blockquote>
                      <p className="aai-post-details-text">
                        Oneaichat has the potential to revolutionize various
                        industries, including healthcare, finance,
                        transportation, and manufacturing, by automating
                        processes, enhancing decision-making, and unlocking new
                        insights.
                      </p>
                    </div>
                  </div>
                  {/* Social Links */}
                  <div className="d-flex align-items-center gap-3 mb-5">
                    <h3 className="aai-post-social-share">Social:</h3>
                    <div className="aai-post-social-links d-flex gap-1">
                      <a className="facebook" data-social="facebook" href="#">
                        <i className="fa-brands fa-facebook"></i>
                      </a>
                      <a className="twitter" data-social="twitter" href="#">
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                      <a className="pinterest" data-social="pinterest" href="#">
                        <i className="fa-brands fa-pinterest-p"></i>
                      </a>
                      <a className="linkedin" data-social="linkedin" href="#">
                        <i className="fa-brands fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                  {/* Comment Form */}
                  <div className="aai-comment-form mt-4">
                    <h3 className="aai-post-comment-note">Leave a Reply</h3>
                    <p className="aai-post-email-note">
                      Your email address will not be published.
                    </p>
                    <form className="mt-4">
                      <div className="aai-comment-input mb-3">
                        <label className="aai-comment-form-label">
                          Comment
                        </label>
                        <textarea className="form-control shadow-none"></textarea>
                      </div>
                      <div className="aai-comment-input mb-3">
                        <label className="aai-comment-form-label">Name</label>
                        <input
                          type="text"
                          className="form-control shadow-none"
                        />
                      </div>
                      <div className="aai-comment-input mb-3">
                        <label className="aai-comment-form-label">Email</label>
                        <input
                          type="text"
                          className="form-control shadow-none"
                        />
                      </div>
                      <div className="aai-comment-input mb-3">
                        <label className="aai-comment-form-label">
                          Website
                        </label>
                        <input
                          type="text"
                          className="form-control shadow-none"
                        />
                      </div>
                      <div className="aai-comment-input d-flex align-items-center gap-3">
                        <input type="checkbox" />
                        <label className="aai-comment-form-label m-0">
                          Save my name, email, and website in this browser for
                          the next time I comment.
                        </label>
                      </div>
                      <div className="mt-4">
                        <button className="aai-btn btn-pill-solid">
                          Post Comment
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-xl-4">
                <aside className="aai-blog-sidebar">
                  {/* Search */}
                  <div className="aai-sidebar-widget mb-4">
                    <form action="">
                      <div className="aai-sidebar-search-from">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          placeholder="Search Here"
                        />
                        <button className="aai-sidebar-search-button">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* Recent Post */}
                  <div className="aai-sidebar-widget mb-4">
                    <h3 className="aai-sidebar-title">Recent Post</h3>
                    <ul className="mt-4 aai-blog-lists d-flex flex-column gap-3">
                      <li className="aai-blog-list-item">
                        <a href="#" className="d-flex align-items-center gap-3">
                          <i className="fa-solid fa-angle-right"></i>
                          <span>
                            Balancing Innovation and Responsibility With
                            Oneaichat.
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="aai-sidebar-widget mb-4">
                    <h3 className="aai-sidebar-title">Upcoming Posts</h3>
                    <ul className="mt-4 aai-blog-lists d-flex flex-column gap-3">
                      <li className="aai-blog-list-item">
                        <a href="#" className="d-flex align-items-center gap-3">
                          <i className="fa-solid fa-angle-right"></i>
                          <span>Shaping the Future of Work and Society.</span>
                        </a>
                      </li>
                      <li className="aai-blog-list-item">
                        <a href="#" className="d-flex align-items-center gap-3">
                          <i className="fa-solid fa-angle-right"></i>
                          <span>
                            Exploring Trends, Challenges, and Opportunities.
                          </span>
                        </a>
                      </li>
                      <li className="aai-blog-list-item">
                        <a href="#" className="d-flex align-items-center gap-3">
                          <i className="fa-solid fa-angle-right"></i>
                          <span>The Evolution of Oneaichat.</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Post Category */}
                  <div className="aai-sidebar-widget">
                    <h3 className="aai-sidebar-title">Category</h3>
                    <ul className="mt-4 aai-blog-lists d-flex flex-column gap-2">
                      <li className="aai-blog-list-item">
                        <a href="#" className="d-flex align-items-center gap-3">
                          <i className="fa-solid fa-angle-right"></i>
                          <span>Oneaichat Revolution(4)</span>
                        </a>
                      </li>
                      <li className="aai-blog-list-item">
                        <a href="#" className="d-flex align-items-center gap-3">
                          <i className="fa-solid fa-angle-right"></i>
                          <span>New Oneaichat(2)</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
        {/* Blog Details End */}
      </main>
      <Footer />
    </div>
  );
};
export default BlogD;
