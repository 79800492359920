// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default styles for mobile */

.position-relative {

    flex-direction: column;
    gap: 10px; /* Space between input and button */
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
  }
  
  .aai-newsletter-btn {
    padding: 8px;
  }
  
  /* Styles for larger screens (web) */
  
  @media (min-width: 768px) {
    .position-relative {
      flex-direction: row;
      align-items: center;
    }
    
    .form-control {
      flex-grow: 1; /* allows the input to take up the available space */
      margin-right: 3px; /* Space between input and button */
    }
  
    .aai-newsletter-btn {
      width: auto;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;;IAEI,sBAAsB;IACtB,SAAS,EAAE,mCAAmC;EAChD;;EAEA;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,YAAY;EACd;;EAEA,oCAAoC;;EAEpC;IACE;MACE,mBAAmB;MACnB,mBAAmB;IACrB;;IAEA;MACE,YAAY,EAAE,oDAAoD;MAClE,iBAAiB,EAAE,mCAAmC;IACxD;;IAEA;MACE,WAAW;IACb;EACF","sourcesContent":["/* Default styles for mobile */\n\n.position-relative {\n\n    flex-direction: column;\n    gap: 10px; /* Space between input and button */\n  }\n  \n  .form-control {\n    width: 100%;\n    padding: 10px;\n  }\n  \n  .aai-newsletter-btn {\n    padding: 8px;\n  }\n  \n  /* Styles for larger screens (web) */\n  \n  @media (min-width: 768px) {\n    .position-relative {\n      flex-direction: row;\n      align-items: center;\n    }\n    \n    .form-control {\n      flex-grow: 1; /* allows the input to take up the available space */\n      margin-right: 3px; /* Space between input and button */\n    }\n  \n    .aai-newsletter-btn {\n      width: auto;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
