import React, { useState, useEffect } from "react";
import styles from "./ChatBoard.module.css";

const ChatBoard = (props) => {
  const { chats, scrollId } = props;
  const [firstChatMessage, setFirstChatMessage] = useState(false);
  const user = "guest";
  useEffect(() => {
    console.log(chats[chats.length - 1], "chats[chats.length - 1]");
    if (chats[chats.length - 1]?.message == "Context Cleared") {
      setFirstChatMessage(true);
    } else {
      setFirstChatMessage(false);
    }
  }, [chats]);

  const getChat = (chat) => {
    let className = "";
    let wrapperClassName = "";
    if (user === chat?.sender) {
      className = styles.sender;
      wrapperClassName = styles.senderWrapper;
    } else if (user !== chat?.sender && chat?.sender === "system") {
      className = styles.receiver;
      wrapperClassName = styles.receiverWrapper;
    }
    if (
      chat?.message ===
        "Sorry, there was an error processing your request. Please try again." ||
      chat?.message === "Sorry, the response took too long. Please try again."
    ) {
      className = `${styles.error}`;
    }
    if (chat?.message == "Context Cleared") {
      return (
        <div className={firstChatMessage ? "swipe-chat" : "context-cleared"}>
          <div class="swipe-left-border"></div>
          <div className="chat-clear" id="chat-clear" key={chat?.timestamp}>
            <div className="">{chat?.message}</div>
          </div>
          <div class="swipe-right-border-landing"></div>
        </div>
      );
    } else {
      return (
        <div className={wrapperClassName} key={chat?.timestamp}>
          <div className={className}>{chat?.message}</div>
        </div>
      );
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container} id={scrollId}>
        {chats?.map((chat) => getChat(chat))}
      </div>
    </div>
  );
};

export default ChatBoard;
