import React, { useEffect, useState } from "react";
import CheckIcon from "components/Icons/Check";
import Link from "components/Link";
import axios from "axios";
import Header from "components/Header";
import Footer from "components/Footer";
import Testimonials from "components/Slider/Testimonials";
import './Pricing.css';
import { axiosInstance } from "config/axios";
import { Helmet } from "react-helmet";

const Pricing=()=>{
 
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionPrompt, setSubscriptionPrompt] = useState(false);
  const [loginPrompt, setLoginPrompt] = useState(false);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const closeMessage = () => {
    setShowPopup(false);
    setShowMessage(false);
  };
  const [showMessage, setShowMessage] = useState(false);
   
  useEffect(() => {
    axiosInstance.get(`/subscriptions`)
    .then((response) => {
      console.log('Response data:', response.data);
    if (response.data.status) {
    setSubscriptions(response.data.subscriptions);
    } else {
    console.error('Failed to fetch subscriptions');
    }
    })
    .catch((error) => {
    console.error('Error fetching subscriptions:', error);
    });
    }, [])

    // const handlePayClick = async (e, planId, planName, planPrice,stripe_id) => {
   
    //   const isPurchaseCompleted = localStorage.getItem('purchaseCompleted');
    //     if (!isPurchaseCompleted) {
    //       localStorage.setItem('stripeId', stripe_id);
    //       localStorage.setItem('selectedPlanName', planName);
    //       localStorage.setItem('selectedPlanId', planId);
    //       localStorage.setItem('selectedPlanPrice', planPrice);
    //   } 
    //   const authUser = JSON.parse(localStorage.getItem("authUser"));
    //   if (!authUser) {
    //     console.error('AuthUser not found in local storage');
    //     setLoginPrompt(true);  // Show the login prompt message
    //     setShowMessage(true);
    //     e.preventDefault();  // Prevent form submission
    //     setTimeout(() => {
    //       window.location.href = '/login'; // Redirect to login page after 3 seconds
    //     }, 1000);
    //     return;
    //   } else {
    //   // Open the modal when pay button is clicked
    //   setShowPopup(true);
    //   setSelectedPaymentOption(null); // Reset selected payment option
    // };
    // }  


    const [plans, setPlans] = useState([]);
    const [userCountry, setUserCountry] = useState(null); // Initialize userCountry state
    useEffect(() => {
      async function fetchData() {
        try {
          const country = await getUserCountry();
          setUserCountry(country); // Set userCountry state after fetching
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchData();
    }, []);
    const getUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        console.log(response,"country code ipppppp")
        return response.data.country_code;
       
      } catch (error) {
        console.error('Error fetching user country:', error);
        return null;
      }
    };

  const getExchangeRates = async (userCountry) => {
    try {
      const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${userCountry}`);
      return response.data.rates;
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
      return null;
    }
  };

  const updatePrices = (exchangeRates) => {
    if (!exchangeRates) return [];

    const pricesInLocalCurrency = {
      'USD': 1,  // Default currency is USD
      'EUR': exchangeRates.EUR,
      // Add more currencies as needed
    };

    const subscriptionPlans = [
      { name: 'Basic', price: 10 },
      { name: 'Standard', price: 20 },
      { name: 'Premium', price: 30 }
    ];

    return subscriptionPlans.map(plan => {
      const priceInLocalCurrency = plan.price * pricesInLocalCurrency['USD'];
      const formattedPrice = new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: navigator.language
      }).format(priceInLocalCurrency);
      return { ...plan, price: formattedPrice };
    });
  };
    const handlePayClick = async (e, planId, planName, planPrice, stripe_id) => {
      let selectedStripeId;

      // Extract the country code from userCountry
      const countryCode = userCountry.toLowerCase(); // Assuming userCountry is in uppercase
    
      // Access the corresponding property from the stripe_id object based on the country code
      if (countryCode === 'us') {
        selectedStripeId = stripe_id.stripe_id_us;
      } else if (countryCode === 'gb') {
        selectedStripeId = stripe_id.stripe_id_uk;
      } else if (countryCode === 'ru') {
        selectedStripeId = stripe_id.stripe_id_ru;
      }else if (countryCode === 'fr') {
          selectedStripeId = stripe_id.stripe_id_fr;
      } else if (countryCode === 'nl') {
        selectedStripeId = stripe_id.stripe_id_fr;
    }
    else if (countryCode === 'de') {
      selectedStripeId = stripe_id.stripe_id_fr;
  }  
  else if (countryCode === 'it') {
    selectedStripeId = stripe_id.stripe_id_fr;
}  
else if (countryCode === 'es') {
  selectedStripeId = stripe_id.stripe_id_fr;
} 
else if (countryCode === 'fi') {
  selectedStripeId = stripe_id.stripe_id_fr;
} 
else if (countryCode === 'gr') {
  selectedStripeId = stripe_id.stripe_id_fr;
} 
else if (countryCode === 'be') {
  selectedStripeId = stripe_id.stripe_id_fr;
} 
else if (countryCode === 'nz') {
  selectedStripeId = stripe_id.stripe_id_nz;
} 
    else if (countryCode === 'sg') {
      selectedStripeId = stripe_id.stripe_id_sg;
    }
    else if (countryCode === 'cn') {
      selectedStripeId = stripe_id.stripe_id_cn;
    }
    else if (countryCode === 'jp') {
      selectedStripeId = stripe_id.stripe_id_jp;
    }
    else if (countryCode === 'au') {
      selectedStripeId = stripe_id.stripe_id_au;
    }
    else if (countryCode === 'kr') {
      selectedStripeId = stripe_id.stripe_id_kr;
    }
    else if (countryCode === 'tw') {
      selectedStripeId = stripe_id.stripe_id_tw;
    }
    else if (countryCode === 'ae') {
      selectedStripeId = stripe_id.stripe_id_ae;
    }
    else if (countryCode === 'ca') {
      selectedStripeId = stripe_id.stripe_id_ca;
    }
    else if (countryCode === 'id') {
      selectedStripeId = stripe_id.stripe_id_id;
    }
    else if (countryCode === 'il') {
      selectedStripeId = stripe_id.stripe_id_il;
    }
    else if (countryCode === 'za') {
      selectedStripeId = stripe_id.stripe_id_za;
    }
    else if (countryCode === 'ua') {
      selectedStripeId = stripe_id.stripe_id_ua;
    }
    else if (countryCode === 'vn') {
      selectedStripeId = stripe_id.stripe_id_vn;
    }
    else if (countryCode === 'br') {
      selectedStripeId = stripe_id.stripe_id_br;
    }
      else {
        selectedStripeId = stripe_id.stripe_id; // Fallback to a default stripe id
      }
    
      console.log('Selected Stripe ID:', selectedStripeId);
      // Check if the user is already subscribed
      const authUserString = localStorage.getItem("authUser");
      const authUser = authUserString ? JSON.parse(authUserString) : null;
      try {
        const subscriptionCheckResponse = await axiosInstance.post('/check-user-subscription', { user_id: authUser?._id });
    
        if (subscriptionCheckResponse?.data?.hasSubscription) {
          setSubscriptionPrompt(true);  // Show the subscription prompt message
             setShowMessage(true);
                  return;
        }
    
        // User is not subscribed, proceed with payment
        const isPurchaseCompleted = localStorage.getItem('purchaseCompleted');
        if (!isPurchaseCompleted) {
          localStorage.setItem('stripeId', selectedStripeId);
          localStorage.setItem('selectedPlanName', planName);
          localStorage.setItem('selectedPlanId', planId);
          localStorage.setItem('selectedPlanPrice', planPrice);
        }
    
        if (!authUser) {
          console.error('AuthUser not found in local storage');
          setLoginPrompt(true); // Show the login prompt message
          setShowMessage(true);
          e.preventDefault(); // Prevent form submission
          setTimeout(() => {
            window.location.href = '/login'; // Redirect to login page after 3 seconds
          }, 1000);
          return;
        } else {
          // Open the modal when pay button is clicked
          setShowPopup(true);
          setSelectedPaymentOption(null); // Reset selected payment option
        }
      } catch (error) {
        console.error('Error checking user subscription:', error);
        // Handle the error (show a message, log, etc.)
      }
    };
    
    const handlePaymentOptionClick = (paymentOption) => {
      setSelectedPaymentOption(paymentOption);
     
    };
    const handleProceed = () => {
      if (selectedPaymentOption === "Stripe") {
        handleStripePayment();
      }
      else if (selectedPaymentOption === "One-Time Payment") {
        handleBilldeskPayment("One-Time Payment");
      } else if (selectedPaymentOption === "Recurring Payment") {
        handleBilldeskPayment("Recurring Payment");
      }
      //till
      else if (selectedPaymentOption === "PhonePe") {
        handlePhonePePayment();
      }
    };
    const handlePhonePePayment = async () => {
      try {
        const selectedPlanId = localStorage.getItem('selectedPlanId');
        const selectedPlanName = localStorage.getItem('selectedPlanName');
        const selectedPlanPrice = localStorage.getItem('selectedPlanPrice');
         const authUserString = localStorage.getItem("authUser");
    const authUser = authUserString ? JSON.parse(authUserString) : null;
        const email = authUser.email
        const userid=authUser._id;
        const url = `https://db.oneaichat.com/pay?planId=${selectedPlanId}&planName=${selectedPlanName}&planPrice=${selectedPlanPrice}&email=${email}&userid=${userid}`;
      const response = await fetch(url, {
          method: 'GET',
        });
    
        if (!response.ok) {
          throw new Error(`Error initiating payment: ${response.statusText}`);
        }
    console.log(url)
        const responseData = await response.json();
    
        // Check if responseData has the expected structure
        if (responseData && responseData.redirectUrl) {
          window.location.href = responseData.redirectUrl;
        } else {
          console.error('Unexpected response format:', responseData);
          // Handle unexpected response format, show a message, or redirect to an error page
        }
      } catch (error) {
        console.error('Payment initiation error:', error);
        // Handle the error, show a message, or redirect to an error page
      }
    };
    
    
  {/*Billdesk starts  */}
    const [amount, setAmount] = useState('');
    const [orderId, setOrderId] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');

    const handleBilldeskPayment = async (type) => {
      const selectedPlanId = localStorage.getItem("selectedPlanId");
      const selectedPlanName = localStorage.getItem("selectedPlanName");
      const selectedPlanPrice = localStorage.getItem("selectedPlanPrice");
      const authUserString = localStorage.getItem("authUser");
    
      const authUser = authUserString ? JSON.parse(authUserString) : null;
      let routeUrl;
      if (type === "One-Time Payment") {
        routeUrl = `https://db.oneaichat.com/api/initiate-payment`;
      }
    
      if (type === "Recurring Payment") {
        routeUrl = `https://db.oneaichat.com/api/initiate-recurring-payment`;
      }
    
      if (type === "Stop Recurring Payment") {
        routeUrl = `https://db.oneaichat.com/api/stop-recurring`;
      }
      // Access user information from the authUser object
      const userEmail = authUser.email;
      const userid = authUser._id;
      console.log(userid);
    
      if (type === "One-Time Payment") {
        try {
          const response = await fetch(routeUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              selectedPlanPrice: parseFloat(selectedPlanPrice),
              orderId,
              customerName,
              customerEmail: userEmail,
              selectedPlanId: selectedPlanId,
              selectedPlanName: selectedPlanName,
              userid: userid,
            }),
          });
    
          const result = await response.json();
    
          const authToken = result.flowConfig.authToken;
          const bdOrderId = result.flowConfig.bdOrderId;
          const MerchantID = result.flowConfig.merchantId;
    
          const flow_config = {
            merchantId: MerchantID,
            bdOrderId: bdOrderId,
            authToken: authToken,
            childWindow: false,
            returnUrl: "https://db.oneaichat.com/api/callback",
            retryCount: 0,
          };
          var responseHandler = function (txn) {
            if (txn.response) {
              alert("callback received status:: ", txn.status);
              alert("callback received response:: ", txn.response); //response handler to be implemented by the merchant
            }
            console.log("txn.response" + txn.response);
          };
          const config = {
            responseHandler: responseHandler,
    
            flowConfig: flow_config,
            flowType: "payments",
          };
    
          window.loadBillDeskSdk(config);
    
          console.log("loadinggg");
        } catch (error) {
          console.error("Error initiating payment:", error);
          // alert('Internal server error. Please try again later.');
        }
      }
    
      if (type === "Recurring Payment") {
        try {
          const response = await fetch(routeUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              selectedPlanPrice: parseFloat(selectedPlanPrice),
              orderId,
              customerName,
              customerEmail: userEmail,
              selectedPlanId: selectedPlanId,
              selectedPlanName: selectedPlanName,
              userid: userid,
            }),
          });
    
          const result = await response.json();
    
          console.log("this is the result ");
          console.log(result);
    
          const authToken = result.flowConfig.authToken;
          const bdOrderId = result.flowConfig.bdOrderId;
          const MerchantID = result.flowConfig.merchantId;
    
          const flow_config = {
            merchantId: MerchantID,
            bdOrderId: bdOrderId,
            authToken: authToken,
            childWindow: false,
            returnUrl: "https://db.oneaichat.com/api/callback",
            // crossButtonHandling: "Y",
            retryCount: 0,
            prefs: {
              payment_categories: ["card", "nb", "upi"],
              allowed_bins: ["459150", "525211", "540000"],
            },
          };
    
          var responseHandler = function (txn) {
            if (txn.response) {
              alert("callback received status:: ", txn.status);
              alert("callback received response:: ", txn.response);
            }
          };
    
          var config = {
            responseHandler: responseHandler,
            flowConfig: flow_config,
            flowType: "payments",
          };
    
          window.loadBillDeskSdk(config);
    
          console.log("loadinggg");
        } catch (error) {
          console.error("Error initiating payment:", error);
          // alert('Internal server error. Please try again later.');
        }
      }
    
      if (type === "Stop Recurring Payment") {
        try {
          const response = await fetch(routeUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              selectedPlanPrice: parseFloat(selectedPlanPrice),
              orderId,
              customerName,
              customerEmail: userEmail,
              selectedPlanId: selectedPlanId,
              selectedPlanName: selectedPlanName,
              userid: userid,
            }),
          });
    
          const result = await response.json();
    
          console.log("this is the result ");
          console.log(result);
    
          const authToken = result.flowConfig.authToken;
          const mandateTokenId = result.flowConfig.mandateTokenId;
          const MerchantID = result.flowConfig.merchantId;
    
          var flow_config = {
            merchantId: MerchantID,
            mandateTokenId: mandateTokenId,
    
            authToken: authToken,
            childWindow: true,
            returnUrl: "https://db.oneaichat.com/api/stop-callback",
            // crossButtonHandling: "Y",
            retryCount: 3,
          };
          console.log("this is the flow consig on front end", flow_config);
    
          var responseHandler = function (txn) {
            if (txn.response) {
              alert("callback received status:: ", txn.status);
              alert("callback received response:: ", txn.response);
            }
          };
    
          var config = {
            responseHandler: responseHandler,
            flowConfig: flow_config,
            flowType: "modify_mandate",
          };
    
          window.loadBillDeskSdk(config);
    
          console.log("loadinggg");
        } catch (error) {
          console.error("Error initiating payment:", error);
          // alert('Internal server error. Please try again later.');
        }
      }
    };
 {/*Billdesk ends */}


 const handleStripePayment = () => {
  const stripeId = localStorage.getItem('stripeId');

  const form = document.createElement('form');
  form.action = 'https://stripe.oneaichat.com/create-checkout-session';
  form.method = 'POST';

  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'stripeId';
  input.value = stripeId;

  form.appendChild(input);

  document.body.appendChild(form);

  form.submit();
};


//  const handlePayyClick = async (e, planId, planName,planPrice) => {
//   const isPurchaseCompleted = localStorage.getItem('purchaseCompleted');
//   if (!isPurchaseCompleted) {
//     localStorage.setItem('selectedPlanName', planName);
//     localStorage.setItem('selectedPlanId', planId);
//     localStorage.setItem('selectedPlanPrice', planPrice);
// }

//       // Extract user details from local storage
//   const authUser = JSON.parse(localStorage.getItem("authUser"));

  
//   if (!authUser) {
//     console.error('AuthUser not found in local storage');
//     setLoginPrompt(true);  // Show the login prompt message
//     setShowMessage(true);
//     e.preventDefault();  // Prevent form submission
//     setTimeout(() => {
//       window.location.href = '/login'; // Redirect to login page after 3 seconds
//     }, 1000);
//     return;
//   } else {
//     // If the user is logged in, check for subscription
//     try {
//       const email = authUser.email;
//       const response = await axiosInstance.post(`/check-subscription`, { user_email: email });
//       if (response.data.hasSubscription) {
//         // Display a user-friendly message to inform the user.
      
//         setSubscriptionPrompt(true);  // Show the subscription prompt message
//         setShowMessage(true);
      
//         e.preventDefault(); // Prevent further processing or redirection
//         setTimeout(() => { 
//           window.location.href = `/payment_success`; 
//       }, 0.900);
//         return; // Stop the function here if the user has a subscription
//       } 


//     } catch (err) {
//       console.error(err);
//       e.preventDefault(); // Prevent further processing in case of an error
//       return;
//     }
//   }
//   const email = authUser.email;
//   const firstName = authUser.firstName;
//   const lastName = authUser.lastName;

//   const subscriptionData = { 
//         plan_id:planId,
//         plan_name:planName,
//         user_first_name: firstName,
//         user_last_name: lastName,
//         user_email: email
//       };
//       try {
//         const response = await axiosInstance.post(`/save-subscription`,subscriptionData );
    
//         if (response.data.success) {
//         console.log('Data saved sucessfully')
//         } else {
//           console.error('Failed to save subscription');
//           e.preventDefault();
//         }
//       } catch (error) {
//         console.error('Axios Error:', error.response.data);
//         e.preventDefault();
//       }      
//     };

// const getPriceForCountry = (subscription, userCountry) => {
//   let parsedPrices = null;
//   try {
//     parsedPrices = JSON.parse(subscription.plan_prices);
//   } catch (error) {
//     console.error('Error parsing plan prices:', error);
//   }

//   const price = parsedPrices && parsedPrices[userCountry];

//   return price ? (userCountry === 'US' ? '$' : '₹') + price : 'Not available for this country';
// };



return(
        <div>
             <Helmet>
            <link rel="canonical" href="https://oneaichat.com/pricing" />
            </Helmet>
            <Header />
            <main className="wrapper">
      {/* Breadcrumb Start */}
      <section
        className="aai-breadcrumb"
        // style={{
        //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="text-center">
                <h2 className="aai-page-title">Subscriptions</h2>
                <ul
                  className="aai-breadcrumb-nav d-flex align-items-center justify-content-center"
                >
                  <li>
                    <a
                      href="/"
                      className="aai-breadcrumb-link text-decoration-underline"
                    >Home</a>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 16L18 12M18 12L14 8M18 12L6 12"
                        stroke="#DADADA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>
                  <li>
                    <a href="" className="aai-breadcrumb-link"> Subscriptions </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb End */}

  
      {/* <!-- Pricing Table Start --> */}
      <section className="aai-price-tables pb-120">
        <div className="container">
          <div
            className="row align-items-center mb-5"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="col-xl-5">
              {/* <div className="secttionn">
                <h2 className="section-title">
                  The Right Plan for Transparent Pricing
                </h2>
              </div> */}
            </div>
            <div className="col-xl-7">
              {/* <div>
                <p className="section-desc">
                  We have several powerful plans to showcase your business and
                  get discovered as a creative entrepreneurs. Everything your
                  need.
                </p>
              </div> */}
            </div>
          </div>
          {loginPrompt && 
        <div className={`message-box ${showMessage ? 'active' : ''}`}>
          <button className="close-btn" onClick={closeMessage}>&times;</button>
          Please Login First.
        </div>
      }
              {subscriptionPrompt && 
    <div className={`subscription-box ${showMessage ? 'active' : ''}`}>
        <button className="close-subscription-btn" onClick={closeMessage}>&times;</button>
        User already has a subscription!
    </div>
    }
              {subscriptions.map((subscription)=> (
          <div
            className="aai-price-table mb-4"
            data-aos="fade-up"
            data-aos-delay="100"
            key={subscription._id}
          >
            <div className="row g-4 align-items-center justify-content-between">
              <div className="col-xl-4 col-lg-6">
                <div className="aai-price-pack">
                  <h3 className="aai-price-pack-name">{subscription.plan_name}</h3>
                  <p className="aai-price-pack-desc">
                  {subscription.plan_description}
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <ul className="aai-price-lists list-unstyled">
                  {/* <li className="aai-price-list-item d-flex align-items-center"> */}
                  <li className="aai-price-list-item d-flex align-items-center" style={{borderBottom:"1px solid grey",paddingBottom:"5px"}}>
                      {/* <span style={{background:"transparent",borderRadius:"50%",width:"33px",height:"20px",border:"1px solid black",display:"flex",justifyContent:"center",alignItems:"center"}}> */}
                      <p style={{color:"black",fontWeight:"bolder",padding:"1px",border:"1px solid black",borderRadius:"50%",fontSize:"12px"}}>✓</p>
                      {/* </span> */}
                  {/* <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.4839 10.0673C15.907 9.64423 16.5929 9.64423 17.016 10.0673C17.4065 10.4578 17.4366 11.0723 17.1061 11.4973L17.016 11.5994L12.6827 15.9327C12.2921 16.3232 11.6776 16.3533 11.2527 16.0228L11.1506 15.9327L8.98393 13.766C8.56086 13.343 8.56086 12.657 8.98393 12.234C9.37445 11.8434 9.98895 11.8134 10.4139 12.1438L10.516 12.234L11.9166 13.6338L15.4839 10.0673Z"
                        fill="#DADADA"
                      />
                      <g opacity="0.5">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 2.16666C7.01687 2.16666 2.16663 7.01691 2.16663 13C2.16663 18.9831 7.01687 23.8333 13 23.8333C18.983 23.8333 23.8333 18.9831 23.8333 13C23.8333 7.01691 18.983 2.16666 13 2.16666ZM13 4.33333C17.7864 4.33333 21.6666 8.21353 21.6666 13C21.6666 17.7865 17.7864 21.6667 13 21.6667C8.21349 21.6667 4.33329 17.7865 4.33329 13C4.33329 8.21353 8.21349 4.33333 13 4.33333Z"
                          fill="#DADADA"
                        />
                      </g>
                    </svg> */}
                    {/* <span className="aai-price-list-item-text"
                      >All Paid Models: ChatGPT - Claude - Mistral</span
                    > */}
                    <span className="aai-price-list-item-text"  >All Pro models: OpenAI - Anthropic - Stability AI - Google</span>
                  </li>
                  {/* <li className="aai-price-list-item d-flex align-items-center"> */}
                  <li className="aai-price-list-item d-flex align-items-center" style={{borderBottom:"1px solid grey",paddingBottom:"5px"}}>
                      {/* <span style={{background:"transparent",borderRadius:"50%",width:"20px",height:"20px",border:"1px solid black",display:"flex",justifyContent:"center",alignItems:"center"}}> */}
                      <p style={{color:"black",fontWeight:"bolder",padding:"1px",border:"1px solid black",borderRadius:"50%",fontSize:"12px"}}>✓</p>
                      {/* </span> */}
                    {/* <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.4839 10.0673C15.907 9.64423 16.5929 9.64423 17.016 10.0673C17.4065 10.4578 17.4366 11.0723 17.1061 11.4973L17.016 11.5994L12.6827 15.9327C12.2921 16.3232 11.6776 16.3533 11.2527 16.0228L11.1506 15.9327L8.98393 13.766C8.56086 13.343 8.56086 12.657 8.98393 12.234C9.37445 11.8434 9.98895 11.8134 10.4139 12.1438L10.516 12.234L11.9166 13.6338L15.4839 10.0673Z"
                        fill="#DADADA"
                      />
                      <g opacity="0.5">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 2.16666C7.01687 2.16666 2.16663 7.01691 2.16663 13C2.16663 18.9831 7.01687 23.8333 13 23.8333C18.983 23.8333 23.8333 18.9831 23.8333 13C23.8333 7.01691 18.983 2.16666 13 2.16666ZM13 4.33333C17.7864 4.33333 21.6666 8.21353 21.6666 13C21.6666 17.7865 17.7864 21.6667 13 21.6667C8.21349 21.6667 4.33329 17.7865 4.33329 13C4.33329 8.21353 8.21349 4.33333 13 4.33333Z"
                          fill="#DADADA"
                        />
                      </g>
                    </svg> */}

                    {/* <span className="aai-price-list-item-text"
                      >Generate: Image-Audio-Document-(Video Coming Soon)</span
                    > */}
                    <span className="aai-price-list-item-text"
                      >Generate: Image-Audio-Document</span>
                  </li>
                  {/* <li className="aai-price-list-item d-flex align-items-center"> */}
                  <li className="aai-price-list-item d-flex align-items-center" style={{borderBottom:"1px solid grey",paddingBottom:"5px"}}>
                      {/* <span style={{background:"transparent",borderRadius:"50%",width:"23px",height:"20px",border:"1px solid black",display:"flex",justifyContent:"center",alignItems:"center"}}> */}
                      <p style={{color:"black",fontWeight:"bolder",padding:"1px",border:"1px solid black",borderRadius:"50%",fontSize:"12px"}}>✓</p>
                      {/* </span> */}
                    {/* <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.4839 10.0673C15.907 9.64423 16.5929 9.64423 17.016 10.0673C17.4065 10.4578 17.4366 11.0723 17.1061 11.4973L17.016 11.5994L12.6827 15.9327C12.2921 16.3232 11.6776 16.3533 11.2527 16.0228L11.1506 15.9327L8.98393 13.766C8.56086 13.343 8.56086 12.657 8.98393 12.234C9.37445 11.8434 9.98895 11.8134 10.4139 12.1438L10.516 12.234L11.9166 13.6338L15.4839 10.0673Z"
                        fill="#DADADA"
                      />
                      <g opacity="0.5">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 2.16667C7.01687 2.16667 2.16663 7.01692 2.16663 13C2.16663 18.9831 7.01687 23.8333 13 23.8333C18.983 23.8333 23.8333 18.9831 23.8333 13C23.8333 7.01692 18.983 2.16667 13 2.16667ZM13 4.33333C17.7864 4.33333 21.6666 8.21353 21.6666 13C21.6666 17.7865 17.7864 21.6667 13 21.6667C8.21349 21.6667 4.33329 17.7865 4.33329 13C4.33329 8.21353 8.21349 4.33333 13 4.33333Z"
                          fill="#DADADA"
                        />
                      </g>
                    </svg> */}

                    {/* <span className="aai-price-list-item-text">Access OneAIChat’s: Focused Categories</span> */}
                    <span className="aai-price-list-item-text"> Access OneAIChat’s Focused-Categories</span>
                  </li>
                  {/* <li className="aai-price-list-item d-flex align-items-center"> */}
                  <li className="aai-price-list-item d-flex align-items-center" style={{borderBottom:"1px solid grey",paddingBottom:"5px"}}>
                      {/* <span style={{background:"transparent",borderRadius:"50%",width:"20px",height:"20px",border:"1px solid black",display:"flex",justifyContent:"center",alignItems:"center"}}> */}
                      <p style={{color:"black",fontWeight:"bolder",padding:"1px",border:"1px solid black",borderRadius:"50%",fontSize:"12px"}}>✓</p>
                      {/* </span> */}
                  {/* <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.4839 10.0673C15.907 9.64423 16.5929 9.64423 17.016 10.0673C17.4065 10.4578 17.4366 11.0723 17.1061 11.4973L17.016 11.5994L12.6827 15.9327C12.2921 16.3232 11.6776 16.3533 11.2527 16.0228L11.1506 15.9327L8.98393 13.766C8.56086 13.343 8.56086 12.657 8.98393 12.234C9.37445 11.8434 9.98895 11.8134 10.4139 12.1438L10.516 12.234L11.9166 13.6338L15.4839 10.0673Z"
                        fill="#DADADA"
                      />
                      <g opacity="0.5">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 2.16666C7.01687 2.16666 2.16663 7.01691 2.16663 13C2.16663 18.9831 7.01687 23.8333 13 23.8333C18.983 23.8333 23.8333 18.9831 23.8333 13C23.8333 7.01691 18.983 2.16666 13 2.16666ZM13 4.33333C17.7864 4.33333 21.6666 8.21353 21.6666 13C21.6666 17.7865 17.7864 21.6667 13 21.6667C8.21349 21.6667 4.33329 17.7865 4.33329 13C4.33329 8.21353 8.21349 4.33333 13 4.33333Z"
                          fill="#DADADA"
                        />
                      </g>
                    </svg> */}
                    {/* <span className="aai-price-list-item-text"
                      >Zero limit on prompts, for all subscriptions</span
                    > */}
                      <span className="aai-price-list-item-text"
                      > Zero limit on prompts.
                      </span>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="aai-price-value">
                  <h2 className="aai-price">
                  {console.log('User Country:', userCountry)}
      {userCountry === 'US' && `${subscription.plan_price_us}`} {/* Adding '$' symbol for US */}
      {userCountry === 'GB' && `${subscription.plan_price_uk}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'RU' && `${subscription.plan_price_ru}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'FR' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'NL' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for France */}
      {userCountry === 'DE' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Germany */}
      {userCountry === 'IT' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Italy */}
      {userCountry === 'ES' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Spain */}
      {userCountry === 'FI' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Finland */}
      {userCountry === 'GR' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Greece */}
      {userCountry === 'BE' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Greece */}
      {userCountry === 'SG' && `${subscription.plan_price_sg}`} {/* Adding '£' symbol for Singapore */}
      {userCountry === 'CN' && `${subscription.plan_price_cn}`} {/* Adding '£' symbol for China */}
      {userCountry === 'JP' && `${subscription.plan_price_jp}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'NZ' && `${subscription.plan_price_nz}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'AU' && `${subscription.plan_price_au}`} {/* Adding '£' symbol for Australia */}
      {userCountry === 'KR' && `${subscription.plan_price_kr}`} {/* Adding '£' symbol for South Korea */}
      {userCountry === 'TW' && `${subscription.plan_price_tw}`} {/* Adding '£' symbol for taiwan*/}
      {userCountry === 'AE' && `${subscription.plan_price_ae}`} {/* Adding '£' symbol for Dubai*/}
      {userCountry === 'CA' && `${subscription.plan_price_ca}`} {/* Adding '£' symbol for Canada*/}
      {userCountry === 'ID' && `${subscription.plan_price_id}`} {/* Adding '£' symbol for Indonesia*/}
      {userCountry === 'ZA' && `${subscription.plan_price_za}`} {/* Adding '£' symbol for South Africa*/}
      {userCountry === 'UA' && `${subscription.plan_price_ua}`} {/* Adding '£' symbol for Ukraine*/}
      {userCountry === 'VN' && `${subscription.plan_price_vn}`} {/* Adding '£' symbol for Vietnam */}
      {userCountry === 'BR' && `${subscription.plan_price_br}`} {/* Adding '£' symbol for Brazil */}






      {userCountry === 'IN' && `₹${subscription.plan_price}`} {/* Adding '₹' symbol for India */}
      
      
                    </h2>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6">
                <div>
               
                <input type="hidden" name="stripeId" value={subscription[`stripe_id_${userCountry ? userCountry.toLowerCase() : ''}`]}  />
                <button 
                  className="aai-btn btn-pill-solid" 
                  id="submit" 
                  role="link" 
                 onClick={(e) => handlePayClick(e, subscription.plan_id, subscription.plan_name, subscription.plan_price,subscription)}
                >
                  PAY
                </button>
            
                </div>
              </div>
            </div>    
          </div>
          ))}
           {/* Popup for payment options */}
        {showPopup && (
         
          <div className="popup">
            <h2>Select Payment Option</h2>
            <br/>
            <button className="popup-close" onClick={closeMessage} style={{fontSize:"larger",
            // marginTop:"-150px",
            marginLeft:"355px",
            position:"fixed",color:'black'}}>X</button>
            <button onClick={() => handlePaymentOptionClick("PhonePe")}
             className={selectedPaymentOption === "PhonePe" ? "selected" : ""}
            >PhonePe</button>
          {/* <button
            onClick={() => handlePaymentOptionClick("BillDesk")}
            className={selectedPaymentOption === "BillDesk" ? "selected" : ""}
          >
            BillDesk
          </button> */}
           <button onClick={openModal}>BillDesk</button>
          <button
            onClick={() => handlePaymentOptionClick("Stripe")}
            className={selectedPaymentOption === "Stripe" ? "selected" : ""}
          >
            Stripe
          </button>
          <br/>
          <br/>
          <br/>
          
          <center>
            <button onClick={handleProceed}>Proceed</button>
          </center>
          </div>
        )}

          {/* this code is for the modal for billdesk options  */}
          {isModalOpen && (
              <div
                className="modal fade show"
                tabIndex="-1"
                style={{ display: "block" }}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                  style={{ height: "80vh" }}
                >
                  <div className="modal-content" style={{ height: "100%" }}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Payment Options
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={closeModal}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div
                      className="modal-body"
                      style={{
                        maxHeight: "calc(80vh - 56px - 50px)", // Adjusted to account for the footer height
                        overflowY: "auto",
                      }}
                    >
                      <div className="d-flex flex-column align-items-center">
                        <div className="text-center mb-4">
                          <button
                            className={`btn mb-2 ${
                              selectedPaymentOption === "One-Time Payment"
                                ? "btn-success"
                                : "btn-primary"
                            }`}
                            onClick={() =>
                              handlePaymentOptionClick("One-Time Payment")
                            }
                          >
                            One-Time Payment
                          </button>
                          <p className="text-muted">
                            Use this option to make a single payment for your
                            purchase. This is ideal for one-off transactions
                            where you do not wish to commit to recurring
                            payments.
                          </p>
                        </div>
                        <div className="text-center mb-4">
                          <button
                            className={`btn mb-2 ${
                              selectedPaymentOption === "Recurring Payment"
                                ? "btn-success"
                                : "btn-primary"
                            }`}
                            onClick={() =>
                              handlePaymentOptionClick("Recurring Payment")
                            }
                          >
                            Recurring Payment
                          </button>
                          <p className="text-muted">
                            Choose this option if you prefer a
                            subscription-based model. Your account will be
                            automatically charged on a regular basis (e.g.,
                            monthly or yearly) for continued access to the
                            service.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Footer with Proceed button */}
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleProceed}
                        disabled={!selectedPaymentOption} // Disable if no option is selected
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* till here the modal for billdesk end */}
          {/* <!-- Single Price Table --> */}
         
          {/* <!-- Single Price Table --> */}
     </div>
      </section>
      {/* <!-- Pricing Table End -->

      <!-- Price Table Two Start--> */}
     
     <section className="aai-feature-table pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="text-center mb-5">
                <h2 className="section-title">Compare plans & features</h2>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <div className="aai-table-wrapper">
                <table className="aai-table">
                  <thead className="aai-thead">
                    <tr>
                      <th scope="coll">
                        <span> Features </span>
                      </th>
                      
                      <th scope="col">
                        <span className="aai-text-danger">Daily/Weekly</span>
                      </th>
                      <th scope="col">
                        <span className="aai-text-danger">Monthly/Quaterly</span>
                      </th>
                      <th scope="col">
                        <span className="aai-text-warning">HalfYearly/Yearly</span>
                      </th>
                      <th scope="col">
                        <span className="aai-text-warning"> Free(With Login 24 hours)</span>
                      </th>
                      <th scope="col">
                        <span className="aai-text-warning">Free(Without Login 24 Hours)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="aai-tbody">
                    <tr>
                      <th scope="row">
                        <div className="">
                          <span>Image</span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                              stroke="#DADADA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </th>
                  
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>1</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="">
                          <span>Video</span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                              stroke="#DADADA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </th>
                     
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>1</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="">
                          <span>Audio</span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                              stroke="#DADADA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </th>
                    
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>1</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="">
                          <span> Document </span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                              stroke="#DADADA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </th>
                    
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>1</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="">
                          <span>Text</span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                              stroke="#DADADA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </th>
                  
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>10</td>
                      <td>10</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    {/* Price table -2 end */}
  {/* <!-- Testimonials Start--> */}

    <Testimonials/>
  {/* <!-- Testimonials End --> */}
<br/>
<br/>    
<br/>
<br/>

              <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-xl-6 col-lg order-2 order-lg-1 mt-5 mt-lg-0"
                  data-aos="fade-right"
                >
                  <div>
                    <img
                      src="assets/img/services/service-1.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg order-1 order-lg-2">
                  <div className="singleclick">
                    <h2 className="section-title mb-4">
                    Reach Audiences through Emotional Storytelling
                    </h2>
                    <p className="section-desc" style={{"textAlign":"justify"}} >
                    Connect with your audience in a whole new way using Oneaichat's emotional intelligence.
                    It understands tone, sentiments and can infuse content with the right emotions to truly
                    engage and inspire readers. Generate narrative storytelling, character bios, speeches and 
                    more to forge deep connections with customers.
                    </p>
                   
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Generate Images - Audio - Video - Documents
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        User world class services like ChatGPT - Claude - Gemini - Mistral - Somebody
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Discipline your focus with our curated categories
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="/pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg">
                  <div>
                    <h2 className="section-title mb-4">
                    Let Creativity Flow with Unlimited AI Assistance
                    </h2>
                    <p className="section-desc"style={{"textAlign":"justify"}}>
                    Stop wasting time on mundane content creation tasks. With Oneaichat's AI assistant at your
                    fingertips, creativity can flow freely. It takes the heavy lifting off your shoulders so 
                    you can focus on strategy while it handles execution at lightning speed. Unleash your potential
                    for impactful communication like never before.

                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Generate Images - Audio - Video - Documents
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        User world class services like Chat GPT - Claude - Gemini - Mistral - Somebody 
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Discipline your focus with our curated categories
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="/pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg mt-5 mt-lg-0"
                  data-aos="fade-left"
                >
                  <div>
                    <img
                      src="assets/img/services/service-2.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
<br/>
<br/>
<br/>
<br/>
      {/* Cta Start */}
     <section
        className="aai-cta pb-120"
        // style={{
        //   background: "url('assets/img/bg/cta-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container" style={{"border":"1px solid #28c434","borderRadius":"25px"}}>
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg">
              <div className="secttionn">
                <h2 className="section-title mb-4">
                The Future Isn't What It Used To Be!&nbsp;
                  <span className="position-relative">
                  
                  </span>
                  
                </h2>
               
                <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                  <a href="/pricing" className="aai-btn btn-pill-solid">
                    Get Started
                  </a>
                  <a href="/about" className="aai-gradient-outline-btn">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-7 col-lg mt-5 mt-xl-0"
              data-aos="fade-up"
              data-aos-delay="80"
            >
              <div className="aai-cta-img">
                <img
                  src="assets/img/logo/2.jpeg"
                  className="img-fluuid"
                  alt=""
                 
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cta End */}
      </main>


       <Footer />
        </div>
    )
    
};
export default Pricing;