// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMessage_optionsButton__Cca2G {
  /* styles for the three-dot button */
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  /* Adjust size as needed */
  width: 10px;
  height: 10px;
}

.ChatMessage_optionsMenu__erBrL {
  position: absolute;
  top: -2rem;
  left: -2rem;
  border: 2px solid #ccc;
  border-radius: 14px; /* Rounded corners */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); Drop shadow */
  z-index: 10000; /* To ensure it stacks above other content */
  min-width: 150px; /* Set a min-width for your menu */
  background: white;  
}

.ChatMessage_optionsMenu__erBrL ul {
  position: relative;
  margin: 0;
  background: white;
  border-radius: 14px; /* Rounded corners */
  z-index: 10001;
}

.ChatMessage_optionsMenu__erBrL li {
  background: white;
  padding: 6px 15px; /* Add padding for each menu item */
  cursor: pointer;
  border-radius: 14px; /* Rounded corners */
}

.ChatMessage_optionsMenu__erBrL li:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
}

/* Show the menu when showOptions is true */
.ChatMessage_showOptions__Xhw4t .ChatMessage_optionsMenu__erBrL {
  display: block; /* Show the menu */
}`, "",{"version":3,"sources":["webpack://./src/pages/Chat/components/ChatBoard/ChatMessage.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,SAAS;EACT,0BAA0B;EAC1B,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,sBAAsB;EACtB,mBAAmB,EAAE,oBAAoB;EACzC,0DAA0D;EAC1D,cAAc,EAAE,4CAA4C;EAC5D,gBAAgB,EAAE,kCAAkC;EACpD,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,iBAAiB;EACjB,mBAAmB,EAAE,oBAAoB;EACzC,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB,EAAE,mCAAmC;EACtD,eAAe;EACf,mBAAmB,EAAE,oBAAoB;AAC3C;;AAEA;EACE,yBAAyB,EAAE,mCAAmC;AAChE;;AAEA,2CAA2C;AAC3C;EACE,cAAc,EAAE,kBAAkB;AACpC","sourcesContent":[".optionsButton {\n  /* styles for the three-dot button */\n  cursor: pointer;\n  background: none;\n  border: none;\n  padding: 0;\n  margin: 0;\n  /* Adjust size as needed */\n  width: 10px;\n  height: 10px;\n}\n\n.optionsMenu {\n  position: absolute;\n  top: -2rem;\n  left: -2rem;\n  border: 2px solid #ccc;\n  border-radius: 14px; /* Rounded corners */\n  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); Drop shadow */\n  z-index: 10000; /* To ensure it stacks above other content */\n  min-width: 150px; /* Set a min-width for your menu */\n  background: white;  \n}\n\n.optionsMenu ul {\n  position: relative;\n  margin: 0;\n  background: white;\n  border-radius: 14px; /* Rounded corners */\n  z-index: 10001;\n}\n\n.optionsMenu li {\n  background: white;\n  padding: 6px 15px; /* Add padding for each menu item */\n  cursor: pointer;\n  border-radius: 14px; /* Rounded corners */\n}\n\n.optionsMenu li:hover {\n  background-color: #f0f0f0; /* Light grey background on hover */\n}\n\n/* Show the menu when showOptions is true */\n.showOptions .optionsMenu {\n  display: block; /* Show the menu */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsButton": `ChatMessage_optionsButton__Cca2G`,
	"optionsMenu": `ChatMessage_optionsMenu__erBrL`,
	"showOptions": `ChatMessage_showOptions__Xhw4t`
};
export default ___CSS_LOADER_EXPORT___;
