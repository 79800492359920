import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { subcategoryList } from "data/ailist";
import Chatheader from "components/Header/ChatHeader/Chatheader";
import "./Profile.css";
import styles from "./Chat.module.css";
import { Helmet } from "react-helmet";

const Profile = () => {
  const [aiList, setAiList] = useState(subcategoryList);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isPhoneNumberDisabled, setIsPhoneNumberDisabled] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [aiName, setAIName] = useState("OneAI Chat");
  const navigate = useNavigate();

  const aiNameHandler = (name, status) => {
    setAIName(name);
    navigate(`/chat`, { replace: true, state: { selectedCategory: name } });
  };

  useEffect(() => {
    let authUser = "";
    const authUserString = localStorage.getItem("authUser");
    if (authUserString) {
      authUser = JSON.parse(authUserString);
    }
    if (authUser) {
      setFirstName(authUser.firstName || "");
      setLastName(authUser.lastName || "");
      if (authUser.email) {
        setEmail(authUser.email);
        setPhoneNumber(authUser.phoneNumber || "");
        setIsPhoneNumberDisabled(!!authUser.phoneNumber);
        setIsEmailDisabled(true);
      } else if (authUser.phoneNumber) {
        setPhoneNumber(authUser.phoneNumber);
        setEmail(authUser.email || "");
        setIsPhoneNumberDisabled(true);
        setIsEmailDisabled(!!authUser.email);
      }
    }
  }, []);

  const handleSave = async () => {
    if (!firstName || !lastName || (!email && !phoneNumber)) {
      setErrorMessages(["All fields must be filled."]);
      return;
    }

    try {
      const response = await fetch(
        "https://db.oneaichat.com/save-user-details",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            phoneNumber,
            firstName,
            lastName,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const updatedAuthUser = {
          ...JSON.parse(localStorage.getItem("authUser")),
          email: email || undefined,
          phoneNumber: phoneNumber || undefined,
          firstName,
          lastName,
        };
        localStorage.setItem("authUser", JSON.stringify(updatedAuthUser));
        setIsEmailDisabled(!!email);
        setIsPhoneNumberDisabled(!!phoneNumber);
        setSuccessMessage("User details saved successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
        setErrorMessages([]);
        console.log("Profile saved successfully:", data);
      } else {
        console.error("Error saving profile:", response.statusText);
        setErrorMessages(["Error saving profile. Please try again."]);
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error saving profile:", error);
      setErrorMessages(["Error saving profile. Please try again."]);
      setSuccessMessage("");
    }
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://oneaichat.com/profile" />
      </Helmet>
      <Chatheader />
      <div className={styles.wrapper}>
        <div className={styles.tools}>
          <div className={styles.search}>
            <div className={styles.textboxWrapper}>
              <input
                type="text"
                placeholder="Search for Bots"
                className={styles.textbox}
                id="search-bots"
              />
              <label htmlFor="search-bots">
                <i className="fa fa-search" />
              </label>
            </div>
          </div>
          <div className={styles.chatsTools}>
            {aiList?.map((ai) => (
              <div
                className={`${styles.aiCategory} ${
                  aiName === ai?.title ? styles.active : ""
                }`}
                key={ai?.id}
                onClick={() => aiNameHandler(ai?.title, true)}
              >
                <div className={styles.aiLogoWrapper}>{ai?.icon}</div>
                <div className={styles.aiToolInfo}>
                  <div className={styles.aiTitle}>{ai?.title}</div>
                  <div className={styles.aiSubTitle}>{ai?.subTitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="profileContainer">
        <div className="profileForm">
          <div className="field">
            <label>First Name:</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="field">
            <label>Last Name:</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="field">
            <label>Phone Number:</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              disabled={isPhoneNumberDisabled}
              minLength="10"
              maxLength="10"
              required
            />
          </div>
          <div className="field">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isEmailDisabled}
              required
            />
          </div>
          {errorMessages.length > 0 && (
            <div className="errorMessages">
              {errorMessages.map((message, index) => (
                <div key={index}>{message}</div>
              ))}
            </div>
          )}
          {successMessage && (
            <div className="successMessage">{successMessage}</div>
          )}
          <button className="saveButton" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
