import React, { useState } from 'react';
import { axiosInstance } from 'config/axios';



const Billdesk = () => {
  const [apiResponse, setAPIResponse] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);

  const handleAPICall = async () => {
    try {
      // Make your API call here
      const response = await axiosInstance.post('/api/retrieve-transaction'); // Add await here
     
      setAPIResponse(response.data); // Assuming the response is in the 'data' property
      console.log(response.data)

     setDecodedToken(response.data.decodedToken); // Replace 'decodedToken' with the actual key in your respons
      // If you want to log the decoded token
      console.log('Decoded Token:', decodedToken);
      //const decodedToken = jwt.decode(response.data, { complete: true });
      //console.log('Decoded Data:', decodedToken);
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  const renderReceipt = () => {
    if (!apiResponse) {
      return null;
    }

    const {
      transactionid,
      orderid,
      transaction_date,
      amount,
      auth_status,
      transaction_error_desc,
    } = apiResponse;

    return (
      <div>
        <center>
        <img src="/assets/img/logo/2.jpeg" alt="logo"  style={{ width: '175px', height: '165px', display: 'block' }} />
        </center>
        <table>
        <h3>Details</h3>
          <tbody>
            <tr>  
              <td>Status Description:</td>
              <td>{transaction_error_desc}</td>
            </tr>
            <tr>
              <td>Payment Mode:</td>
              <td>Online Payment</td>
            </tr>
            <tr>
              <td>Transaction ID:</td>
              <td>{transactionid}</td>
            </tr>
            <tr>
              <td>Order ID:</td>
              <td>{orderid}</td>
            </tr>
            <tr>
              <td>Transaction Date:</td>
              <td>{transaction_date}</td>
            </tr>
            <tr>
              <td>Amount:</td>
              <td>{amount}</td>
            </tr>
            <tr>
              <td>Authorization Status:</td>
              <td>{auth_status}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <h2>Retrieve Transaction Page</h2>
      <button className="retrieve-button" onClick={handleAPICall}>Retrieve Transaction</button>

      {renderReceipt()}

      {decodedToken && (
        <div>
          <h3>Decoded Token</h3>
          <pre>{JSON.stringify(decodedToken, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default Billdesk;
