import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "components/Header";
import Footer from "components/Footer";
import Link from "components/Link";
import RightArrowIcon from "components/Icons/RightArrow";
import CheckIcon from "components/Icons/Check";
import PublicChat from "components/PublicChat";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "config/axios";
import { Helmet } from "react-helmet";

const Home = () => {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionPrompt, setSubscriptionPrompt] = useState(false);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const categories = [
    "Technical Writing",
    "Health",
    "Marketing",
    "Coding",
    "Audio/Music",
    "Video",
    "Mathematics",
    "Faith",
    "Science",
    "Art/Design",
    "Writing",
    "Finance",
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCategoryIndex((prevIndex) => (prevIndex + 1) % categories.length);
    }, 1000);

    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, []); // Empty dependency array to run the effect only once

  {
    /*Apple sign up start--------- */
  }
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  //console.log(getCookie("token"), "cookie-Token-------");
  //console.log(email, "Email-------");
  useEffect(() => {
    // Check if the user is logged in
    const token = getCookie("token");
    const email = getCookie("email");
    const isLoggedIn = !!token && !!email;
    console.log(token, "Token-------");
    if (token != "") {
      // const updatedEmail = email.replace(/%/g, '@');
      //  console.log(updatedEmail, "Email-------");
      const fetchData = async () => {
        try {
          const response = await axiosInstance.post(`/user-data`, { token });
          console.log(response, "user-apple-response--------------");

          if (response.data) {
            const { _id, email, token, deleted, createdAt, updatedAt } =
              response.data;
            const authUser = {
              _id,
              email,
              token,
              deleted,
              createdAt,
              updatedAt,
            };
            setIsLoggedIn(true);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("authUser", JSON.stringify(authUser));
            localStorage.setItem("loggedIn", true);
            const cookies = document.cookie.split(";");

            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i];
              const eqPos = cookie.indexOf("=");
              const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.oneaichat.com`;
            }
            window.location.href = "/chat";
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      if (isLoggedIn) {
        fetchData();
      }
    }
  }, []);
  {
    /*Apple sign up close--------- */
  }

  const closeMessage = () => {
    setShowPopup(false);
    setShowMessage(false);
  };
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    axiosInstance
      .get(`/subscriptions`)
      .then((response) => {
        console.log("Response data:", response.data);
        if (response.data.status) {
          setSubscriptions(response.data.subscriptions);
        } else {
          console.error("Failed to fetch subscriptions");
        }
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
      });
  }, []);

  //  const handlePayClick = async (e, planId, planName,planPrice) => {
  //   setShowMessage(true);
  //   setLoginPrompt(true);
  //   setTimeout(() => {
  //     setShowMessage(false);
  //     setLoginPrompt(false);
  //         }, 3000);
  // //   const isPurchaseCompleted = localStorage.getItem('purchaseCompleted');
  // //   if (!isPurchaseCompleted) {
  // //     localStorage.setItem('selectedPlanName', planName);
  // //     localStorage.setItem('selectedPlanId', planId);
  // //     localStorage.setItem('selectedPlanPrice', planPrice);
  // // }

  // //       // Extract user details from local storage
  // //   const authUser = JSON.parse(localStorage.getItem("authUser"));

  // //   if (!authUser) {
  // //     console.error('AuthUser not found in local storage');
  // //     setLoginPrompt(true);  // Show the login prompt message
  // //     setShowMessage(true);
  // //     e.preventDefault();  // Prevent form submission
  // //     setTimeout(() => {
  // //       window.location.href = '/login'; // Redirect to login page after 3 seconds
  // //     }, 4000);
  // //     return;
  // //   } else {
  // //     // If the user is logged in, check for subscription
  // //     try {
  // //       const email = authUser.email;
  // //       const response = await axiosInstance.post(`/check-subscription`, { user_email: email });
  // //       if (response.data.hasSubscription) {
  // //         // Display a user-friendly message to inform the user.

  // //         setSubscriptionPrompt(true);  // Show the subscription prompt message
  // //         setShowMessage(true);

  // //         e.preventDefault(); // Prevent further processing or redirection
  // //         setTimeout(() => {
  // //           window.location.href = `/payment_success`;
  // //       }, 0.900);
  // //         return; // Stop the function here if the user has a subscription
  // //       }

  // //     } catch (err) {
  // //       console.error(err);
  // //       e.preventDefault(); // Prevent further processing in case of an error
  // //       return;
  // //     }
  // //   }
  // //   const email = authUser.email;
  // //   const firstName = authUser.firstName;
  // //   const lastName = authUser.lastName;

  // //   const subscriptionData = {
  // //         plan_id:planId,
  // //         plan_name:planName,
  // //         user_first_name: firstName,
  // //         user_last_name: lastName,
  // //         user_email: email
  // //       };
  // //       try {
  // //         const response = await axiosInstance.post(`/save-subscription`,subscriptionData );

  // //         if (response.data.success) {
  // //         console.log('Data saved sucessfully')
  // //         } else {
  // //           console.error('Failed to save subscription');
  // //           e.preventDefault();
  // //         }
  // //       } catch (error) {
  // //         console.error('Axios Error:', error.response.data);
  // //         e.preventDefault();
  // //       }
  //     };

  const [plans, setPlans] = useState([]);
  const [userCountry, setUserCountry] = useState(null); // Initialize userCountry state
  useEffect(() => {
    async function fetchData() {
      try {
        const country = await getUserCountry();
        setUserCountry(country); // Set userCountry state after fetching
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  const getUserCountry = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      console.log(response, "country code ipppppp");
      return response.data.country_code;
    } catch (error) {
      console.error("Error fetching user country:", error);
      return null;
    }
  };

  const getExchangeRates = async (userCountry) => {
    try {
      const response = await axios.get(
        `https://api.exchangerate-api.com/v4/latest/${userCountry}`
      );
      return response.data.rates;
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
      return null;
    }
  };

  const updatePrices = (exchangeRates) => {
    if (!exchangeRates) return [];

    const pricesInLocalCurrency = {
      USD: 1, // Default currency is USD
      EUR: exchangeRates.EUR,
      // Add more currencies as needed
    };

    const subscriptionPlans = [
      { name: "Basic", price: 10 },
      { name: "Standard", price: 20 },
      { name: "Premium", price: 30 },
    ];

    return subscriptionPlans.map((plan) => {
      const priceInLocalCurrency = plan.price * pricesInLocalCurrency["USD"];
      const formattedPrice = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: navigator.language,
      }).format(priceInLocalCurrency);
      return { ...plan, price: formattedPrice };
    });
  };

  const handlePayClick = async (e, planId, planName, planPrice, stripe_id) => {
    let selectedStripeId;

    // Extract the country code from userCountry
    const countryCode = userCountry.toLowerCase(); // Assuming userCountry is in uppercase

    // Access the corresponding property from the stripe_id object based on the country code
    if (countryCode === "us") {
      selectedStripeId = stripe_id.stripe_id_us;
    } else if (countryCode === "ca") {
      selectedStripeId = stripe_id.stripe_id_ca;
    } else if (countryCode === "gb") {
      selectedStripeId = stripe_id.stripe_id_gb || stripe_id.stripe_id_uk;
    } else if (countryCode === "fr") {
      selectedStripeId = stripe_id.stripe_id_fr;
    } else if (countryCode === "de") {
      selectedStripeId = stripe_id.stripe_id_de;
    } else if (countryCode === "ru") {
      selectedStripeId = stripe_id.stripe_id_ru;
    } else if (countryCode === "cn") {
      selectedStripeId = stripe_id.stripe_id_cn;
    } else if (countryCode === "jp") {
      selectedStripeId = stripe_id.stripe_id_jp;
    } else if (countryCode === "br") {
      selectedStripeId = stripe_id.stripe_id_br;
    } else if (countryCode === "au") {
      selectedStripeId = stripe_id.stripe_id_au;
    } else if (countryCode === "it") {
      selectedStripeId = stripe_id.stripe_id_it;
    } else if (countryCode === "kr") {
      selectedStripeId = stripe_id.stripe_id_kr;
    } else if (countryCode === "mx") {
      selectedStripeId = stripe_id.stripe_id_mx;
    } else if (countryCode === "za") {
      selectedStripeId = stripe_id.stripe_id_za;
    } else if (countryCode === "se") {
      selectedStripeId = stripe_id.stripe_id_se;
    } else if (countryCode === "pl") {
      selectedStripeId = stripe_id.stripe_id_pl;
    } else if (countryCode === "es") {
      selectedStripeId = stripe_id.stripe_id_es;
    } else if (countryCode === "ch") {
      selectedStripeId = stripe_id.stripe_id_ch;
    } else if (countryCode === "at") {
      selectedStripeId = stripe_id.stripe_id_at;
    } else if (countryCode === "be") {
      selectedStripeId = stripe_id.stripe_id_be;
    } else if (countryCode === "nl") {
      selectedStripeId = stripe_id.stripe_id_nl;
    } else if (countryCode === "no") {
      selectedStripeId = stripe_id.stripe_id_no;
    } else if (countryCode === "dk") {
      selectedStripeId = stripe_id.stripe_id_dk;
    } else if (countryCode === "fi") {
      selectedStripeId = stripe_id.stripe_id_fi;
    } else if (countryCode === "cz") {
      selectedStripeId = stripe_id.stripe_id_cz;
    } else if (countryCode === "ua") {
      selectedStripeId = stripe_id.stripe_id_ua;
    } else if (countryCode === "hk") {
      selectedStripeId = stripe_id.stripe_id_hk;
    } else if (countryCode === "ar") {
      selectedStripeId = stripe_id.stripe_id_ar;
    } else if (countryCode === "cl") {
      selectedStripeId = stripe_id.stripe_id_cl;
    } else if (countryCode === "bd") {
      selectedStripeId = stripe_id.stripe_id_bd;
    } else if (countryCode === "eg") {
      selectedStripeId = stripe_id.stripe_id_eg;
    } else if (countryCode === "ng") {
      selectedStripeId = stripe_id.stripe_id_ng;
    } else if (countryCode === "ke") {
      selectedStripeId = stripe_id.stripe_id_ke;
    } else if (countryCode === "tn") {
      selectedStripeId = stripe_id.stripe_id_tn;
    } else if (countryCode === "bh") {
      selectedStripeId = stripe_id.stripe_id_bh;
    } else if (countryCode === "ee") {
      selectedStripeId = stripe_id.stripe_id_ee;
    } else if (countryCode === "is") {
      selectedStripeId = stripe_id.stripe_id_is;
    } else if (countryCode === "cy") {
      selectedStripeId = stripe_id.stripe_id_cy;
    } else if (countryCode === "bg") {
      selectedStripeId = stripe_id.stripe_id_bg;
    } else if (countryCode === "hr") {
      selectedStripeId = stripe_id.stripe_id_hr;
    } else if (countryCode === "pt") {
      selectedStripeId = stripe_id.stripe_id_pt;
    } else if (countryCode === "lv") {
      selectedStripeId = stripe_id.stripe_id_lv;
    } else if (countryCode === "lt") {
      selectedStripeId = stripe_id.stripe_id_lt;
    } else if (countryCode === "mt") {
      selectedStripeId = stripe_id.stripe_id_mt;
    } else if (countryCode === "ro") {
      selectedStripeId = stripe_id.stripe_id_ro;
    } else if (countryCode === "si") {
      selectedStripeId = stripe_id.stripe_id_si;
    } else if (countryCode === "rs") {
      selectedStripeId = stripe_id.stripe_id_rs;
    } else if (countryCode === "mk") {
      selectedStripeId = stripe_id.stripe_id_mk;
    } else if (countryCode === "me") {
      selectedStripeId = stripe_id.stripe_id_me;
    } else if (countryCode === "ba") {
      selectedStripeId = stripe_id.stripe_id_ba;
    } else if (countryCode === "sk") {
      selectedStripeId = stripe_id.stripe_id_sk;
    } else if (countryCode === "lu") {
      selectedStripeId = stripe_id.stripe_id_lu;
    } else if (countryCode === "ad") {
      selectedStripeId = stripe_id.stripe_id_ad;
    } else if (countryCode === "am") {
      selectedStripeId = stripe_id.stripe_id_am;
    } else if (countryCode === "az") {
      selectedStripeId = stripe_id.stripe_id_az;
    } else if (countryCode === "by") {
      selectedStripeId = stripe_id.stripe_id_by;
    } else if (countryCode === "ge") {
      selectedStripeId = stripe_id.stripe_id_ge;
    } else if (countryCode === "md") {
      selectedStripeId = stripe_id.stripe_id_md;
    } else if (countryCode === "kz") {
      selectedStripeId = stripe_id.stripe_id_kz;
    } else if (countryCode === "tj") {
      selectedStripeId = stripe_id.stripe_id_tj;
    } else if (countryCode === "tm") {
      selectedStripeId = stripe_id.stripe_id_tm;
    } else if (countryCode === "kg") {
      selectedStripeId = stripe_id.stripe_id_kg;
    } else if (countryCode === "uz") {
      selectedStripeId = stripe_id.stripe_id_uz;
    } else if (countryCode === "bt") {
      selectedStripeId = stripe_id.stripe_id_bt;
    } else if (countryCode === "mv") {
      selectedStripeId = stripe_id.stripe_id_mv;
    } else if (countryCode === "kh") {
      selectedStripeId = stripe_id.stripe_id_kh;
    } else if (countryCode === "la") {
      selectedStripeId = stripe_id.stripe_id_la;
    } else if (countryCode === "mm") {
      selectedStripeId = stripe_id.stripe_id_mm;
    } else if (countryCode === "vn") {
      selectedStripeId = stripe_id.stripe_id_vn;
    } else if (countryCode === "th") {
      selectedStripeId = stripe_id.stripe_id_th;
    } else if (countryCode === "my") {
      selectedStripeId = stripe_id.stripe_id_my;
    } else if (countryCode === "sg") {
      selectedStripeId = stripe_id.stripe_id_sg;
    } else if (countryCode === "ph") {
      selectedStripeId = stripe_id.stripe_id_ph;
    } else if (countryCode === "np") {
      selectedStripeId = stripe_id.stripe_id_np;
    } else if (countryCode === "lk") {
      selectedStripeId = stripe_id.stripe_id_lk;
    } else if (countryCode === "qa") {
      selectedStripeId = stripe_id.stripe_id_qa;
    } else if (countryCode === "jo") {
      selectedStripeId = stripe_id.stripe_id_jo;
    } else if (countryCode === "om") {
      selectedStripeId = stripe_id.stripe_id_om;
    } else if (countryCode === "ye") {
      selectedStripeId = stripe_id.stripe_id_ye;
    } else if (countryCode === "il") {
      selectedStripeId = stripe_id.stripe_id_il;
    } else if (countryCode === "pk") {
      selectedStripeId = stripe_id.stripe_id_pk;
    } else if (countryCode === "kw") {
      selectedStripeId = stripe_id.stripe_id_kw;
    } else if (countryCode === "sa") {
      selectedStripeId = stripe_id.stripe_id_sa;
    } else if (countryCode === "tr") {
      selectedStripeId = stripe_id.stripe_id_tr;
    } else if (countryCode === "ae") {
      selectedStripeId = stripe_id.stripe_id_ae;
    } else if (countryCode === "af") {
      selectedStripeId = stripe_id.stripe_id_af;
    } else if (countryCode === "al") {
      selectedStripeId = stripe_id.stripe_id_al;
    } else if (countryCode === "dz") {
      selectedStripeId = stripe_id.stripe_id_dz;
    } else if (countryCode === "ao") {
      selectedStripeId = stripe_id.stripe_id_ao;
    } else if (countryCode === "ai") {
      selectedStripeId = stripe_id.stripe_id_ai;
    } else if (countryCode === "aq") {
      selectedStripeId = stripe_id.stripe_id_aq;
    } else if (countryCode === "ag") {
      selectedStripeId = stripe_id.stripe_id_ag;
    } else if (countryCode === "bs") {
      selectedStripeId = stripe_id.stripe_id_bs;
    } else if (countryCode === "bb") {
      selectedStripeId = stripe_id.stripe_id_bb;
    } else if (countryCode === "bz") {
      selectedStripeId = stripe_id.stripe_id_bz;
    } else if (countryCode === "bj") {
      selectedStripeId = stripe_id.stripe_id_bj;
    } else if (countryCode === "bm") {
      selectedStripeId = stripe_id.stripe_id_bm;
    } else if (countryCode === "bo") {
      selectedStripeId = stripe_id.stripe_id_bo;
    } else if (countryCode === "bw") {
      selectedStripeId = stripe_id.stripe_id_bw;
    } else if (countryCode === "bn") {
      selectedStripeId = stripe_id.stripe_id_bn;
    } else if (countryCode === "bf") {
      selectedStripeId = stripe_id.stripe_id_bf;
    } else if (countryCode === "bi") {
      selectedStripeId = stripe_id.stripe_id_bi;
    } else if (countryCode === "cv") {
      selectedStripeId = stripe_id.stripe_id_cv;
    } else if (countryCode === "ky") {
      selectedStripeId = stripe_id.stripe_id_ky;
    } else if (countryCode === "cf") {
      selectedStripeId = stripe_id.stripe_id_cf;
    } else if (countryCode === "td") {
      selectedStripeId = stripe_id.stripe_id_td;
    } else if (countryCode === "co") {
      selectedStripeId = stripe_id.stripe_id_co;
    } else if (countryCode === "km") {
      selectedStripeId = stripe_id.stripe_id_km;
    } else if (countryCode === "cg") {
      selectedStripeId = stripe_id.stripe_id_cg;
    } else if (countryCode === "cr") {
      selectedStripeId = stripe_id.stripe_id_cr;
    } else if (countryCode === "ci") {
      selectedStripeId = stripe_id.stripe_id_ci;
    } else if (countryCode === "ga") {
      selectedStripeId = stripe_id.stripe_id_ga;
    } else if (countryCode === "gm") {
      selectedStripeId = stripe_id.stripe_id_gm;
    } else if (countryCode === "gh") {
      selectedStripeId = stripe_id.stripe_id_gh;
    } else if (countryCode === "gr") {
      selectedStripeId = stripe_id.stripe_id_gr;
    } else if (countryCode === "gd") {
      selectedStripeId = stripe_id.stripe_id_gd;
    } else if (countryCode === "gu") {
      selectedStripeId = stripe_id.stripe_id_gu;
    } else if (countryCode === "gt") {
      selectedStripeId = stripe_id.stripe_id_gt;
    } else if (countryCode === "gn") {
      selectedStripeId = stripe_id.stripe_id_gn;
    } else if (countryCode === "gw") {
      selectedStripeId = stripe_id.stripe_id_gw;
    } else if (countryCode === "gy") {
      selectedStripeId = stripe_id.stripe_id_gy;
    } else if (countryCode === "ht") {
      selectedStripeId = stripe_id.stripe_id_ht;
    } else if (countryCode === "hn") {
      selectedStripeId = stripe_id.stripe_id_hn;
    } else if (countryCode === "hu") {
      selectedStripeId = stripe_id.stripe_id_hu;
    } else if (countryCode === "dj") {
      selectedStripeId = stripe_id.stripe_id_dj;
    } else if (countryCode === "dm") {
      selectedStripeId = stripe_id.stripe_id_dm;
    } else if (countryCode === "do") {
      selectedStripeId = stripe_id.stripe_id_do;
    } else if (countryCode === "sv") {
      selectedStripeId = stripe_id.stripe_id_sv;
    } else if (countryCode === "gq") {
      selectedStripeId = stripe_id.stripe_id_gq;
    } else if (countryCode === "er") {
      selectedStripeId = stripe_id.stripe_id_er;
    } else if (countryCode === "sz") {
      selectedStripeId = stripe_id.stripe_id_sz;
    } else if (countryCode === "et") {
      selectedStripeId = stripe_id.stripe_id_et;
    } else if (countryCode === "fj") {
      selectedStripeId = stripe_id.stripe_id_fj;
    } else if (countryCode === "id") {
      selectedStripeId = stripe_id.stripe_id_id;
    } else if (countryCode === "iq") {
      selectedStripeId = stripe_id.stripe_id_iq;
    } else if (countryCode === "ie") {
      selectedStripeId = stripe_id.stripe_id_ie;
    } else if (countryCode === "jm") {
      selectedStripeId = stripe_id.stripe_id_jm;
    } else if (countryCode === "ki") {
      selectedStripeId = stripe_id.stripe_id_ki;
    } else if (countryCode === "lb") {
      selectedStripeId = stripe_id.stripe_id_lb;
    } else if (countryCode === "ls") {
      selectedStripeId = stripe_id.stripe_id_ls;
    } else if (countryCode === "cm") {
      selectedStripeId = stripe_id.stripe_id_cm;
    } else if (countryCode === "lr") {
      selectedStripeId = stripe_id.stripe_id_lr;
    } else if (countryCode === "ly") {
      selectedStripeId = stripe_id.stripe_id_ly;
    } else if (countryCode === "li") {
      selectedStripeId = stripe_id.stripe_id_li;
    } else if (countryCode === "mg") {
      selectedStripeId = stripe_id.stripe_id_mg;
    } else if (countryCode === "mw") {
      selectedStripeId = stripe_id.stripe_id_mw;
    } else if (countryCode === "ml") {
      selectedStripeId = stripe_id.stripe_id_ml;
    } else if (countryCode === "mr") {
      selectedStripeId = stripe_id.stripe_id_mr;
    } else if (countryCode === "mu") {
      selectedStripeId = stripe_id.stripe_id_mu;
    } else if (countryCode === "yt") {
      selectedStripeId = stripe_id.stripe_id_yt;
    } else if (countryCode === "mc") {
      selectedStripeId = stripe_id.stripe_id_mc;
    } else if (countryCode === "mn") {
      selectedStripeId = stripe_id.stripe_id_mn;
    } else if (countryCode === "ms") {
      selectedStripeId = stripe_id.stripe_id_ms;
    } else if (countryCode === "ma") {
      selectedStripeId = stripe_id.stripe_id_ma;
    } else if (countryCode === "mz") {
      selectedStripeId = stripe_id.stripe_id_mz;
    } else if (countryCode === "na") {
      selectedStripeId = stripe_id.stripe_id_na;
    } else if (countryCode === "nr") {
      selectedStripeId = stripe_id.stripe_id_nr;
    } else if (countryCode === "nc") {
      selectedStripeId = stripe_id.stripe_id_nc;
    } else if (countryCode === "nz") {
      selectedStripeId = stripe_id.stripe_id_nz;
    } else if (countryCode === "ni") {
      selectedStripeId = stripe_id.stripe_id_ni;
    } else if (countryCode === "ne") {
      selectedStripeId = stripe_id.stripe_id_ne;
    } else if (countryCode === "pw") {
      selectedStripeId = stripe_id.stripe_id_pw;
    } else if (countryCode === "pa") {
      selectedStripeId = stripe_id.stripe_id_pa;
    } else if (countryCode === "pg") {
      selectedStripeId = stripe_id.stripe_id_pg;
    } else if (countryCode === "py") {
      selectedStripeId = stripe_id.stripe_id_py;
    } else if (countryCode === "pe") {
      selectedStripeId = stripe_id.stripe_id_pe;
    } else if (countryCode === "pr") {
      selectedStripeId = stripe_id.stripe_id_pr;
    } else if (countryCode === "re") {
      selectedStripeId = stripe_id.stripe_id_re;
    } else if (countryCode === "rw") {
      selectedStripeId = stripe_id.stripe_id_rw;
    } else if (countryCode === "st") {
      selectedStripeId = stripe_id.stripe_id_st;
    } else if (countryCode === "sn") {
      selectedStripeId = stripe_id.stripe_id_sn;
    } else if (countryCode === "sc") {
      selectedStripeId = stripe_id.stripe_id_sc;
    } else if (countryCode === "sl") {
      selectedStripeId = stripe_id.stripe_id_sl;
    } else if (countryCode === "so") {
      selectedStripeId = stripe_id.stripe_id_so;
    } else if (countryCode === "ss") {
      selectedStripeId = stripe_id.stripe_id_ss;
    } else if (countryCode === "sd") {
      selectedStripeId = stripe_id.stripe_id_sd;
    } else if (countryCode === "sr") {
      selectedStripeId = stripe_id.stripe_id_sr;
    } else if (countryCode === "sj") {
      selectedStripeId = stripe_id.stripe_id_sj;
    } else if (countryCode === "tw") {
      selectedStripeId = stripe_id.stripe_id_tw;
    } else if (countryCode === "tz") {
      selectedStripeId = stripe_id.stripe_id_tz;
    } else if (countryCode === "tl") {
      selectedStripeId = stripe_id.stripe_id_tl;
    } else if (countryCode === "tg") {
      selectedStripeId = stripe_id.stripe_id_tg;
    } else if (countryCode === "to") {
      selectedStripeId = stripe_id.stripe_id_to;
    } else if (countryCode === "tt") {
      selectedStripeId = stripe_id.stripe_id_tt;
    } else if (countryCode === "tc") {
      selectedStripeId = stripe_id.stripe_id_tc;
    } else if (countryCode === "tv") {
      selectedStripeId = stripe_id.stripe_id_tv;
    } else if (countryCode === "ug") {
      selectedStripeId = stripe_id.stripe_id_ug;
    } else if (countryCode === "uy") {
      selectedStripeId = stripe_id.stripe_id_uy;
    } else if (countryCode === "vu") {
      selectedStripeId = stripe_id.stripe_id_vu;
    } else if (countryCode === "ve") {
      selectedStripeId = stripe_id.stripe_id_ve;
    } else if (countryCode === "wf") {
      selectedStripeId = stripe_id.stripe_id_wf;
    } else if (countryCode === "eh") {
      selectedStripeId = stripe_id.stripe_id_eh;
    } else if (countryCode === "zm") {
      selectedStripeId = stripe_id.stripe_id_zm;
    } else if (countryCode === "zw") {
      selectedStripeId = stripe_id.stripe_id_zw;
    }
    // from her eteh extra countries are added
    else if (countryCode === "ax") {
      selectedStripeId = stripe_id.stripe_id_ax;
    } else if (countryCode === "aw") {
      selectedStripeId = stripe_id.stripe_id_aw;
    } else if (countryCode === "ac") {
      selectedStripeId = stripe_id.stripe_id_ac;
    } else if (countryCode === "bv") {
      selectedStripeId = stripe_id.stripe_id_bv;
    } else if (countryCode === "io") {
      selectedStripeId = stripe_id.stripe_id_io;
    } else if (countryCode === "vg") {
      selectedStripeId = stripe_id.stripe_id_vg;
    } else if (countryCode === "bq") {
      selectedStripeId = stripe_id.stripe_id_bq;
    } else if (countryCode === "cd") {
      selectedStripeId = stripe_id.stripe_id_cd;
    } else if (countryCode === "ck") {
      selectedStripeId = stripe_id.stripe_id_ck;
    } else if (countryCode === "cw") {
      selectedStripeId = stripe_id.stripe_id_cw;
    } else if (countryCode === "fk") {
      selectedStripeId = stripe_id.stripe_id_fk;
    } else if (countryCode === "fo") {
      selectedStripeId = stripe_id.stripe_id_fo;
    } else if (countryCode === "gf") {
      selectedStripeId = stripe_id.stripe_id_gf;
    } else if (countryCode === "pf") {
      selectedStripeId = stripe_id.stripe_id_pf;
    } else if (countryCode === "tf") {
      selectedStripeId = stripe_id.stripe_id_tf;
    } else if (countryCode === "gi") {
      selectedStripeId = stripe_id.stripe_id_gi;
    } else if (countryCode === "gl") {
      selectedStripeId = stripe_id.stripe_id_gl;
    } else if (countryCode === "gp") {
      selectedStripeId = stripe_id.stripe_id_gp;
    } else if (countryCode === "gg") {
      selectedStripeId = stripe_id.stripe_id_gg;
    } else if (countryCode === "im") {
      selectedStripeId = stripe_id.stripe_id_im;
    } else if (countryCode === "je") {
      selectedStripeId = stripe_id.stripe_id_je;
    } else if (countryCode === "xk") {
      selectedStripeId = stripe_id.stripe_id_xk;
    } else if (countryCode === "mo") {
      selectedStripeId = stripe_id.stripe_id_mo;
    } else if (countryCode === "mq") {
      selectedStripeId = stripe_id.stripe_id_mq;
    } else if (countryCode === "nu") {
      selectedStripeId = stripe_id.stripe_id_nu;
    } else if (countryCode === "ps") {
      selectedStripeId = stripe_id.stripe_id_ps;
    } else if (countryCode === "pn") {
      selectedStripeId = stripe_id.stripe_id_pn;
    } else if (countryCode === "ws") {
      selectedStripeId = stripe_id.stripe_id_ws;
    } else if (countryCode === "sm") {
      selectedStripeId = stripe_id.stripe_id_sm;
    } else if (countryCode === "sx") {
      selectedStripeId = stripe_id.stripe_id_sx;
    } else if (countryCode === "sb") {
      selectedStripeId = stripe_id.stripe_id_sb;
    } else if (countryCode === "gs") {
      selectedStripeId = stripe_id.stripe_id_gs;
    } else if (countryCode === "bl") {
      selectedStripeId = stripe_id.stripe_id_bl;
    } else if (countryCode === "sh") {
      selectedStripeId = stripe_id.stripe_id_sh;
    } else if (countryCode === "kn") {
      selectedStripeId = stripe_id.stripe_id_kn;
    } else if (countryCode === "lc") {
      selectedStripeId = stripe_id.stripe_id_lc;
    } else if (countryCode === "mf") {
      selectedStripeId = stripe_id.stripe_id_mf;
    } else if (countryCode === "pm") {
      selectedStripeId = stripe_id.stripe_id_pm;
    } else if (countryCode === "vc") {
      selectedStripeId = stripe_id.stripe_id_vc;
    } else if (countryCode === "tk") {
      selectedStripeId = stripe_id.stripe_id_tk;
    } else if (countryCode === "ta") {
      selectedStripeId = stripe_id.stripe_id_ta;
    } else if (countryCode === "va") {
      selectedStripeId = stripe_id.stripe_id_va;
    } else {
      selectedStripeId = stripe_id.stripe_id; // Fallback to a default stripe id
    }

    console.log("Selected Stripe ID:", selectedStripeId);
    // Check if the user is already subscribed
    const authUserString = localStorage.getItem("authUser");
    const authUser = authUserString ? JSON.parse(authUserString) : null;
    try {
      const subscriptionCheckResponse = await axiosInstance.post(
        "/check-user-subscription",
        { user_id: authUser?._id }
      );

      if (subscriptionCheckResponse?.data?.hasSubscription) {
        setSubscriptionPrompt(true); // Show the subscription prompt message
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
        return;
      }

      // User is not subscribed, proceed with payment
      const isPurchaseCompleted = localStorage.getItem("purchaseCompleted");
      if (!isPurchaseCompleted) {
        localStorage.setItem("stripeId", selectedStripeId);
        localStorage.setItem("selectedPlanName", planName);
        localStorage.setItem("selectedPlanId", planId);
        localStorage.setItem("selectedPlanPrice", planPrice);
      }

      if (!authUser) {
        console.error("AuthUser not found in local storage");
        setLoginPrompt(true); // Show the login prompt message
        setShowMessage(true);
        e.preventDefault(); // Prevent form submission
        setTimeout(() => {
          window.location.href = "/login"; // Redirect to login page after 3 seconds
        }, 1000);
        return;
      } else {
        // Open the modal when pay button is clicked
        setShowPopup(true);
        setSelectedPaymentOption(null); // Reset selected payment option
      }
    } catch (error) {
      console.error("Error checking user subscription:", error);
      // Handle the error (show a message, log, etc.)
    }
  };

  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handlePaymentOptionClick = (paymentOption) => {
    setSelectedPaymentOption(paymentOption);
  };

  const handleProceed = () => {
    if (selectedPaymentOption === "Stripe") {
      handleStripePayment();
    } else if (selectedPaymentOption === "One-Time Payment") {
      handleBilldeskPayment("One-Time Payment");
    } else if (selectedPaymentOption === "Recurring Payment") {
      handleBilldeskPayment("Recurring Payment");
    }
    //till
    else if (selectedPaymentOption === "PhonePe") {
      handlePhonePePayment();
    }
  };

  const handlePhonePePayment = async () => {
    try {
      const selectedPlanId = localStorage.getItem("selectedPlanId");
      const selectedPlanName = localStorage.getItem("selectedPlanName");
      const selectedPlanPrice = localStorage.getItem("selectedPlanPrice");
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const email = authUser.email;
      const userid = authUser._id;
      const url = `https://db.oneaichat.com/pay?planId=${selectedPlanId}&planName=${selectedPlanName}&planPrice=${selectedPlanPrice}&email=${email}&userid=${userid}`;
      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`Error initiating payment: ${response.statusText}`);
      }

      const responseData = await response.json();

      // Check if responseData has the expected structure
      if (responseData && responseData.redirectUrl) {
        window.location.href = responseData.redirectUrl;
      } else {
        console.error("Unexpected response format:", responseData);
        // Handle unexpected response format, show a message, or redirect to an error page
      }
    } catch (error) {
      console.error("Payment initiation error:", error);
      // Handle the error, show a message, or redirect to an error page
    }
  };

  {
    /*Billdesk starts  */
  }
  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");

  // const handleBilldeskPayment = async () => {
  // const selectedPlanId = localStorage.getItem('selectedPlanId');
  // const selectedPlanName = localStorage.getItem('selectedPlanName');
  // const selectedPlanPrice = localStorage.getItem('selectedPlanPrice');
  // const authUserString = localStorage.getItem('authUser');

  // const authUser = authUserString ? JSON.parse(authUserString) : null;

  // // Access user information from the authUser object
  // const userEmail = authUser.email;
  // const userid=authUser._id;
  // console.log(userid)
  // try {
  //   const response = await fetch(`https://db.oneaichat.com/api/initiate-payment`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({selectedPlanPrice: parseFloat(selectedPlanPrice), orderId, customerName, customerEmail: userEmail, selectedPlanId: selectedPlanId,  selectedPlanName: selectedPlanName,userid:userid }),
  //   });

  //   const result = await response.json();

  //   const authToken = result.flowConfig.authToken;
  //   const bdOrderId = result.flowConfig.bdOrderId;

  //   const flow_config = {
  //     merchantId: "KALETECHPL",
  //     bdOrderId: bdOrderId,
  //     authToken: authToken,
  //     childWindow: false,
  //     returnUrl: "https://db.oneaichat.com/api/callback",
  //     retryCount: 0
  //   };
  //   var responseHandler = function(txn) {
  //     if (txn.response) {
  //         alert("callback received status:: ", txn.status);
  //         alert("callback received response:: ", txn.response)//response handler to be implemented by the merchant
  //       }
  //       console.log("txn.response"+txn.response)
  //     };
  //   const config = {
  //     responseHandler: responseHandler,

  //     flowConfig: flow_config,
  //     flowType: "payments"
  //   };

  //   window.loadBillDeskSdk(config);

  //   console.log("loadinggg");
  // } catch (error) {
  //   console.error('Error initiating payment:', error);
  //   // alert('Internal server error. Please try again later.');
  // }
  // }

  const handleBilldeskPayment = async (type) => {
    const selectedPlanId = localStorage.getItem("selectedPlanId");
    const selectedPlanName = localStorage.getItem("selectedPlanName");
    const selectedPlanPrice = localStorage.getItem("selectedPlanPrice");
    const authUserString = localStorage.getItem("authUser");

    const authUser = authUserString ? JSON.parse(authUserString) : null;
    let routeUrl;
    if (type === "One-Time Payment") {
      routeUrl = `https://db.oneaichat.com/api/initiate-payment`;
    }

    if (type === "Recurring Payment") {
      routeUrl = `https://db.oneaichat.com/api/initiate-recurring-payment`;
    }

    if (type === "Stop Recurring Payment") {
      routeUrl = `https://db.oneaichat.com/api/stop-recurring`;
    }
    // Access user information from the authUser object
    const userEmail = authUser.email;
    const userid = authUser._id;
    console.log(userid);

    if (type === "One-Time Payment") {
      try {
        const response = await fetch(routeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPlanPrice: parseFloat(selectedPlanPrice),
            orderId,
            customerName,
            customerEmail: userEmail,
            selectedPlanId: selectedPlanId,
            selectedPlanName: selectedPlanName,
            userid: userid,
          }),
        });

        const result = await response.json();

        const authToken = result.flowConfig.authToken;
        const bdOrderId = result.flowConfig.bdOrderId;
        const MerchantID = result.flowConfig.merchantId;

        const flow_config = {
          merchantId: MerchantID,
          bdOrderId: bdOrderId,
          authToken: authToken,
          childWindow: false,
          returnUrl: "https://db.oneaichat.com/api/callback",
          retryCount: 0,
        };
        var responseHandler = function (txn) {
          if (txn.response) {
            alert("callback received status:: ", txn.status);
            alert("callback received response:: ", txn.response); //response handler to be implemented by the merchant
          }
          console.log("txn.response" + txn.response);
        };
        const config = {
          responseHandler: responseHandler,

          flowConfig: flow_config,
          flowType: "payments",
        };

        window.loadBillDeskSdk(config);

        console.log("loadinggg");
      } catch (error) {
        console.error("Error initiating payment:", error);
        // alert('Internal server error. Please try again later.');
      }
    }

    if (type === "Recurring Payment") {
      try {
        const response = await fetch(routeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPlanPrice: parseFloat(selectedPlanPrice),
            orderId,
            customerName,
            customerEmail: userEmail,
            selectedPlanId: selectedPlanId,
            selectedPlanName: selectedPlanName,
            userid: userid,
          }),
        });

        const result = await response.json();

        console.log("this is the result ");
        console.log(result);

        const authToken = result.flowConfig.authToken;
        const bdOrderId = result.flowConfig.bdOrderId;
        const MerchantID = result.flowConfig.merchantId;

        const flow_config = {
          merchantId: MerchantID,
          bdOrderId: bdOrderId,
          authToken: authToken,
          childWindow: false,
          returnUrl: "https://db.oneaichat.com/api/callback",
          // crossButtonHandling: "Y",
          retryCount: 0,
          prefs: {
            payment_categories: ["card", "nb", "upi"],
            allowed_bins: ["459150", "525211", "540000"],
          },
        };

        var responseHandler = function (txn) {
          if (txn.response) {
            alert("callback received status:: ", txn.status);
            alert("callback received response:: ", txn.response);
          }
        };

        var config = {
          responseHandler: responseHandler,
          flowConfig: flow_config,
          flowType: "payments",
        };

        window.loadBillDeskSdk(config);

        console.log("loadinggg");
      } catch (error) {
        console.error("Error initiating payment:", error);
        // alert('Internal server error. Please try again later.');
      }
    }

    if (type === "Stop Recurring Payment") {
      try {
        const response = await fetch(routeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPlanPrice: parseFloat(selectedPlanPrice),
            orderId,
            customerName,
            customerEmail: userEmail,
            selectedPlanId: selectedPlanId,
            selectedPlanName: selectedPlanName,
            userid: userid,
          }),
        });

        const result = await response.json();

        console.log("this is the result ");
        console.log(result);

        const authToken = result.flowConfig.authToken;
        const mandateTokenId = result.flowConfig.mandateTokenId;
        const MerchantID = result.flowConfig.merchantId;

        var flow_config = {
          merchantId: MerchantID,
          mandateTokenId: mandateTokenId,

          authToken: authToken,
          childWindow: true,
          returnUrl: "https://db.oneaichat.com/api/stop-callback",
          // crossButtonHandling: "Y",
          retryCount: 3,
        };
        console.log("this is the flow consig on front end", flow_config);

        var responseHandler = function (txn) {
          if (txn.response) {
            alert("callback received status:: ", txn.status);
            alert("callback received response:: ", txn.response);
          }
        };

        var config = {
          responseHandler: responseHandler,
          flowConfig: flow_config,
          flowType: "modify_mandate",
        };

        window.loadBillDeskSdk(config);

        console.log("loadinggg");
      } catch (error) {
        console.error("Error initiating payment:", error);
        // alert('Internal server error. Please try again later.');
      }
    }
  };

  {
    /*Billdesk ends */
  }

  const handleStripePayment = () => {
    const stripeId = localStorage.getItem("stripeId");

    const form = document.createElement("form");
    form.action = "https://stripe.oneaichat.com/create-checkout-session";
    form.method = "POST";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "stripeId";
    input.value = stripeId;

    form.appendChild(input);

    document.body.appendChild(form);

    form.submit();
  };

  return (
    <div>
      <Helmet>
        <title>OneAIChat: Chat with AI Chatbot for Instant Conversations</title>
        <meta
          name="google-site-verification"
          content="TEb40LI-LMfGmAhUaRi9pVUoltbyYCM20BZJF5O_nk8"
        />
        {/* <meta
          name="description"
          content="OneAIChat is a machine learning and artificial intelligence research
      platform offering software as a service focused on Generative AI. Chat with AI
      Now!"
        /> */}

        {/* <meta
          name="description"
          content="OneAIChat provides instant access to the latest Pro Gen AI services such as ChatGPT-Claude-Mistral and various other advanced bots. Chat with AI online now!"
        /> */}

        <meta
          name="description"
          content="OneAIChat provides instant access to the latest Pro Gen AI services such as ChatGPT-Claude-Mistral-Gemini-Cohere-Haiper-Ideogram-Somebody. Chat with AI now!"
        />
        <meta
          name="keywords"
          content="AI chatbot, Ai Chat online, AI Chat Website, Free Ai Chat Online, Free Chatbot Website, Ai Chatbot Website, Free AI Chatbot Online, Artificial Intelligence Chatbot, Free Artificial Intelligence Chatbot Online, Chat With AI, Top AI Chatbot, Best Chatbot website, Online Chatbot Website, AI Aggregator"
        />
        <link rel="canonical" href="https://oneaichat.com/" />
      </Helmet>
      <Header />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <PublicChat />

      <main className="wrapper">
        <div className="container">
          <p
            className="sectionn"
            style={{ paddingTop: "10px", textSize: "15px", fontWeight: "bold" }}
          >
            OneAIChat aggregates open-source and copyrighted content including
            image/video/audio/document/text generation for user convenience. We
            don't own or transfer ownership of any provided content. Users are
            advised to fact-check information, respect copyrights, and use
            generated content responsibly. For more details, visit our{" "}
            <a href="/terms-use" style={{ textDecoration: "underline" }}>
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/privacy-policy" style={{ textDecoration: "underline" }}>
              Privacy Policy
            </a>
            .
          </p>
        </div>

        {/* <!-- Hero Start --> */}
        <section
          className="aai-hero-one position-relative"
          // style={{
          //   background:
          //     "url('assets/img/hero/hero-bg-1.jpeg') no-repeat center center/cover",
          // }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-7">
                <div className="aai-hero-one-content">
                  <h1 className="aai-hero-one-title">
                    High-quality way to write your <br />
                    <span
                      className="gradient-text typing-animation"
                      data-strings={JSON.stringify(categories)}
                    >
                      {categories[categoryIndex]}
                    </span>
                  </h1>
                  <br />
                  <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                    <Link to="/pricing" className="aai-gradient-outline-btn">
                      Get Started
                    </Link>
                    <Link to="/about" className="aai-btn btn-pill-solid">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-xl-5 mt-5 mt-lg-0">
                <div className="aai-hero-one-img">
                  <img
                    src="assets/img/hero/dashboard-img-3.png"
                    className="img-fluid aai-hero-img d-none d-xl-block"
                    alt="chat-screenshot"
                    width={"45%"}
                  />
                  <img
                    src="assets/img/hero/dashboard-img-3.png"
                    className="img-fluid aai-hero-img d-xl-none"
                    alt="chat-screenshot"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Hero End --> */}

        {/* <!-- Writing Step --> */}
        <section className="writing-steps-area py-5 text-center">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row justify-content-center">
                <div className="writing-step">
                  <h4 className="writing-step-title">
                    Simplicity and Power of Machine Learning
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="writing-steps-area py-5 text-center">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row justify-content-center">
                <div className="writing-step">
                  <h4 className="writing-step-title">
                    Complexity of Deep Learning
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="writing-steps-area py-5 text-center">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row justify-content-center">
                <div className="writing-step">
                  <h4 className="writing-step-title">
                    Speed of Artificial Intelligence
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="writing-steps-area py-5 text-center">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row justify-content-center">
                <div className="writing-step">
                  <h4 className="writing-step-title">
                    One AI Chat - " Discipline your focus "
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="writing-steps-area py-5 text-center">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row justify-content-center">
                <div className="writing-step">
                  <h4 className="writing-step-title">
                    Everything Unlimited – No Baggage
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Writing Step End --> */}

        {/* <!-- Service Start --> */}
        {/* <section className="aai-services">
          <div
            // style={{
            //   background:
            //     "url('assets/img/bg/service-1.jpeg') no-repeat center center/cover",
            // }}
            className="pt-120 pb-60"
          >
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-xl-6 col-lg order-2 order-lg-1 mt-5 mt-lg-0"
                  data-aos="fade-right"
                >
                  <div>
                    <img
                      src="assets/img/services/service-1.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg order-1 order-lg-2">
                  <div className="singleclick">
                    <h2 className="section-title mb-4">
                      Create Content in Single Click with Oneaichat Power
                    </h2>
                    <p className="section-desc">
                      Give our Oneaichat a few descriptions and we'll automatically
                      create blog articles, product descriptions and more for
                      you within just few second.
                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Let's Communicate with your customers with emotions
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Start creating powerful content, for your next ads
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Settling In Country
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="/pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="pt-60 pb-60"
            // style={{
            //   background:
            //     "url('assets/img/bg/service-2.jpeg') no-repeat center center/cover",
            // }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg">
                  <div>
                    <h2 className="section-title mb-4">
                      Write Blog Posts, Stories, & Even Books
                    </h2>
                    <p className="section-desc">
                      Just let me know the topic or genre you have in mind, and
                      I'll assist you in creating engaging content. Feel free to
                      provide any specific instructions or ideas you'd like me
                      to incorporate.
                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          The Oneaichat-generated text is always fresh and relevant.
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          The Oneaichat-generated text is guaranteed to be unique and
                          original.
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          The Oneaichat-generated text is SEO-friendly and
                          authoritative.
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="/pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg mt-5 mt-lg-0"
                  data-aos="fade-left"
                >
                  <div>
                    <img
                      src="assets/img/services/service-2.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="pt-60 pb-120"
            // style={{
            //   background:
            //     "url('assets/img/bg/service-1.jpeg') no-repeat center center/cover",
            // }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-xl-6 col-lg order-2 order-lg-1 mt-5 mt-lg-0"
                  data-aos="fade-right"
                >
                  <div>
                    <img
                      src="assets/img/services/service-3.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg order-1 order-lg-2">
                  <div>
                    <h2 className="section-title mb-4">
                      Instruct to our Oneaichat and generate copy
                    </h2>
                    <p className="section-desc">
                      Give our Oneaichat a few descriptions and we'll automatically
                      create blog articles, product descriptions and more for
                      you within just few second.
                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Entering & Leaving From Country
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Country Citizenship
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Settling In Country
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Service End --> */}

        {/* <!-- Aai Feature  --> */}

        {/* <!-- Aai Feature  End--> */}

        {/* <!-- Testimonials Start--> */}
        {/* <Testimonials /> */}
        {/* <!-- Testimonials End --> */}

        {/* <!-- Pricing Table Start --> */}
        <br />
        <br />
        <section className="aai-price-tables pb-120">
          <div className="container">
            <div
              className="row align-items-center mb-5"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="col-xl-5">
                {/* <div className="secttionn">
                <h2 className="section-title">
                  The Right Plan for Transparent Pricing
                </h2>
              </div> */}
              </div>
              <div className="col-xl-7">
                {/* <div>
                <p className="section-desc">
                  We have several powerful plans to showcase your business and
                  get discovered as a creative entrepreneurs. Everything your
                  need.
                </p>
              </div> */}
              </div>
            </div>
            {loginPrompt && (
              <div className={`message-box ${showMessage ? "active" : ""}`}>
                <button className="close-btn" onClick={closeMessage}>
                  &times;
                </button>
                Please Login First.
              </div>
            )}
            {subscriptionPrompt && (
              <div
                className={`subscription-box ${showMessage ? "active" : ""}`}
              >
                <button
                  className="close-subscription-btn"
                  onClick={closeMessage}
                >
                  &times;
                </button>
                User already has a subscription!
              </div>
            )}
            {subscriptions.map((subscription) => (
              <div
                className="aai-price-table mb-4"
                data-aos="fade-up"
                data-aos-delay="100"
                key={subscription._id}
              >
                <div className="row g-4 align-items-center justify-content-between">
                  <div className="col-xl-4 col-lg-6">
                    <div className="aai-price-pack">
                      <h3 className="aai-price-pack-name">
                        {subscription.plan_name}
                      </h3>
                      <p className="aai-price-pack-desc">
                        {subscription.plan_description}
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <ul className="aai-price-lists list-unstyled">
                      {/* <li className="aai-price-list-item d-flex align-items-center"> */}
                      <li
                        className="aai-price-list-item d-flex align-items-center"
                        style={{
                          borderBottom: "1px solid grey",
                          paddingBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            background: "transparent",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            border: "1px solid black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexShrink: 0, // Prevents stretching
                            flex: "0 0 auto", // Maintains fixed size
                          }}
                        >
                          <p style={{ color: "black", margin: 0 }}>✔</p>
                        </span>

                        <span className="aai-price-list-item-text" style={{ marginLeft: "8px" }}>
                        All Pro models: OpenAI - Anthropic - Stability AI - Google  
                         Mistral - Dall-E - Cohere - Ideogram - Beatoven - Haiper
                        </span>
                      </li>

                      <li
                        className="aai-price-list-item d-flex align-items-center"
                        style={{
                          borderBottom: "1px solid grey",
                          paddingBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            background: "transparent",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            border: "1px solid black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexShrink: 0, // Prevents stretching
                            flex: "0 0 auto", // Maintains fixed size
                          }}
                        >
                          <p style={{ color: "black", margin: 0 }}>✔</p>
                        </span>

                        <span className="aai-price-list-item-text" style={{ marginLeft: "8px" }}>
                        Generate: Image-Audio-Video-Document
                        </span>
                      </li>

                      <li
                        className="aai-price-list-item d-flex align-items-center"
                        style={{
                          borderBottom: "1px solid grey",
                          paddingBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            background: "transparent",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            border: "1px solid black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexShrink: 0, // Prevents stretching
                            flex: "0 0 auto", // Maintains fixed size
                          }}
                        >
                          <p style={{ color: "black", margin: 0 }}>✔</p>
                        </span>

                        <span className="aai-price-list-item-text" style={{ marginLeft: "8px" }}>
                        Access OneAIChat’s Focused-Categories
                        </span>
                      </li>

                      <li
                        className="aai-price-list-item d-flex align-items-center"
                        style={{
                          borderBottom: "1px solid grey",
                          paddingBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            background: "transparent",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            border: "1px solid black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexShrink: 0, // Prevents stretching
                            flex: "0 0 auto", // Maintains fixed size
                          }}
                        >
                          <p style={{ color: "black", margin: 0 }}>✔</p>
                        </span>

                        <span className="aai-price-list-item-text" style={{ marginLeft: "8px" }}>
                          Zero limit on prompts.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="aai-price-value">
                      <h2 className="aai-price">
                        {console.log("User Country:", userCountry)}
                        {userCountry === "AF" &&
                          `${subscription.plan_price_af}`}{" "}
                        {userCountry === "AL" &&
                          `${subscription.plan_price_al}`}{" "}
                        {userCountry === "DZ" &&
                          `${subscription.plan_price_dz}`}{" "}
                        {userCountry === "AD" &&
                          `${subscription.plan_price_ad}`}{" "}
                        {userCountry === "AO" &&
                          `${subscription.plan_price_ao}`}{" "}
                        {userCountry === "AI" &&
                          `${subscription.plan_price_ai}`}{" "}
                        {userCountry === "AQ" &&
                          `${subscription.plan_price_aq}`}{" "}
                        {userCountry === "AG" &&
                          `${subscription.plan_price_ag}`}{" "}
                        {userCountry === "AR" &&
                          `${subscription.plan_price_ar}`}{" "}
                        {userCountry === "AM" &&
                          `${subscription.plan_price_am}`}{" "}
                        {userCountry === "AU" &&
                          `${subscription.plan_price_au}`}{" "}
                        {userCountry === "AT" &&
                          `${subscription.plan_price_at}`}{" "}
                        {userCountry === "AZ" &&
                          `${subscription.plan_price_az}`}{" "}
                        {userCountry === "BS" &&
                          `${subscription.plan_price_bs}`}{" "}
                        {userCountry === "BH" &&
                          `${subscription.plan_price_bh}`}{" "}
                        {userCountry === "BD" &&
                          `${subscription.plan_price_bd}`}{" "}
                        {userCountry === "BB" &&
                          `${subscription.plan_price_bb}`}{" "}
                        {userCountry === "BY" &&
                          `${subscription.plan_price_by}`}{" "}
                        {userCountry === "BE" &&
                          `${subscription.plan_price_be}`}{" "}
                        {userCountry === "BZ" &&
                          `${subscription.plan_price_bz}`}{" "}
                        {userCountry === "BJ" &&
                          `${subscription.plan_price_bj}`}{" "}
                        {userCountry === "BM" &&
                          `${subscription.plan_price_bm}`}{" "}
                        {userCountry === "BT" &&
                          `${subscription.plan_price_bt}`}{" "}
                        {userCountry === "BO" &&
                          `${subscription.plan_price_bo}`}{" "}
                        {userCountry === "BA" &&
                          `${subscription.plan_price_ba}`}{" "}
                        {userCountry === "BW" &&
                          `${subscription.plan_price_bw}`}{" "}
                        {userCountry === "BR" &&
                          `${subscription.plan_price_br}`}{" "}
                        {userCountry === "BN" &&
                          `${subscription.plan_price_bn}`}{" "}
                        {userCountry === "BG" &&
                          `${subscription.plan_price_bg}`}{" "}
                        {userCountry === "BF" &&
                          `${subscription.plan_price_bf}`}{" "}
                        {userCountry === "BI" &&
                          `${subscription.plan_price_bi}`}{" "}
                        {userCountry === "KH" &&
                          `${subscription.plan_price_kh}`}{" "}
                        {userCountry === "CM" &&
                          `${subscription.plan_price_cm}`}{" "}
                        {userCountry === "CA" &&
                          `${subscription.plan_price_ca}`}{" "}
                        {userCountry === "CV" &&
                          `${subscription.plan_price_cv}`}{" "}
                        {userCountry === "CH" &&
                          `${subscription.plan_price_ch}`}{" "}
                        {userCountry === "KY" &&
                          `${subscription.plan_price_ky}`}{" "}
                        {userCountry === "CF" &&
                          `${subscription.plan_price_cf}`}{" "}
                        {userCountry === "TD" &&
                          `${subscription.plan_price_td}`}{" "}
                        {userCountry === "CL" &&
                          `${subscription.plan_price_cl}`}{" "}
                        {userCountry === "CN" &&
                          `${subscription.plan_price_cn}`}{" "}
                        {userCountry === "CO" &&
                          `${subscription.plan_price_co}`}{" "}
                        {userCountry === "KM" &&
                          `${subscription.plan_price_km}`}{" "}
                        {userCountry === "CG" &&
                          `${subscription.plan_price_cg}`}{" "}
                        {userCountry === "CR" &&
                          `${subscription.plan_price_cr}`}{" "}
                        {userCountry === "CI" &&
                          `${subscription.plan_price_ci}`}{" "}
                        {userCountry === "HR" &&
                          `${subscription.plan_price_hr}`}{" "}
                        {userCountry === "CY" &&
                          `${subscription.plan_price_cy}`}{" "}
                        {userCountry === "CZ" &&
                          `${subscription.plan_price_cz}`}{" "}
                        {userCountry === "DK" &&
                          `${subscription.plan_price_dk}`}{" "}
                        {userCountry === "DJ" &&
                          `${subscription.plan_price_dj}`}{" "}
                        {userCountry === "DM" &&
                          `${subscription.plan_price_dm}`}{" "}
                        {userCountry === "DO" &&
                          `${subscription.plan_price_do}`}{" "}
                        {userCountry === "EC" &&
                          `${subscription.plan_price_ec}`}{" "}
                        {userCountry === "EG" &&
                          `${subscription.plan_price_eg}`}{" "}
                        {userCountry === "SV" &&
                          `${subscription.plan_price_sv}`}{" "}
                        {userCountry === "GQ" &&
                          `${subscription.plan_price_gq}`}{" "}
                        {userCountry === "ER" &&
                          `${subscription.plan_price_er}`}{" "}
                        {userCountry === "EE" &&
                          `${subscription.plan_price_ee}`}{" "}
                        {userCountry === "SZ" &&
                          `${subscription.plan_price_sz}`}{" "}
                        {userCountry === "ET" &&
                          `${subscription.plan_price_et}`}{" "}
                        {userCountry === "FJ" &&
                          `${subscription.plan_price_fj}`}{" "}
                        {userCountry === "FI" &&
                          `${subscription.plan_price_fi}`}{" "}
                        {userCountry === "FR" &&
                          `${subscription.plan_price_fr}`}{" "}
                        {userCountry === "GA" &&
                          `${subscription.plan_price_ga}`}{" "}
                        {userCountry === "GM" &&
                          `${subscription.plan_price_gm}`}{" "}
                        {userCountry === "GE" &&
                          `${subscription.plan_price_ge}`}{" "}
                        {userCountry === "DE" &&
                          `${subscription.plan_price_de}`}{" "}
                        {userCountry === "GH" &&
                          `${subscription.plan_price_gh}`}{" "}
                        {userCountry === "GR" &&
                          `${subscription.plan_price_gr}`}{" "}
                        {userCountry === "GD" &&
                          `${subscription.plan_price_gd}`}{" "}
                        {userCountry === "GU" &&
                          `${subscription.plan_price_gu}`}{" "}
                        {userCountry === "GT" &&
                          `${subscription.plan_price_gt}`}{" "}
                        {userCountry === "GN" &&
                          `${subscription.plan_price_gn}`}{" "}
                        {userCountry === "GW" &&
                          `${subscription.plan_price_gw}`}{" "}
                        {userCountry === "GY" &&
                          `${subscription.plan_price_gy}`}{" "}
                        {userCountry === "HT" &&
                          `${subscription.plan_price_ht}`}{" "}
                        {userCountry === "HN" &&
                          `${subscription.plan_price_hn}`}{" "}
                        {userCountry === "HU" &&
                          `${subscription.plan_price_hu}`}{" "}
                        {userCountry === "HK" &&
                          `${subscription.plan_price_hk}`}{" "}
                        {userCountry === "IS" &&
                          `${subscription.plan_price_is}`}{" "}
                        {userCountry === "ID" &&
                          `${subscription.plan_price_id}`}{" "}
                        {userCountry === "IQ" &&
                          `${subscription.plan_price_iq}`}{" "}
                        {userCountry === "IE" &&
                          `${subscription.plan_price_ie}`}{" "}
                        {userCountry === "IL" &&
                          `${subscription.plan_price_il}`}{" "}
                        {userCountry === "IT" &&
                          `${subscription.plan_price_it}`}{" "}
                        {userCountry === "JM" &&
                          `${subscription.plan_price_jm}`}{" "}
                        {userCountry === "JP" &&
                          `${subscription.plan_price_jp}`}{" "}
                        {userCountry === "JO" &&
                          `${subscription.plan_price_jo}`}{" "}
                        {userCountry === "KZ" &&
                          `${subscription.plan_price_kz}`}{" "}
                        {userCountry === "KE" &&
                          `${subscription.plan_price_ke}`}{" "}
                        {userCountry === "KI" &&
                          `${subscription.plan_price_ki}`}{" "}
                        {userCountry === "KR" &&
                          `${subscription.plan_price_kr}`}{" "}
                        {userCountry === "KW" &&
                          `${subscription.plan_price_kw}`}{" "}
                        {userCountry === "KG" &&
                          `${subscription.plan_price_kg}`}{" "}
                        {userCountry === "LA" &&
                          `${subscription.plan_price_la}`}{" "}
                        {userCountry === "LV" &&
                          `${subscription.plan_price_lv}`}{" "}
                        {userCountry === "LB" &&
                          `${subscription.plan_price_lb}`}{" "}
                        {userCountry === "LS" &&
                          `${subscription.plan_price_ls}`}{" "}
                        {userCountry === "LR" &&
                          `${subscription.plan_price_lr}`}{" "}
                        {userCountry === "LY" &&
                          `${subscription.plan_price_ly}`}{" "}
                        {userCountry === "LI" &&
                          `${subscription.plan_price_li}`}{" "}
                        {userCountry === "LT" &&
                          `${subscription.plan_price_lt}`}{" "}
                        {userCountry === "LU" &&
                          `${subscription.plan_price_lu}`}{" "}
                        {userCountry === "MG" &&
                          `${subscription.plan_price_mg}`}{" "}
                        {userCountry === "MW" &&
                          `${subscription.plan_price_mw}`}{" "}
                        {userCountry === "MY" &&
                          `${subscription.plan_price_my}`}{" "}
                        {userCountry === "MV" &&
                          `${subscription.plan_price_mv}`}{" "}
                        {userCountry === "ML" &&
                          `${subscription.plan_price_ml}`}{" "}
                        {userCountry === "MT" &&
                          `${subscription.plan_price_mt}`}{" "}
                        {userCountry === "MR" &&
                          `${subscription.plan_price_mr}`}{" "}
                        {userCountry === "MU" &&
                          `${subscription.plan_price_mu}`}{" "}
                        {userCountry === "MK" &&
                          `${subscription.plan_price_mk}`}{" "}
                        {userCountry === "YT" &&
                          `${subscription.plan_price_yt}`}{" "}
                        {userCountry === "MX" &&
                          `${subscription.plan_price_mx}`}{" "}
                        {userCountry === "MD" &&
                          `${subscription.plan_price_md}`}{" "}
                        {userCountry === "MC" &&
                          `${subscription.plan_price_mc}`}{" "}
                        {userCountry === "MN" &&
                          `${subscription.plan_price_mn}`}{" "}
                        {userCountry === "ME" &&
                          `${subscription.plan_price_me}`}{" "}
                        {userCountry === "MS" &&
                          `${subscription.plan_price_ms}`}{" "}
                        {userCountry === "MA" &&
                          `${subscription.plan_price_ma}`}{" "}
                        {userCountry === "MZ" &&
                          `${subscription.plan_price_mz}`}{" "}
                        {userCountry === "MM" &&
                          `${subscription.plan_price_mm}`}{" "}
                        {userCountry === "NA" &&
                          `${subscription.plan_price_na}`}{" "}
                        {userCountry === "NR" &&
                          `${subscription.plan_price_nr}`}{" "}
                        {userCountry === "NP" &&
                          `${subscription.plan_price_np}`}{" "}
                        {userCountry === "NL" &&
                          `${subscription.plan_price_nl}`}{" "}
                        {userCountry === "NC" &&
                          `${subscription.plan_price_nc}`}{" "}
                        {userCountry === "NZ" &&
                          `${subscription.plan_price_nz}`}{" "}
                        {userCountry === "NI" &&
                          `${subscription.plan_price_ni}`}{" "}
                        {userCountry === "NE" &&
                          `${subscription.plan_price_ne}`}{" "}
                        {userCountry === "NG" &&
                          `${subscription.plan_price_ng}`}{" "}
                        {userCountry === "NO" &&
                          `${subscription.plan_price_no}`}{" "}
                        {userCountry === "OM" &&
                          `${subscription.plan_price_om}`}{" "}
                        {userCountry === "PK" &&
                          `${subscription.plan_price_pk}`}{" "}
                        {userCountry === "PW" &&
                          `${subscription.plan_price_pw}`}{" "}
                        {userCountry === "PA" &&
                          `${subscription.plan_price_pa}`}{" "}
                        {userCountry === "PG" &&
                          `${subscription.plan_price_pg}`}{" "}
                        {userCountry === "PY" &&
                          `${subscription.plan_price_py}`}{" "}
                        {userCountry === "PE" &&
                          `${subscription.plan_price_pe}`}{" "}
                        {userCountry === "PH" &&
                          `${subscription.plan_price_ph}`}{" "}
                        {userCountry === "PL" &&
                          `${subscription.plan_price_pl}`}{" "}
                        {userCountry === "PT" &&
                          `${subscription.plan_price_pt}`}{" "}
                        {userCountry === "PR" &&
                          `${subscription.plan_price_pr}`}{" "}
                        {userCountry === "QA" &&
                          `${subscription.plan_price_qa}`}{" "}
                        {userCountry === "RE" &&
                          `${subscription.plan_price_re}`}{" "}
                        {userCountry === "RO" &&
                          `${subscription.plan_price_ro}`}{" "}
                        {userCountry === "RU" &&
                          `${subscription.plan_price_ru}`}{" "}
                        {userCountry === "RW" &&
                          `${subscription.plan_price_rw}`}{" "}
                        {userCountry === "ST" &&
                          `${subscription.plan_price_st}`}{" "}
                        {userCountry === "SA" &&
                          `${subscription.plan_price_sa}`}{" "}
                        {userCountry === "SN" &&
                          `${subscription.plan_price_sn}`}{" "}
                        {userCountry === "RS" &&
                          `${subscription.plan_price_rs}`}{" "}
                        {userCountry === "SC" &&
                          `${subscription.plan_price_sc}`}{" "}
                        {userCountry === "SL" &&
                          `${subscription.plan_price_sl}`}{" "}
                        {userCountry === "SG" &&
                          `${subscription.plan_price_sg}`}{" "}
                        {userCountry === "SK" &&
                          `${subscription.plan_price_sk}`}{" "}
                        {userCountry === "SI" &&
                          `${subscription.plan_price_si}`}{" "}
                        {userCountry === "SO" &&
                          `${subscription.plan_price_so}`}{" "}
                        {userCountry === "ZA" &&
                          `${subscription.plan_price_za}`}{" "}
                        {userCountry === "SS" &&
                          `${subscription.plan_price_ss}`}{" "}
                        {userCountry === "ES" &&
                          `${subscription.plan_price_es}`}{" "}
                        {userCountry === "LK" &&
                          `${subscription.plan_price_lk}`}{" "}
                        {userCountry === "SD" &&
                          `${subscription.plan_price_sd}`}{" "}
                        {userCountry === "SR" &&
                          `${subscription.plan_price_sr}`}{" "}
                        {userCountry === "SJ" &&
                          `${subscription.plan_price_sj}`}{" "}
                        {userCountry === "SE" &&
                          `${subscription.plan_price_se}`}{" "}
                        {userCountry === "TW" &&
                          `${subscription.plan_price_tw}`}{" "}
                        {userCountry === "TJ" &&
                          `${subscription.plan_price_tj}`}{" "}
                        {userCountry === "TZ" &&
                          `${subscription.plan_price_tz}`}{" "}
                        {userCountry === "TH" &&
                          `${subscription.plan_price_th}`}{" "}
                        {userCountry === "TL" &&
                          `${subscription.plan_price_tl}`}{" "}
                        {userCountry === "TG" &&
                          `${subscription.plan_price_tg}`}{" "}
                        {userCountry === "TO" &&
                          `${subscription.plan_price_to}`}{" "}
                        {userCountry === "TT" &&
                          `${subscription.plan_price_tt}`}{" "}
                        {userCountry === "TN" &&
                          `${subscription.plan_price_tn}`}{" "}
                        {userCountry === "TR" &&
                          `${subscription.plan_price_tr}`}{" "}
                        {userCountry === "TM" &&
                          `${subscription.plan_price_tm}`}{" "}
                        {userCountry === "TC" &&
                          `${subscription.plan_price_tc}`}{" "}
                        {userCountry === "TV" &&
                          `${subscription.plan_price_tv}`}{" "}
                        {userCountry === "UG" &&
                          `${subscription.plan_price_ug}`}{" "}
                        {userCountry === "UA" &&
                          `${subscription.plan_price_ua}`}{" "}
                        {userCountry === "AE" &&
                          `${subscription.plan_price_ae}`}{" "}
                        {userCountry === "GB" &&
                          `${
                            subscription.plan_price_gb ||
                            subscription.plan_price_uk
                          }`}{" "}
                        {userCountry === "US" &&
                          `${subscription.plan_price_us}`}{" "}
                        {userCountry === "UY" &&
                          `${subscription.plan_price_uy}`}{" "}
                        {userCountry === "UZ" &&
                          `${subscription.plan_price_uz}`}{" "}
                        {userCountry === "VU" &&
                          `${subscription.plan_price_vu}`}{" "}
                        {userCountry === "VE" &&
                          `${subscription.plan_price_ve}`}{" "}
                        {userCountry === "VN" &&
                          `${subscription.plan_price_vn}`}{" "}
                        {userCountry === "WF" &&
                          `${subscription.plan_price_wf}`}{" "}
                        {userCountry === "EH" &&
                          `${subscription.plan_price_eh}`}{" "}
                        {userCountry === "YE" &&
                          `${subscription.plan_price_ye}`}{" "}
                        {userCountry === "ZM" &&
                          `${subscription.plan_price_zm}`}{" "}
                        {userCountry === "ZW" &&
                          `${subscription.plan_price_zw}`}{" "}
                        {/* from here the the extra countries are added  */}
                        {userCountry === "AX" &&
                          `${subscription.plan_price_ax}`}{" "}
                        {userCountry === "AW" &&
                          `${subscription.plan_price_aw}`}{" "}
                        {userCountry === "AC" &&
                          `${subscription.plan_price_ac}`}{" "}
                        {userCountry === "BV" &&
                          `${subscription.plan_price_bv}`}{" "}
                        {userCountry === "IO" &&
                          `${subscription.plan_price_io}`}{" "}
                        {userCountry === "VG" &&
                          `${subscription.plan_price_vg}`}{" "}
                        {userCountry === "BQ" &&
                          `${subscription.plan_price_bq}`}{" "}
                        {userCountry === "CD" &&
                          `${subscription.plan_price_cd}`}{" "}
                        {userCountry === "CK" &&
                          `${subscription.plan_price_ck}`}{" "}
                        {userCountry === "CW" &&
                          `${subscription.plan_price_cw}`}{" "}
                        {userCountry === "FK" &&
                          `${subscription.plan_price_fk}`}{" "}
                        {userCountry === "FO" &&
                          `${subscription.plan_price_fo}`}{" "}
                        {userCountry === "GF" &&
                          `${subscription.plan_price_gf}`}{" "}
                        {userCountry === "PF" &&
                          `${subscription.plan_price_pf}`}{" "}
                        {userCountry === "TF" &&
                          `${subscription.plan_price_tf}`}{" "}
                        {userCountry === "GI" &&
                          `${subscription.plan_price_gi}`}{" "}
                        {userCountry === "GL" &&
                          `${subscription.plan_price_gl}`}{" "}
                        {userCountry === "GP" &&
                          `${subscription.plan_price_gp}`}{" "}
                        {userCountry === "GG" &&
                          `${subscription.plan_price_gg}`}{" "}
                        {userCountry === "IM" &&
                          `${subscription.plan_price_im}`}{" "}
                        {userCountry === "JE" &&
                          `${subscription.plan_price_je}`}{" "}
                        {userCountry === "XK" &&
                          `${subscription.plan_price_xk}`}{" "}
                        {userCountry === "MO" &&
                          `${subscription.plan_price_mo}`}{" "}
                        {userCountry === "MQ" &&
                          `${subscription.plan_price_mq}`}{" "}
                        {userCountry === "NU" &&
                          `${subscription.plan_price_nu}`}{" "}
                        {userCountry === "PS" &&
                          `${subscription.plan_price_ps}`}{" "}
                        {userCountry === "PN" &&
                          `${subscription.plan_price_pn}`}{" "}
                        {userCountry === "WS" &&
                          `${subscription.plan_price_ws}`}{" "}
                        {userCountry === "SM" &&
                          `${subscription.plan_price_sm}`}{" "}
                        {userCountry === "SX" &&
                          `${subscription.plan_price_sx}`}{" "}
                        {userCountry === "SB" &&
                          `${subscription.plan_price_sb}`}{" "}
                        {userCountry === "GS" &&
                          `${subscription.plan_price_gs}`}{" "}
                        {userCountry === "BL" &&
                          `${subscription.plan_price_bl}`}{" "}
                        {userCountry === "SH" &&
                          `${subscription.plan_price_sh}`}{" "}
                        {userCountry === "KN" &&
                          `${subscription.plan_price_kn}`}{" "}
                        {userCountry === "LC" &&
                          `${subscription.plan_price_lc}`}{" "}
                        {userCountry === "MF" &&
                          `${subscription.plan_price_mf}`}{" "}
                        {userCountry === "PM" &&
                          `${subscription.plan_price_pm}`}{" "}
                        {userCountry === "VC" &&
                          `${subscription.plan_price_vc}`}{" "}
                        {userCountry === "TK" &&
                          `${subscription.plan_price_tk}`}{" "}
                        {userCountry === "TA" &&
                          `${subscription.plan_price_ta}`}{" "}
                        {userCountry === "VA" &&
                          `${subscription.plan_price_va}`}{" "}
                        {userCountry === "IN" && `₹${subscription.plan_price}`}{" "}
                      </h2>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-6">
                    <div>
                      <input
                        type="hidden"
                        name="stripeId"
                        value={
                          subscription[
                            `stripe_id_${
                              userCountry ? userCountry.toLowerCase() : ""
                            }`
                          ]
                        }
                      />
                      <button
                        className="aai-btn btn-pill-solid"
                        id="submit"
                        role="link"
                        onClick={(e) =>
                          handlePayClick(
                            e,
                            subscription.plan_id,
                            subscription.plan_name,
                            subscription.plan_price,
                            subscription
                          )
                        }
                      >
                        PAY
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* Popup for payment options */}
            {showPopup && (
              <div className="popup">
                <h2>Select Payment Option</h2>
                <br />
                <button
                  className="popup-close"
                  onClick={closeMessage}
                  style={{
                    fontSize: "larger",
                    // marginTop:"-150px",
                    marginLeft: "355px",
                    position: "fixed",
                    color: "black",
                  }}
                >
                  X
                </button>
                <button
                  onClick={() => handlePaymentOptionClick("PhonePe")}
                  className={
                    selectedPaymentOption === "PhonePe" ? "selected" : ""
                  }
                >
                  PhonePe
                </button>
                {/* <button
            onClick={() => handlePaymentOptionClick("BillDesk")}
            className={selectedPaymentOption === "BillDesk" ? "selected" : ""}
          >
            BillDesk
          </button> */}
                <button onClick={openModal}>BillDesk</button>
                <button
                  onClick={() => handlePaymentOptionClick("Stripe")}
                  className={
                    selectedPaymentOption === "Stripe" ? "selected" : ""
                  }
                >
                  Stripe
                </button>
                <br />
                <br />
                <br />

                <center>
                  <button onClick={handleProceed}>Proceed</button>
                </center>
              </div>
            )}

            {/* this code is for the modal for billdesk options  */}
            {isModalOpen && (
              <div
                className="modal fade show"
                tabIndex="-1"
                style={{ display: "block" }}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                  style={{ height: "80vh" }}
                >
                  <div className="modal-content" style={{ height: "100%" }}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Payment Options
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={closeModal}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div
                      className="modal-body"
                      style={{
                        maxHeight: "calc(80vh - 56px - 50px)", // Adjusted to account for the footer height
                        overflowY: "auto",
                      }}
                    >
                      <div className="d-flex flex-column align-items-center">
                        <div className="text-center mb-4">
                          <button
                            className={`btn mb-2 ${
                              selectedPaymentOption === "One-Time Payment"
                                ? "btn-success"
                                : "btn-primary"
                            }`}
                            onClick={() =>
                              handlePaymentOptionClick("One-Time Payment")
                            }
                          >
                            One-Time Payment
                          </button>
                          <p className="text-muted">
                            Use this option to make a single payment for your
                            purchase. This is ideal for one-off transactions
                            where you do not wish to commit to recurring
                            payments.
                          </p>
                        </div>
                        <div className="text-center mb-4">
                          <button
                            className={`btn mb-2 ${
                              selectedPaymentOption === "Recurring Payment"
                                ? "btn-success"
                                : "btn-primary"
                            }`}
                            onClick={() =>
                              handlePaymentOptionClick("Recurring Payment")
                            }
                          >
                            Recurring Payment
                          </button>
                          <p className="text-muted">
                            Choose this option if you prefer a
                            subscription-based model. Your account will be
                            automatically charged on a regular basis (e.g.,
                            monthly or yearly) for continued access to the
                            service.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Footer with Proceed button */}
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleProceed}
                        disabled={!selectedPaymentOption} // Disable if no option is selected
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* till here the modal for billdesk end */}
            {/* <!-- Single Price Table --> */}

            {/* <!-- Single Price Table --> */}
          </div>
        </section>
        {/* <!-- Pricing Table End --> */}

        {/* Cta Start */}
        <section
          className="aai-cta pb-120"

          // style={{
          //   background: "url('assets/img/bg/cta-bg.jpeg') no-repeat center center/cover"
          // }}
        >
          <div
            className="container"
            style={{ border: "1px solid #28c434", borderRadius: "25px" }}
          >
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg">
                <div className="secttionn">
                  <h2 className="section-title mb-4">
                    The Future Isn't What It Used To Be!&nbsp;
                    <span className="position-relative"></span>
                  </h2>

                  <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                    <a href="/pricing" className="aai-btn btn-pill-solid">
                      Get Started
                    </a>
                    <a href="/about" className="aai-gradient-outline-btn">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-7 col-lg mt-5 mt-xl-0"
                data-aos="fade-up"
                data-aos-delay="80"
              >
                <div className="aai-cta-img">
                  <img
                    src="assets/img/logo/2.jpeg"
                    className="img-fluuid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Cta End */}
      </main>
      <Footer />
    </div>
  );
};

export default Home;
