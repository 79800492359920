import React, { useState, useEffect, useRef } from "react";
import Rooms from "./components/Rooms";
import {
  toolList,
  aiList as importedAiList,
  healthAiList,
  marketingAiList,
  videoAiList,
  scienceAiList,
  writingAiList,
  designAiList,
  codingAiList,
  musicAiList,
  mathAiList,
  faithAiList,
  financeAiList,
} from "data/ailist";
import ChatTextbox from "pages/Chat/components/ChatTextbox";
import useRoomCountOfAi from "hooks/useRoomCountOfAi";
import styles from "./Chat.module.css";
import Link from "components/Link";
import PaymentSuccess from "../PaymentSucess";
import Setting from "pages/Setting"; // Assuming you've exported your component.
import useChatRoomByUser from "hooks/useChatRoomByUser";
import { axiosInstance } from "config/axios";
import Chatheader from "components/Header/ChatHeader/Chatheader";
import Profile from "pages/Profile";
import { Collapse } from "react-bootstrap"; // Import Bootstrap Dropdown
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const ChatPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("Somebody");
  const location = useLocation();
  const [setNewCategory, setCategory] = useState("Somebody");
  const [isAiCategory, setAiCategory] = useState("OneAIChat-Somebody");
  const [aiList, setAiList] = useState(importedAiList); // Default to the full AI list
  const [chats, setChats] = useState([]);
  const [showRoombar, setShowRoombar] = useState(false);
  const [type, setType] = useState("Somebody");
  const [aiName, setAIName] = useState("OneAIChat-Somebody");
  const [selectedChatbot, setSelectedChatbot] = useState("");
  const isLoggedIn = localStorage.getItem("loggedIn");
  const [subscriptions, setSubscriptions] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [searchAiList, setSearchAiList] = useState([]);

  // billdesk from
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isStopRecurringButton, setIsRecurringButton] = useState("");

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // the below use effect is to desplay the stop recurring button

  useEffect(() => {
    const authUserString = localStorage.getItem("authUser");
    const authUser = authUserString ? JSON.parse(authUserString) : null;

    if (authUser) {
      const userEmail = authUser.email;
      const userid = authUser._id;

      console.log("this is the userEmail ", userEmail);
      console.log("this is teh user id ", userid);

      fetch("https://db.oneaichat.com/api/get-mandate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          userId: userid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data); // Log the success response
          setIsRecurringButton(data.data);
          if (data.success) {
            console.log("Mandate Status:", data.data); // Log the specific mandate status
          } else {
            console.log("Error Message:", data.message); // Log the error message
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []); // Empty dependency array means this effect runs once on mount

  // the above use effect is to desplay the stop recurring button

  //billdesk till

  useEffect(() => {
    axiosInstance
      .get(`/subscriptions`)
      .then((response) => {
        console.log("Response data:", response.data);
        if (response.data.status) {
          setSubscriptions(response.data.subscriptions);
        } else {
          console.error("Failed to fetch subscriptions");
        }
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await checkUserSubscription();
        checkFreeChatCount();

        if (!isLoggedIn) {
          navigate("/login");
        }

        // Check if there is state in the location object
        if (location.state && location.state.selectedCategory) {
          setSelectedCategory(location.state.selectedCategory);
        } else if (location.state && location.state.roomCategory) {
          setSelectedCategory(location.state.roomCategory);
        } else {
          setSelectedCategory(setNewCategory);
        }
      } catch (error) {
        console.error("Error in useEffect:", error);
        // Handle errors as needed
      }
    };

    fetchData(); // Call the asynchronous function

    // No dependencies needed for this useEffect
  }, [location.state]); // Empty dependency array to run the effect only once on mount
  useEffect(() => {
    // Get values from LocalStorage
    const storedCategory = localStorage.getItem("selectedCategory");
    const storedAiCategory = localStorage.getItem("selectedAiCategory");

    console.log("Stored Category:", storedCategory);
    console.log("Stored AI Category:", storedAiCategory);

    // Set the state based on LocalStorage values
    setSelectedCategory(storedCategory || "Somebody");
    setAIName(storedAiCategory || "OneAIChat-Somebody");
    console.log("Selected Category:", selectedCategory);
    console.log("AI Category:", isAiCategory);
  }, []); // Trigger effect only on component mount

  const typeHandler = (name) => {
    setType(name);
  };
  const [subscriptionPrompt, setSubscriptionPrompt] = useState(false);
  const navigate = useNavigate();
  const [rotate, setRotate] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isCatPopupVisible, setCatPopupVisible] = useState(false);
  const [isAichatPopupVisible, setAiChatPopupVisible] = useState(false);
  const [isNewRoomStatus, setNewRoomStatus] = useState(false);

  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showChatTextbox, setShowChatTextbox] = useState(true);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isSubscribed, setSubscribed] = useState(false);
  console.log(isSubscribed, "isSubscribed-status");
  const [chatCounts, setChatCounts] = useState({
    freeChat: null,
    imageCount: null,
    videoCount: null,
    audioCount: null,
    textCount: null,
    docCount: null,
  });
  {
    /* Dropdown setting start */
  }
  const [isDroppdownVisible, setDroppdownVisible] = useState(false);
  const [selectedDropdownItems, setSelectedDropdownItems] = useState([]);
  const [selectedAICategory, setSelectedAICategory] = useState(null);

  const handleToggleDropdown = (aiTitle) => {
    setSelectedAICategory((prev) => (prev === aiTitle ? null : aiTitle));
    setDroppdownVisible((prev) => !prev);
  };

  // const handleToggleItemSelection = (aiTitle) => {
  //   setSelectedDropdownItems((prevItems) => {
  //     if (prevItems.includes(aiTitle)) {
  //       return prevItems.filter((item) => item !== aiTitle);
  //     } else {
  //       return [...prevItems, aiTitle];
  //     }
  //   });
  // };

  const getOtherAiList = (aiCategory) => {
    const aiItem = aiList.find((ai) => ai.title === aiCategory);
    return aiItem
      ? (aiItem.otherAiList || []).sort(
          (a, b) => new Date(b.launchedAt) - new Date(a.launchedAt)
        )
      : [];
  };

  {
    /* Dropdown setting End */
  }
  const checkFreeChatCount = async () => {
    const userFreeChat = await axiosInstance.post(`/freechatcount`);
    //console.log(userFreeChat?.data,"freechat---------------");
    setChatCounts({
      freeChat: userFreeChat?.data?.chatcount,
      imageCount: userFreeChat?.data?.imagecount,
      videoCount: userFreeChat?.data?.videocount,
      audioCount: userFreeChat?.data?.audiocount,
      textCount: userFreeChat?.data?.textcount,
      docCount: userFreeChat?.data?.doccount,
    });
    setChatCount(userFreeChat?.data?.chatcount);
  };
  const { freeChat, imageCount, videoCount, audioCount } = chatCounts;
  const [isChatCount, setChatCount] = useState();
  const chatbot = aiName;
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const closeMessage = () => {
    setShowMessage(false);
  };

  const closeShowPopup = () => {
    setShowPopup(false);
  };

  const [showMessage, setShowMessage] = useState(false);

  const [isChatCategoryOpen, setChatCategoryOpen] = useState(true);

  const clearChat = () => {
    // Implement the logic to clear the chat
    console.log("Clearing chat...");
    setChats([]);
  };

  const handleNewRoom = (status) => {
    setNewRoomStatus(status);
    if (status != false) {
      setSelectedCategory(setNewCategory);
      setAIName("OneAIChat-Somebody");
      // Load the corresponding AI list based on the selected category
      switch (setNewCategory) {
        case "Somebody":
          setAiList(importedAiList);
          break;
        case "Health":
          setAiList(healthAiList);
          break;
        case "Marketing":
          setAiList(marketingAiList);
          break;
        case "Video":
          setAiList(videoAiList);
          break;
        case "Science":
          setAiList(scienceAiList);
          break;
        case "Writing":
          setAiList(writingAiList);
          break;
        case "Art/Design":
          setAiList(designAiList);
          break;
        case "Coding":
          setAiList(codingAiList);
          break;
        case "Audio/Music":
          setAiList(musicAiList);
          break;
        case "Mathematics":
          setAiList(mathAiList);
          break;
        case "Faith":
          setAiList(faithAiList);
          break;
        case "Finance":
          setAiList(financeAiList);
          break;
        // Add cases for other categories as needed
        default:
          setAiList(aiList);
      }
      localStorage.setItem("selectedCategory", selectedCategory);
      navigate(`/chat`, { replace: true });
    }
    setCatPopupVisible(false);
  };
  const selectRef = useRef(null);
  const handleCategoryClick = (category, status) => {
    setCatPopupVisible(true);
    setCategory(category);
  };

  const [loginPrompt, setLoginPrompt] = useState(false);
  const handlePayClick = async (e, planId, planName, planPrice, stripe_id) => {
    // Check if the user is already subscribed
    const authUser = JSON.parse(localStorage.getItem("authUser"));

    try {
      const subscriptionCheckResponse = await axiosInstance.post(
        "/check-user-subscription",
        { user_id: authUser?._id }
      );

      if (subscriptionCheckResponse?.data?.hasSubscription) {
        setSubscriptionPrompt(true); // Show the subscription prompt message
        setShowMessage(true);

        return;
      }

      // User is not subscribed, proceed with payment
      const isPurchaseCompleted = localStorage.getItem("purchaseCompleted");
      if (!isPurchaseCompleted) {
        localStorage.setItem("stripeId", stripe_id);
        localStorage.setItem("selectedPlanName", planName);
        localStorage.setItem("selectedPlanId", planId);
        localStorage.setItem("selectedPlanPrice", planPrice);
      }

      if (!authUser) {
        console.error("AuthUser not found in local storage");
        setLoginPrompt(true); // Show the login prompt message
        setShowMessage(true);
        e.preventDefault(); // Prevent form submission
        setTimeout(() => {
          window.location.href = "/login"; // Redirect to login page after 3 seconds
        }, 1000);
        return;
      } else {
        // Open the modal when pay button is clicked
        setShowPopup(true);
        setSelectedPaymentOption(null); // Reset selected payment option
      }
    } catch (error) {
      console.error("Error checking user subscription:", error);
      // Handle the error (show a message, log, etc.)
    }
  };

  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handlePaymentOptionClick = (paymentOption) => {
    setSelectedPaymentOption(paymentOption);
  };

  const handleProceed = () => {
    console.log("this is the handle proceed");
    console.log("this is the selected payment options ", selectedPaymentOption);
    if (selectedPaymentOption === "Stripe") {
      handleStripePayment();
    }

    //from
    else if (selectedPaymentOption === "One-Time Payment") {
      handleBilldeskPayment("One-Time Payment");
    } else if (selectedPaymentOption === "Recurring Payment") {
      handleBilldeskPayment("Recurring Payment");
    }
    //till
    else if (selectedPaymentOption === "PhonePe") {
      handlePhonePePayment();
    }
  };

  const handlePhonePePayment = async () => {
    try {
      const selectedPlanId = localStorage.getItem("selectedPlanId");
      const selectedPlanName = localStorage.getItem("selectedPlanName");
      const selectedPlanPrice = localStorage.getItem("selectedPlanPrice");
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const email = authUser.email;
      const userid = authUser._id;
      const url = `https://db.oneaichat.com/pay?planId=${selectedPlanId}&planName=${selectedPlanName}&planPrice=${selectedPlanPrice}&email=${email}&userid=${userid}`;
      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`Error initiating payment: ${response.statusText}`);
      }

      const responseData = await response.json();

      // Check if responseData has the expected structure
      if (responseData && responseData.redirectUrl) {
        window.location.href = responseData.redirectUrl;
      } else {
        console.error("Unexpected response format:", responseData);
        // Handle unexpected response format, show a message, or redirect to an error page
      }
    } catch (error) {
      console.error("Payment initiation error:", error);
      // Handle the error, show a message, or redirect to an error page
    }
  };

  {
    /*Billdesk starts  */
  }
  //   const [amount, setAmount] = useState('');
  //   const [orderId, setOrderId] = useState('');
  //   const [customerName, setCustomerName] = useState('');
  //   const [customerEmail, setCustomerEmail] = useState('');

  //  const handleBilldeskPayment = async () => {
  //   const selectedPlanId = localStorage.getItem('selectedPlanId');
  //   const selectedPlanName = localStorage.getItem('selectedPlanName');
  //   const selectedPlanPrice = localStorage.getItem('selectedPlanPrice');
  //   const authUserString = localStorage.getItem('authUser');

  //   const authUser = authUserString ? JSON.parse(authUserString) : null;

  //   // Access user information from the authUser object
  //   const userEmail = authUser.email;
  //   const userid=authUser._id;
  //  console.log(userid)
  //   try {
  //     const response = await fetch(`https://db.oneaichat.com/api/initiate-payment`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({selectedPlanPrice: parseFloat(selectedPlanPrice), orderId, customerName, customerEmail: userEmail, selectedPlanId: selectedPlanId,  selectedPlanName: selectedPlanName,userid:userid }),
  //     });

  //     const result = await response.json();

  //     const authToken = result.flowConfig.authToken;
  //     const bdOrderId = result.flowConfig.bdOrderId;

  //     const flow_config = {
  //       merchantId: "KALETECHPL",
  //       bdOrderId: bdOrderId,
  //       authToken: authToken,
  //       childWindow: false,
  //       returnUrl: "https://db.oneaichat.com/api/callback",
  //       retryCount: 0
  //     };
  //     var responseHandler = function(txn) {
  //       if (txn.response) {
  //           alert("callback received status:: ", txn.status);
  //           alert("callback received response:: ", txn.response)//response handler to be implemented by the merchant
  //         }
  //         console.log("txn.response"+txn.response)
  //       };
  //     const config = {
  //       responseHandler: responseHandler,

  //       flowConfig: flow_config,
  //       flowType: "payments"
  //     };

  //     window.loadBillDeskSdk(config);

  //     console.log("loadinggg");
  //   } catch (error) {
  //     console.error('Error initiating payment:', error);
  //     // alert('Internal server error. Please try again later.');
  //   }
  // }
  {
    /*Billdesk ends */
  }

  {
    /*Billdesk starts  */
  }
  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");

  const handleBilldeskPayment = async (type) => {
    const selectedPlanId = localStorage.getItem("selectedPlanId");
    const selectedPlanName = localStorage.getItem("selectedPlanName");
    const selectedPlanPrice = localStorage.getItem("selectedPlanPrice");
    const authUserString = localStorage.getItem("authUser");

    const authUser = authUserString ? JSON.parse(authUserString) : null;
    let routeUrl;
    if (type === "One-Time Payment") {
      routeUrl = `https://db.oneaichat.com/api/initiate-payment`;
    }

    if (type === "Recurring Payment") {
      routeUrl = `https://db.oneaichat.com/api/initiate-recurring-payment`;
    }

    if (type === "Stop Recurring Payment") {
      routeUrl = `https://db.oneaichat.com/api/stop-recurring`;
    }
    // Access user information from the authUser object
    const userEmail = authUser.email;
    const userid = authUser._id;
    console.log(userid);

    if (type === "One-Time Payment") {
      try {
        const response = await fetch(routeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPlanPrice: parseFloat(selectedPlanPrice),
            orderId,
            customerName,
            customerEmail: userEmail,
            selectedPlanId: selectedPlanId,
            selectedPlanName: selectedPlanName,
            userid: userid,
          }),
        });

        const result = await response.json();

        const authToken = result.flowConfig.authToken;
        const bdOrderId = result.flowConfig.bdOrderId;
        const MerchantID = result.flowConfig.merchantId;

        const flow_config = {
          merchantId: MerchantID,
          bdOrderId: bdOrderId,
          authToken: authToken,
          childWindow: false,
          returnUrl: "https://db.oneaichat.com/api/callback",
          retryCount: 0,
        };
        var responseHandler = function (txn) {
          if (txn.response) {
            alert("callback received status:: ", txn.status);
            alert("callback received response:: ", txn.response); //response handler to be implemented by the merchant
          }
          console.log("txn.response" + txn.response);
        };
        const config = {
          responseHandler: responseHandler,

          flowConfig: flow_config,
          flowType: "payments",
        };

        window.loadBillDeskSdk(config);

        console.log("loadinggg");
      } catch (error) {
        console.error("Error initiating payment:", error);
        // alert('Internal server error. Please try again later.');
      }
    }

    if (type === "Recurring Payment") {
      try {
        const response = await fetch(routeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPlanPrice: parseFloat(selectedPlanPrice),
            orderId,
            customerName,
            customerEmail: userEmail,
            selectedPlanId: selectedPlanId,
            selectedPlanName: selectedPlanName,
            userid: userid,
          }),
        });

        const result = await response.json();

        console.log("this is the result ");
        console.log(result);

        const authToken = result.flowConfig.authToken;
        const bdOrderId = result.flowConfig.bdOrderId;
        const MerchantID = result.flowConfig.merchantId;

        const flow_config = {
          merchantId: MerchantID,
          bdOrderId: bdOrderId,
          authToken: authToken,
          childWindow: false,
          returnUrl: "https://db.oneaichat.com/api/callback",
          // crossButtonHandling: "Y",
          retryCount: 0,
          prefs: {
            payment_categories: ["card", "nb", "upi"],
            allowed_bins: ["459150", "525211", "540000"],
          },
        };

        var responseHandler = function (txn) {
          if (txn.response) {
            alert("callback received status:: ", txn.status);
            alert("callback received response:: ", txn.response);
          }
        };

        var config = {
          responseHandler: responseHandler,
          flowConfig: flow_config,
          flowType: "payments",
        };

        window.loadBillDeskSdk(config);

        console.log("loadinggg");
      } catch (error) {
        console.error("Error initiating payment:", error);
        // alert('Internal server error. Please try again later.');
      }
    }

    if (type === "Stop Recurring Payment") {
      try {
        const response = await fetch(routeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPlanPrice: parseFloat(selectedPlanPrice),
            orderId,
            customerName,
            customerEmail: userEmail,
            selectedPlanId: selectedPlanId,
            selectedPlanName: selectedPlanName,
            userid: userid,
          }),
        });

        const result = await response.json();

        console.log("this is the result ");
        console.log(result);

        const authToken = result.flowConfig.authToken;
        const mandateTokenId = result.flowConfig.mandateTokenId;
        const MerchantID = result.flowConfig.merchantId;

        var flow_config = {
          merchantId: MerchantID,
          mandateTokenId: mandateTokenId,

          authToken: authToken,
          childWindow: true,
          returnUrl: "https://db.oneaichat.com/api/stop-callback",
          // crossButtonHandling: "Y",
          retryCount: 3,
        };
        console.log("this is the flow consig on front end", flow_config);

        var responseHandler = function (txn) {
          if (txn.response) {
            alert("callback received status:: ", txn.status);
            alert("callback received response:: ", txn.response);
          }
        };

        var config = {
          responseHandler: responseHandler,
          flowConfig: flow_config,
          flowType: "modify_mandate",
        };

        window.loadBillDeskSdk(config);

        console.log("loadinggg");
      } catch (error) {
        console.error("Error initiating payment:", error);
        // alert('Internal server error. Please try again later.');
      }
    }
  };
  {
    /*Billdesk ends */
  }

  const handleStripePayment = () => {
    const stripeId = localStorage.getItem("stripeId");

    const form = document.createElement("form");
    form.action = "http://localhost:4242/create-checkout-session";
    form.method = "POST";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "stripeId";
    input.value = stripeId;

    form.appendChild(input);

    document.body.appendChild(form);

    form.submit();
  };
  // const aiNameHandler = (name, status) => {
  //   setAiChatPopupVisible(status)
  //   setAiCategory(isAiCategory);
  // };
  // // console.log(isAiCategory, "isAiCategory");
  // const handleNewchatBot = (status) => {
  //   if(status !=false){
  //     setAIName(isAiCategory);
  //     setAiChatPopupVisible(false);
  //   }
  //   else{
  //     setAiChatPopupVisible(false);
  //   }
  // }

  const aiNameHandler = (name, status) => {
    console.log("Clicked AI Name:", name);
    console.log("Popup Status:", status);
    setAiChatPopupVisible(status);
    setAiCategory(name);
  };
  const handleNewchatBot = (status) => {
    if (status != false) {
      setAIName(isAiCategory);
      setAiChatPopupVisible(false);
      localStorage.setItem("selectedAiCategory", isAiCategory);

      navigate(`/chat`, { replace: true });
    } else {
      setAiChatPopupVisible(false);
    }
  };

  let currentauthUser = "";
  const authUserString = localStorage.getItem("authUser");
  if (authUserString) {
    currentauthUser = JSON.parse(authUserString);
  }
  const { data: countOfRooms } = useRoomCountOfAi(currentauthUser._id, aiName);
  const checkUserSubscription = async () => {
    const userSubscriptionResponse = await axiosInstance.post(
      `/check-user-subscription`,
      { user_id: currentauthUser._id }
    );
    const userFreeChat = await axiosInstance.post(`/freechatcount`);
    setChatCounts({
      freeChat: userFreeChat?.data?.chatcount,
      imageCount: userFreeChat?.data?.imagecount,
      videoCount: userFreeChat?.data?.videocount,
      audioCount: userFreeChat?.data?.audiocount,
      textCount: userFreeChat?.data?.textcount,
      docCount: userFreeChat?.data?.doccount,
    });
    setChatCount(userFreeChat?.data?.chatcount);
    console.log(userSubscriptionResponse, "subcribed-------------");
    if (
      userSubscriptionResponse?.data?.hasSubscription &&
      userSubscriptionResponse?.data?.content?.payment_status != "pending"
    ) {
      console.log(userSubscriptionResponse, "userSubscriptionResponse");
      console.log(userSubscriptionResponse?.data?.hasSubscription);
      console.log(userSubscriptionResponse?.data?.content?.payment_status);
      console.log(isSubscribed, "Before setting isSubscribed");
      setSubscribed(true);
      console.log(isSubscribed, "After setting isSubscribed");
    }
  };

  const openRoombar = (name) => {
    if (selectedChatbot === name && showRoombar) {
      setShowRoombar(false);
    } else {
      setSelectedChatbot(name);
      setShowRoombar(true);
    }
  };

  const closeRoombar = (name) => {
    setShowRoombar(false);
  };

  const [activeNavItem, setActiveNavItem] = useState(""); // default to 'profile' or null

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isCategoryDropdownVisible, setCategoryDropdownVisible] =
    useState(false);
  const togggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleCategoryDropdown = () => {
    setCategoryDropdownVisible(!isCategoryDropdownVisible);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  const handleBlur = () => {
    setCategoryDropdownVisible(false);
  };

  const toggleRoombar = () => {
    setShowRoombar(!showRoombar);
  };

  const allOtherAiList = [];
  aiList.forEach((ai) => {
    // Add parent object to allOtherAiList
    allOtherAiList.push({
      id: ai.id,
      title: ai.title,
      subTitle: ai.subTitle,
      icon: ai.icon,
    });
    if (Array.isArray(ai.otherAiList)) {
      // Append the items from otherAiList to the allOtherAiList array
      ai.otherAiList.forEach((subAi) => {
        allOtherAiList.push(subAi);
      });
    }
  });
  const searchHandle = () => {
    // console.log("AI List", aiList);
    setSearchAiList([]);
    // console.log("inputSearch:", inputSearch); // Check the value of inputSearch
    // console.log("allother list", allOtherAiList);
    // console.log("hiii");
    // console.log("searchlist", searchAiList);

    const regex = new RegExp(`\\b${inputSearch}\\b`, "i");

    let filteredData = allOtherAiList.filter((res) => {
      let a = regex.test(res.title);
      let b = res.title.toLowerCase().startsWith(inputSearch.toLowerCase());

      if (inputSearch.toLowerCase() === "chat") {
        return res.title.toLowerCase().includes("chat");
      }
      if (inputSearch.toLowerCase() === "cla") {
        return res.title.toLowerCase().includes("cla");
      }
      if (inputSearch.toLowerCase() === "gem") {
        return res.title.toLowerCase().includes("gem");
      }

      if (inputSearch.toLowerCase() === "com") {
        return res.title.toLowerCase().includes("com");
      }
      if (inputSearch.toLowerCase() === "one") {
        return res.title.toLowerCase().includes("one");
      }

      if (inputSearch.toLowerCase() === "goo") {
        return res.title.toLowerCase().includes("goo");
      }

      if (inputSearch.toLowerCase() === "sta") {
        return res.title.toLowerCase().includes("sta");
      }

      if (inputSearch.toLowerCase() === "dal") {
        return res.title.toLowerCase().includes("dal");
      }

      if (b && inputSearch.length === 1) {
        return res.title.toLowerCase().startsWith(inputSearch.toLowerCase());
      } else {
        return regex.test(res.title);
      }
    });

    // console.log("Filtered Data:", filteredData); // Log the filtered data
    setSearchAiList(filteredData);
    // console.log("updated searchlist", searchAiList);
    filteredData = [];
    // console.log("Filter empty", filteredData);
    // Further processing or updating state can be done here
  };

  useEffect(() => {
    if (inputSearch !== "") {
      setSearchAiList([]);
      searchHandle();
    } else {
      setSearchAiList([]);
      // console.log("else searchlist",searchAiList)
    }
  }, [inputSearch]);

  // from here the stretchable sidebar

  const [sidebarWidth, setSidebarWidth] = useState(320); // Initial width
  const handleMouseDown = (e) => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    // Prevent text selection
    document.body.style.userSelect = "none";
  };
  const handleMouseMove = (e) => {
    let newWidth = e.clientX; // Assuming 1rem = 10px for this calculation
    // alert(newWidth)
    if (newWidth < 200) newWidth = 200; // Minimum width
    if (newWidth > 600) newWidth = 600; // Maximum width
    console.log("this is new width", newWidth);
    setSidebarWidth(newWidth);
  };
  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    // Allow text selection again
    document.body.style.userSelect = "auto";
  };

  // till here stretchable sidebar code

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://oneaichat.com/chat" />
      </Helmet>
      <Chatheader />
      {/* Side bar */}

      <div className={styles.wrapper}>
        {/* Chat AI List */}

        {/* from here the stretchable sidebar code  */}

        <div className={styles.tools} style={{ width: `${sidebarWidth}rem` }}>
          <div
            style={{
              width: "20px",
              height: "100%",
              background: "transparent",
              cursor: "ew-resize",
              position: "absolute",
              top: "0%",
              right: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "large",
              borderRight: "5px solid grey",
              display: window.innerWidth <= 768 ? "none" : "block",
            }}
            onMouseDown={handleMouseDown}
          ></div>

          {isStopRecurringButton === "active" && (
            <div class="container my-1">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => handleBilldeskPayment("Stop Recurring Payment")}
              >
                Stop Recurring Payment
              </button>
            </div>
          )}
          <div className={styles.search}>
            {/* till here the stretchable side bar code is  */}

            {/* <div className={styles.textboxWrapper}>
              <input
                type="text"
                placeholder="Search for Bots"
                className={styles.textbox}
                id="search-bots"
              />
              <label htmlFor="search-bots">
                <i className="fa fa-search" />
              </label>
            </div> */}

            <div className={styles.textboxWrapper}>
              <input
                type="text"
                placeholder="Search for Bots"
                className={styles.textbox}
                id="search-bots"
                value={inputSearch}
                onChange={(e) => {
                  setInputSearch(e.target.value);
                  // searchHandle(); // Call the searchHandle function
                  // alert(inputSearch)
                }}
              />
              <label htmlFor="search-bots" onClick={searchHandle}>
                <i className="fa fa-search" />
              </label>
            </div>
          </div>

          {/* ... (other JSX) */}

          {/* Chat tools */}
          <div>
            <select
              className={styles.dropdown}
              value={aiName} // Assuming aiName is the selected value
              onChange={(e) => aiNameHandler(e.target.value, true)}
              disabled={!isSubscribed ? true : false}
            >
              <option value="" disabled>
                Select an chatbot
              </option>
              {aiList?.map((ai) => (
                <option
                  value={ai?.title}
                  key={ai?.id}
                  className={`${styles.aiCategory} ${
                    aiName === ai?.title ? styles.active : ""
                  }`}
                >
                  {ai?.title}
                </option>
              ))}
            </select>

            <div className={styles.chatsTools}>
              {inputSearch.trim() == "" && searchAiList.length == 0 ? (
                aiList?.map((ai) => {
                  const isDropdownItem = [
                    "Claude-2.0",
                    "Mistral-7b",
                    "GPT-3.5-Turbo-0613",
                    "Stable Diffusion Core",
                    "Dalle-3- HD-1024 * 1024",
                    "Ideogram-V2-Turbo",
                    "Command-R",
                    "Gemini-1.0-pro",
                  ].includes(ai?.title);
                  const isActive =
                    aiName === ai?.title ||
                    selectedDropdownItems.includes(ai?.title);
                  return (
                    <div key={ai?.id}>
                      {isDropdownItem ? (
                        <div>
                          <div
                            className={`${styles.aiCategory} ${
                              aiName === ai?.title ? styles.active : ""
                            }`}
                            onClick={() => {
                              if (aiName !== ai?.title) {
                                !isSubscribed
                                  ? setPopupVisible(true)
                                  : aiNameHandler(ai?.title, true);
                              }
                            }}
                            onMouseEnter={() => {
                              handleToggleDropdown(ai?.title);
                              console.log("this is my ai", ai);
                            }}
                            // onMouseLeave={() => handleToggleDropdown(ai?.title)}
                            style={{
                              cursor: !isSubscribed ? "not-allowed" : "pointer",
                            }}
                          >
                            <div className={styles.aiLogoWrapper}>
                              {ai?.icon}
                            </div>
                            <div className={styles.aiToolInfo}>
                              <div className={styles.aiTitle}>{ai?.title}</div>
                              <div className={styles.aiSubTitle}>
                                {ai?.subTitle}
                              </div>
                            </div>
                            <div className={styles.dropdownIcon}>
                              <i
                                className={`fa-solid fa-angle-${
                                  selectedAICategory === ai?.title
                                    ? "up"
                                    : "down"
                                } `}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleToggleDropdown(ai?.title);
                                }}
                              ></i>
                            </div>
                          </div>
                          <Collapse in={selectedAICategory === ai?.title}>
                            <div>
                              {getOtherAiList(ai?.title).map((otherAI) => (
                                <div
                                  key={otherAI.id}
                                  className={`${styles.aiCategory} ${
                                    aiName === otherAI.title
                                      ? styles.active
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (aiName !== otherAI.title) {
                                      !isSubscribed
                                        ? setPopupVisible(true)
                                        : aiNameHandler(otherAI.title, true);
                                    }
                                  }}
                                >
                                  <div className={styles.aiLogoWrapper}>
                                    {otherAI.icon}
                                  </div>
                                  <div className={styles.aiToolInfo}>
                                    <div className={styles.aiTitle}>
                                      {otherAI.title}
                                    </div>
                                    <div className={styles.aiSubTitle}>
                                      {otherAI.subTitle}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Collapse>
                        </div>
                      ) : (
                        <div
                          className={`${styles.aiCategory} ${
                            aiName === ai?.title ? styles.active : ""
                          }`}
                          onClick={() => {
                            if (aiName !== ai?.title) {
                              !isSubscribed
                                ? setPopupVisible(true)
                                : aiNameHandler(ai?.title, true);
                            }
                          }}
                          style={{
                            cursor: !isSubscribed ? "not-allowed" : "pointer",
                          }}
                        >
                          <div className={styles.aiLogoWrapper}>{ai?.icon}</div>
                          <div className={styles.aiToolInfo}>
                            <div className={styles.aiTitle}>{ai?.title}</div>
                            <div className={styles.aiSubTitle}>
                              {ai?.subTitle}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : searchAiList.length > 0 && inputSearch.length > 0 ? (
                searchAiList?.map((ai) => (
                  <React.Fragment key={ai?.id}>
                    <div
                      className={`${styles.aiCategory} ${
                        aiName === ai?.title ? styles.active : ""
                      }`}
                      onClick={() => {
                        if (aiName !== ai?.title) {
                          !isSubscribed
                            ? setPopupVisible(true)
                            : aiNameHandler(ai?.title, true);
                        }
                      }}
                      style={{
                        cursor: !isSubscribed ? "not-allowed" : "pointer",
                      }}
                    >
                      <div className={styles.aiLogoWrapper}>{ai?.icon}</div>
                      <div className={styles.aiToolInfo}>
                        <div className={styles.aiTitle}>{ai?.title}</div>
                        <div className={styles.aiSubTitle}>{ai?.subTitle}</div>
                      </div>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="text-center mt-5">Type More</div>
              )}
            </div>
          </div>
        </div>

        {/* Chat categories */}
        <div className={styles.categories}>
          <div className={styles.mobileCategoryDropdown}>
            <select
              ref={selectRef}
              className={styles.categorySelect}
              value={selectedCategory}
              onChange={(e) => handleCategoryClick(e.target.value, false)}
              onBlur={handleBlur}
            >
              <option value="" disabled>
                Select an category
              </option>

              {toolList?.map((tool) => (
                <option
                  value={tool?.name}
                  key={tool?.id}
                  className={`${styles.category} ${
                    selectedCategory === tool?.name ? styles.active : ""
                  }`}
                  disabled={
                    !isSubscribed
                      ? freeChat === null ||
                        (freeChat < 10 &&
                          (audioCount < 1 || audioCount == null) &&
                          tool?.name === "Audio/Music") ||
                        (freeChat < 10 && tool?.name === "Somebody") ||
                        (freeChat < 10 &&
                          (videoCount < 1 || videoCount == null) &&
                          tool?.name === "Video")
                        ? false
                        : true
                      : false
                  }
                >
                  {tool?.name}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.dropdownCategories}>
            <div className={styles.row}>
              {toolList?.slice(0, 6).map((tool) => (
                // <div
                //   className={`${styles.category} ${
                //     selectedCategory === tool?.name ? styles.active : ''
                //   }`}
                //   onClick={() => !isSubscribed
                //     ? (freeChat === null ||
                //         (freeChat < 10 &&
                //           (audioCount < 1 || audioCount == null) &&
                //           tool?.name === 'Audio/Music') ||
                //         (freeChat < 10 && tool?.name === 'Somebody') ||
                //         (freeChat < 10 && ( videoCount < 1 || videoCount == null) && tool?.name === 'Video'))
                //       ? handleCategoryClick(tool?.name, false)
                //       :  setPopupVisible(true)
                //     : handleCategoryClick(tool?.name, false)}
                //   key={tool?.id}
                //   style={{cursor: !isSubscribed
                //     ? (freeChat === null ||
                //         (freeChat < 10 &&
                //           (audioCount < 1 || audioCount == null) &&
                //           tool?.name === 'Audio/Music') ||
                //         (freeChat < 10 && tool?.name === 'Somebody') ||
                //         (freeChat < 10 && ( videoCount < 1 || videoCount == null) && tool?.name === 'Video'))
                //       ? ''
                //       : 'not-allowed'
                //     : ''}}
                // >

                <div
                  className={`${styles.category} ${
                    selectedCategory === tool?.name ? styles.active : ""
                  }`}
                  onClick={() => {
                    if (selectedCategory !== tool?.name) {
                      !isSubscribed
                        ? freeChat === null ||
                          (freeChat < 10 &&
                            (audioCount < 1 || audioCount == null) &&
                            tool?.name === "Audio/Music") ||
                          (freeChat < 10 && tool?.name === "Somebody") ||
                          (freeChat < 10 &&
                            (videoCount < 1 || videoCount == null) &&
                            tool?.name === "Video")
                          ? handleCategoryClick(tool?.name, false)
                          : setPopupVisible(true)
                        : handleCategoryClick(tool?.name, false);
                    }
                  }}
                  key={tool?.id}
                  style={{
                    cursor: !isSubscribed
                      ? freeChat === null ||
                        (freeChat < 10 &&
                          (audioCount < 1 || audioCount == null) &&
                          tool?.name === "Audio/Music") ||
                        (freeChat < 10 && tool?.name === "Somebody") ||
                        (freeChat < 10 &&
                          (videoCount < 1 || videoCount == null) &&
                          tool?.name === "Video")
                        ? ""
                        : "not-allowed"
                      : "",
                  }}
                >
                  <div>{tool?.icon}</div>
                  <div className={styles.toolTitle}>{tool?.name}</div>
                </div>
              ))}
            </div>
            <div className={styles.row}>
              {toolList?.slice(6, 12).map((tool) => (
                // <div
                //   className={`${styles.category} ${
                //     selectedCategory === tool?.name ? styles.active : ''
                //   }`}
                //   onClick={() => isSubscribed ? handleCategoryClick(tool?.name, false) :  setPopupVisible(true)}
                //   key={tool?.id}
                //   style={{cursor: isSubscribed ? "" : "not-allowed"}}
                // >

                <div
                  className={`${styles.category} ${
                    selectedCategory === tool?.name ? styles.active : ""
                  }`}
                  onClick={() => {
                    if (selectedCategory !== tool?.name) {
                      // console.log("this is my",selectedCategory);
                      // console.log("this is my",tool)
                      isSubscribed
                        ? handleCategoryClick(tool?.name, false)
                        : setPopupVisible(true);
                    }
                  }}
                  key={tool?.id}
                  style={{ cursor: isSubscribed ? "" : "not-allowed" }}
                >
                  <div>{tool?.icon}</div>
                  <div className={styles.toolTitle}>{tool?.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {showChatTextbox && (
          <ChatTextbox
            userId={currentauthUser._id}
            aiName={aiName}
            category={selectedCategory}
            roomStatus={isNewRoomStatus}
            isSubscribed={isSubscribed}
            chatCounts={chatCounts}
          />
        )}
        {showPaymentSuccess && (
          <div className="payment-success-overlay">
            <Setting />

            {/* This close button is to allow the user to close the payment success overlay */}
          </div>
        )}
        {showProfile && (
          <div className="payment-success-overlay">
            <Profile />

            {/* This close button is to allow the user to close the payment success overlay */}
          </div>
        )}
        {/* Room bar */}
        {showRoombar && (
          <Rooms
            userId={currentauthUser._id}
            ai={aiName}
            category={selectedCategory}
            onClose={closeRoombar}
          />
        )}
        {/* <Chatheader showRoombar={showRoombar} toggleRoombar={toggleRoombar}  /> */}

        {/* Popup subscription open */}

        {isPopupVisible && (
          <div className={styles.overlay}>
            <div className={styles.popup}>
              {/* <button className={styles.popp}  onClick={() => setPopupVisible(false)} >
    <i className="fas fa-times"></i>X
    </button> */}

              <button
                className={styles.popp}
                onClick={() => {
                  setShowPopup(false);
                  setPopupVisible(false);
                }}
              >
                X
              </button>

              {subscriptionPrompt && (
                <div
                  className={`subscription-box ${showMessage ? "active" : ""}`}
                >
                  <button
                    className="close-subscription-btn"
                    onClick={closeMessage}
                  >
                    &times;
                  </button>
                  User already has a subscription!
                </div>
              )}
              {subscriptions.map((subscription) => (
                <div
                  className="aai-price-table mb-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  key={subscription._id}
                >
                  <div className="row g-4 align-items-center justify-content-between">
                    <div className="col-xl-4 col-lg-6">
                      <div className="aai-price-pack">
                        <h3 className="aai-price-pack-name">
                          {subscription.plan_name}
                        </h3>
                        <p className="aai-price-pack-desc">
                          {subscription.plan_description}
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                      <ul className="aai-price-lists list-unstyled">
                        {/* ... your list items with SVGs ... */}
                      </ul>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                      <div
                        className="aai-price-value"
                        style={{ marginLeft: "-10px", fontSize: "2rem" }}
                      >
                        <h2
                          className="aai-price"
                          style={{ fontSize: "1.5rem" }}
                        >
                          ₹{subscription.plan_price}
                        </h2>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-6">
                      <div>
                        <input
                          type="hidden"
                          name="stripeId"
                          value={subscription.stripe_id}
                        />
                        <button
                          className="aai-btn btn-pill-solid"
                          id="submit"
                          role="link"
                          onClick={(e) =>
                            handlePayClick(
                              e,
                              subscription.plan_id,
                              subscription.plan_name,
                              subscription.plan_price,
                              subscription.stripe_id
                            )
                          }
                        >
                          PAY
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* Popup for payment options */}
              {showPopup && (
                <div className="popup">
                  <h2>Select Payment Option</h2>
                  <br />
                  {/* <button className="popup-close" onClick={closeMessage} style={{fontSize:"larger",marginTop:"-150px",marginLeft:"355px",position:"fixed",color:'black'}}>X</button> */}

                  <button
                    className="popup-close"
                    onClick={closeShowPopup}
                    style={{
                      fontSize: "larger",
                      // marginTop: "-150px",
                      marginLeft: "355px",
                      position: "fixed",
                      color: "black",
                    }}
                  >
                    X
                  </button>

                  <button
                    onClick={() => handlePaymentOptionClick("PhonePe")}
                    className={
                      selectedPaymentOption === "PhonePe" ? "selected" : ""
                    }
                  >
                    PhonePe
                  </button>
                  {/* //billdesk from and above button is coommet */}
                  <button onClick={openModal}>BillDesk</button>
                  {/* till here */}
                  <button
                    onClick={() => handlePaymentOptionClick("Stripe")}
                    className={
                      selectedPaymentOption === "Stripe" ? "selected" : ""
                    }
                  >
                    Stripe
                  </button>
                  <br />
                  <br />
                  <br />

                  <center>
                    <button onClick={handleProceed}>Proceed</button>
                  </center>
                </div>
              )}

              {/* this code is for the modal for billdesk options  */}
              {isModalOpen && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  style={{ display: "block" }}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                    style={{ height: "80vh" }}
                  >
                    <div className="modal-content" style={{ height: "100%" }}>
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Payment Options
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={closeModal}
                          aria-label="Close"
                        ></button>
                      </div>
                      <div
                        className="modal-body"
                        style={{
                          maxHeight: "calc(80vh - 56px - 50px)", // Adjusted to account for the footer height
                          overflowY: "auto",
                        }}
                      >
                        <div className="d-flex flex-column align-items-center">
                          <div className="text-center mb-4">
                            <button
                              className={`btn mb-2 ${
                                selectedPaymentOption === "One-Time Payment"
                                  ? "btn-success"
                                  : "btn-primary"
                              }`}
                              onClick={() =>
                                handlePaymentOptionClick("One-Time Payment")
                              }
                            >
                              One-Time Payment
                            </button>
                            <p className="text-muted">
                              Use this option to make a single payment for your
                              purchase. This is ideal for one-off transactions
                              where you do not wish to commit to recurring
                              payments.
                            </p>
                          </div>
                          <div className="text-center mb-4">
                            <button
                              className={`btn mb-2 ${
                                selectedPaymentOption === "Recurring Payment"
                                  ? "btn-success"
                                  : "btn-primary"
                              }`}
                              onClick={() =>
                                handlePaymentOptionClick("Recurring Payment")
                              }
                            >
                              Recurring Payment
                            </button>
                            <p className="text-muted">
                              Choose this option if you prefer a
                              subscription-based model. Your account will be
                              automatically charged on a regular basis (e.g.,
                              monthly or yearly) for continued access to the
                              service.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* Footer with Proceed button */}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleProceed}
                          disabled={!selectedPaymentOption} // Disable if no option is selected
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* till here the modal for billdesk end */}
              <button
                className="aai-btn btn-pill-solid"
                onClick={() => setPopupVisible(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {/* Popup subscription close */}

        {/* Right Side bar */}
        <div className={styles.rightSidebar}>
          <div />
          <div>
            {countOfRooms?.map((room, index) => {
              const images = {
                oneAI: (
                  <img
                    src="/assets/img/chat/oneai.png"
                    alt="Chat sonic"
                    width="30px"
                  />
                ),
                "gpt-3.5-turbo-0613": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-0301": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-0125": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-vision-preview": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-1106-preview": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-0125-preview": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-turbo-preview": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-16k": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-1106": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-16k-0613": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),

                "gpt-3.5-turbo-instruct": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-0613": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-instruct-0914": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),

                "gpt-4o": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4o-2024-05-13": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "o1-mini": (
                  <img
                    src="/assets/img/logo/o1.jpeg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "o1-preview": (
                  <img
                    src="/assets/img/logo/o1.jpeg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gemini-1.0-pro": (
                  <img
                    src="/assets/img/chat/googlebard.png"
                    width="30px"
                    alt="Google Bard"
                  />
                ),
                "gemini-1.5-pro": (
                  <img
                    src="/assets/img/chat/googlebard.png"
                    width="30px"
                    alt="Google Bard"
                  />
                ),
                "claude-2.0": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claude"
                  />
                ),
                "claude-2.1": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claude"
                  />
                ),
                "claude-+--1.2": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claudeeee"
                  />
                ),
                "claude-3-opus-20240229": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claudeeee"
                  />
                ),
                "claude-3-sonnet-20240229": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claudeeee"
                  />
                ),
                "claude-3-haiku-20240307": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claudeeee"
                  />
                ),
                googlePalm: (
                  <img
                    src="/assets/img/chat/palm.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                mistral7b: (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-tiny-2312": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-tiny": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-small-2312": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "open-mixtral-8x7b": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-small": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-small-2402": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-small-latest": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-medium-latest": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-medium-2312": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-medium": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-large-latest": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-large-2402": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "open-mixtral-8x22b": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "llama 2 70b": (
                  <img
                    src="/assets/img/chat/llama.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion Core": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion 3": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion 3-Turbo": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion 1.6": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion-XL 1.0": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- HD-1024 * 1024": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- HD-1024 * 1792": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- Standard-1024 * 1024": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- Standard-1024 * 1792": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- Standard-1024 * 1792": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-2-1024 * 1024": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-2-512 * 512": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-2-256 * 256": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "command-R": (
                  <img
                    src="/assets/img/chat/command.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "command-R-Plus": (
                  <img
                    src="/assets/img/chat/command.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),

                "claude-3-5-sonnet-20240620": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claude-3.5"
                  />
                ),

                Haiper_AI: (
                  <img
                    src="/assets/img/chat/Haiper.jpeg"
                    width="30px"
                    alt="Haiper_AI"
                  />
                ),

                Haiper_Pic: (
                  <img
                    src="/assets/img/chat/Haiper.jpeg"
                    width="30px"
                    alt="Haiper_Pic"
                  />
                ),


                "Ideogram-V2-Turbo": (
                  <img
                    src="/assets/img/logo/ideogram.jpeg"
                    width="30px"
                    alt="Ideogram-V2-Turbo"
                  />
                ),

                "Ideogram-V2": (
                  <img
                    src="/assets/img/logo/ideogram.jpeg"
                    width="30px"
                    alt="Ideogram-V2"
                  />
                ),

                "Ideogram-V1-Turbo": (
                  <img
                    src="/assets/img/logo/ideogram.jpeg"
                    width="30px"
                    alt="Ideogram-V1-Turbo"
                  />
                ),

                "Ideogram-V1": (
                  <img
                    src="/assets/img/logo/ideogram.jpeg"
                    width="30px"
                    alt="Ideogram-V1"
                  />
                ),

                Beatoven: (
                  <img
                    src="/assets/img/logo/Beatoven.png"
                    width="30px"
                    alt="Beatoven"
                  />
                ),
              };

              if (!room?._id) {
                return null;
              }
              return (
                <div
                  className={
                    index
                      ? "position-relative text-center mt-4 cursor-pointer"
                      : "position-relative cursor-pointer"
                  }
                  onClick={() => openRoombar(room?._id)}
                  key={room?._id}
                >
                  {images?.[room?._id]}
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                    {room?.total}
                  </span>
                </div>
              );
            })}
          </div>
          <div />
        </div>
      </div>

      {/* Popup category open */}
      {isCatPopupVisible && (
        <div className={styles.overlay}>
          <div className={styles.popup} id="cat-popup-main-div">
            {/* <button className="popup-close"  onClick={() => setCatPopupVisible(false)} style={{fontSize:"larger",marginTop:"-40px",marginLeft:"650px",position:"fixed",color:'white'}}>X</button> */}

            <button
              className="popup-close"
              onClick={() => setCatPopupVisible(false)}
              style={
                {}
                // {fontSize:"larger",marginTop:"-40px",marginLeft:"650px",position:"fixed",color:'white'}
              }
            >
              X
            </button>

            {subscriptionPrompt && (
              <div
                className={`subscription-box ${showMessage ? "active" : ""}`}
              >
                <button
                  className="close-subscription-btn"
                  onClick={closeMessage}
                >
                  &times;
                </button>
                User already has a subscription!
              </div>
            )}

            {/* <div className="aai-price-table mb-4"> */}
            <div className="aai-price-table">
              <div
                className="row align-items-center justify-content-between mb-4"
                style={{ color: "#04882b", marginLeft: "-3rem" }}
              >
                <h4>Do you want to switch the category?</h4>
              </div>
              <div className="g-2 align-items-center justify-content-between cat-popup-div">
                <span className="" style={{ marginRight: "1rem" }}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleNewRoom(true, setNewCategory)}
                  >
                    Yes
                  </button>
                </span>
                <span className="" style={{}}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleNewRoom(false, setNewCategory)}
                  >
                    No
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Popup category close */}

      {/* Popup Aichat open */}
      {isAichatPopupVisible && (
        <div className={styles.overlay}>
          <div className={styles.popup} id="cat-popup-main-div">
            {/* <button className="popup-close"  onClick={() => setAiChatPopupVisible(false)} style={{fontSize:"larger",marginTop:"-40px",marginLeft:"650px",position:"fixed",color:'white'}}>X</button> */}

            <button
              className="popup-close"
              onClick={() => setAiChatPopupVisible(false)}
              style={
                {}
                // {fontSize:"larger",marginTop:"-40px",marginLeft:"650px",position:"fixed",color:'white'}
              }
            >
              X
            </button>

            {subscriptionPrompt && (
              <div
                className={`subscription-box ${showMessage ? "active" : ""}`}
              >
                <button
                  className="close-subscription-btn"
                  onClick={closeMessage}
                >
                  &times;
                </button>
                User already has a subscription!
              </div>
            )}

            {/* <div className="aai-price-table mb-4"> */}
            <div className="aai-price-table">
              <div
                className="row align-items-center justify-content-between mb-4"
                style={{ color: "#04882b", marginLeft: "-3rem" }}
              >
                <h4>Do you want to switch the ChatBot?</h4>
              </div>
              <div className="g-2 align-items-center justify-content-between cat-popup-div">
                <span className="" style={{ marginRight: "1rem" }}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleNewchatBot(true)}
                  >
                    Yes
                  </button>
                </span>
                <span className="" style={{}}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleNewchatBot(false)}
                  >
                    No
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Popup Aichat close */}
    </div>
  );
};
export default ChatPage;
