import React, { useState } from "react";
import styles from "./ChatMessage.module.css"; // Add styles for .optionsButton and .optionsMenu here
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "config/axios";

const ChatMessage = ({
  chat,
  onDelete,
  onLike,
  onDislike,
  user,
  userId,
  type,
  showOptions,
  setShowOptions,
  // senderType,
  // setSenderType,
  isPopupActive,
  togglePopup,
}) => {
  // const [showOptions, setShowOptions] = useState(false);
  const [liked, setLiked] = useState(false);
  const [messageTimeout, setMessageTimeout] = useState(null);
  const [disliked, setDisliked] = useState(false);
  const [isChatDeletePopup, setIsChatDeletePopup] = useState(false);
  const [isChatDelete, setIsChatDelete] = useState(false);

  const closeMessage = () => {
    setShowMessage(false);
    setShowMessages(false);
    clearTimeout(messageTimeout);
  };
  const [showMessage, setShowMessage] = useState(false);
  const [showMessages, setShowMessages] = useState(false);

  let className = user === chat?.sender ? styles.sender : styles.receiver;
  let wrapperClassName =
    user === chat?.sender ? styles.senderWrapper : styles.receiverWrapper;
  // console.log(chat, "chat---------------------------------------");
  // const toggleOptions = (e) => {
  //   setShowOptions((prevShowOptions) => !prevShowOptions);
  // };
  const navigate = useNavigate();
  const handleLike = async () => {
    try {
      const response = await axiosInstance.post(
        `chat/like/${chat.id}/${userId}`
      );
      // setShowOptions(false);
      handleOptionsClick();
      console.log(response, "chat-options-response");
      if (response.status) {
        setLiked(true);
      }
    } catch (error) {
      console.error("Error liking chat", error);
    }
  };

  const handleDislike = async () => {
    try {
      const response = await axiosInstance.post(
        `chat/unlike/${chat.id}/${userId}`
      );
      // setShowOptions(false);
      handleOptionsClick();
      if (response.status) {
        setLiked(false);
      }
    } catch (error) {
      console.error("Error unliking chat", error);
    }
  };

  const handleDeletePopup = () => {
    // setShowOptions(false);
    handleOptionsClick();
    setIsChatDeletePopup(true);
  };

  const handleDelete = async () => {
    try {
      console.log("delete-started");
      handleOptionsClick();
      // setShowOptions(false);
      const response = await axiosInstance.post(
        `chat/delete/${chat.id}/${type}`
      );
      // setShowOptions(false);
      handleOptionsClick();
      if (response.status) {
        window.location.reload();
        if (onDelete) {
          onDelete(chat);
        }
      } else {
        console.error("Error deleting chat");
      }
    } catch (error) {
      console.error("Error deleting chat", error);
    }
  };

  const handleCopy = async () => {
    // setShowOptions(false);
    handleOptionsClick();
    if (navigator.clipboard) {
      try {
        let message;
        if (type == "sender") {
          message = chat?.sender == "guest" ? chat?.message : chat?.question;
        } else {
          message = chat?.message;
        }
        await navigator.clipboard.writeText(message);
        setShowMessages(true);
        handleOptionsClick();
        // setShowOptions(false);
        const timeoutId = setTimeout(() => {
          setShowMessages(false);
        }, 3000);
        setMessageTimeout(timeoutId);
      } catch (error) {
        console.error("Failed to copy message", error);
      }
    } else {
      console.error("Clipboard API not available.");
    }
  };

  const handleDeleteChat = (status) => {
    // setShowOptions(false);
    handleOptionsClick();
    setIsChatDeletePopup(false);
    if (status != false) {
      handleDelete();
    }
  };

  const handleShare = async () => {
    setShowMessage(true);
    // setShowOptions(false);
    handleOptionsClick();

    // Get the current room ID
    const roomId = window.location.pathname.split("/").pop();

    // Save the room ID to sessionStorage
    localStorage.setItem("sharedRoomId", roomId);
    const selectedCategory = localStorage.getItem("selectedCategory");
    const selectedAiCategory = localStorage.getItem("selectedAiCategory");

    const shareableLink = `${window.location.origin}/chat/${roomId}?selectedCategory=${selectedCategory}&selectedAiCategory=${selectedAiCategory}`;

    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(shareableLink);
        console.log("Shareable link copied:", shareableLink);

        if (navigator.share) {
          await navigator.share({
            title: "Chat Message",
            text: chat?.message,
            url: shareableLink,
          });
          console.log("Message shared!");
        } else {
          console.log("Shareable link:", shareableLink);
        }

        setShowOptions(false); // Close options menu
        const timeoutId = setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        setMessageTimeout(timeoutId);
      } catch (error) {
        console.error("Failed to share message or copy link", error);
      }
    } else {
      console.error("Clipboard API not available.");
    }
  };

  const handleDownload = () => {
    // setShowOptions(false);

    // Assuming chat.image is the URL or Base64 data of the image
    const imageUrl = chat.message;

    // Create an anchor element
    const link = document.createElement("a");
    link.href = imageUrl;

    // Set the download attribute with a suggested filename
    link.download = "downloaded_image";

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  const handleOptionsClick = () => {
    togglePopup(chat.id, chat.sender);
  };

  const extractVideoUrl = (message) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(message, "text/html");
    const videoSource = doc.querySelector("video source");
    return videoSource ? videoSource.src : null;
  };

  const handleDownloadVideo = () => {
    const videoUrl = extractVideoUrl(chat.message);

    if (videoUrl) {
      const anchor = document.createElement("a");
      anchor.href = videoUrl;
      anchor.download = "video.mp4"; // Set the file name
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } else {
      console.error("No video URL found.");
    }
  };

  const handleDownloadVideoNative = () => {
    const videoUrl = chat.message;
    if (videoUrl) {
      // Extract file extension (e.g., mp4, webm)
      const fileExtension = videoUrl.split(".").pop().split("?")[0]; // To handle URLs with query strings
      const fileName = `video.${fileExtension}`; // Set the dynamic file name
      const anchor = document.createElement("a");
      anchor.href = videoUrl;
      anchor.download = fileName; // Use the dynamic file name
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } else {
      console.error("No video URL found.");
    }
  };

  // client/src/pages/Chat/components/ChatBoard/ChatMessage.js

  const handleDownloadIdeoGram = async () => {
    try {
      const urlIdeo = chat?.message; // Get the URL from the message
      console.log("thi id th url ", urlIdeo);
      // Make a POST request to convert the URL to Base64
      const response = await fetch("https://db.oneaichat.com/convertbase", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Ensure the correct content type
        },
        body: JSON.stringify({ urlIdeo }), // Send the URL in the body
      });

      if (!response.ok) {
        console.log("hi error ");
        const errorText = await response.text(); // Get the error text from the response
        console.error("Error response:", errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.base64) {
        // Create an anchor element to trigger the download
        const a = document.createElement("a");

        a.href = `data:image/jpeg;base64,${data.base64}`; // Assuming JPEG image, adjust MIME type if needed

        a.download = urlIdeo.split("/").pop().split("?")[0]; // Set filename based on URL
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error downloading the image:", error);
      alert("Error occurred while downloading the image.");
    }
  };

  return (
    <div class="chat-options">
      <div
        className={wrapperClassName}
        id="chat-option-new"
        key={chat?.timestamp}
        style={{
          paddingBottom: isPopupActive && chat.sender === "system" && "150px",
        }}
      >
        <div className={className}>
          {liked && <span className={styles.likeIcon}>👍</span>}
          {/* {chat?.message} */}
          {/* <button onClick={toggleOptions} className={`${styles.optionsButton} ${showOptions ? styles.showOptions : ''}`}>
          ⋮
        </button> */}
          <svg
            // onClick={toggleOptions}
            onClick={handleOptionsClick}
            style={{ width: "1.5rem" }}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM19 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
          </svg>
          {console.log("this i sthe showOption after cliclk ", showOptions)}
          {/* {(showOptions === chat.id && senderType === chat.sender) && ( */}
          {isPopupActive && (
            <div className={styles.optionsMenu}>
              <ul>
                {type != "sender" && (
                  <li onClick={handleLike}>{liked ? "" : "Like"}</li>
                )}
                {type != "sender" && <li onClick={handleDislike}>Dislike</li>}
                <li onClick={handleDeletePopup}>Delete</li>
                {!chat?.message.startsWith("data:image") && (
                  <li onClick={handleCopy}>Copy message</li>
                )}
                <li onClick={handleShare}>Share</li>
                {/* Add Download option conditionally based on your requirement */}
                {chat?.message.startsWith("data:image") && type != "sender" && (
                  <li onClick={handleDownload}>Download</li>
                )}
                {
                  /* // Render conditionally based on supported video formats */
                  console.log("this i steh chat message ", chat.message)
                }
                {(chat?.message.includes(`type="video/mp4"`) ||
                  chat?.message.includes(`video.webm`)) &&
                  type !== "sender" && (
                    <li
                      onClick={() => {
                        if (chat?.message.includes(`video.webm`)) {
                          console.log("video is in webm");
                          handleDownloadVideoNative(); // Directly use the URL for webm
                        } else if (chat?.message.includes(`type="video/mp4"`)) {
                          console.log("video is in mp4");
                          handleDownloadVideo(); // Extract URL from the video tag for mp4
                        }
                      }}
                    >
                      Download
                    </li>
                  )}

                {chat?.message.includes("ideogram.ai") && type !== "sender" && (
                  <li onClick={handleDownloadIdeoGram}>Download</li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      {/* Popup Aichat open */}
      {isChatDeletePopup && (
        <div className="Chat_overlay__CqioD">
          <div
            className="Chat_popup__1Q3W5"
            id="cat-popup-main-div-deletechatmessage"
          >
            <button
              className="popup-close"
              onClick={() => setIsChatDeletePopup(false)}
              style={{
                fontSize: "larger",
                marginTop: "-40px",
                marginLeft: "650px",
                position: "fixed",
                color: "white",
              }}
            >
              X
            </button>
            <div className="aai-price-table">
              <div
                className="row align-items-center justify-content-between"
                style={{ color: "#04882b", marginLeft: "-3rem" }}
              >
                <h4>Are you sure you want to delete Message?</h4>
              </div>
              <div className="g-2 align-items-center justify-content-between cat-popup-div mt-4">
                <span className="" style={{ marginRight: "1rem" }}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleDeleteChat(true)}
                  >
                    Yes
                  </button>
                </span>
                <span className="" style={{}}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleDeleteChat(false)}
                  >
                    No
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`message-box ${showMessages ? "active" : ""}`}>
        <button className="close-btn" onClick={closeMessage}>
          &times;
        </button>
        Message copied!
      </div>
      <div
        className={`message-box ${showMessage ? "active" : ""}`}
        style={{ backgroundColor: "#0f0", color: "black" }}
      >
        <button className="close-btn" onClick={closeMessage}>
          &times;
        </button>
        Shareable Link Copied!
      </div>
    </div>
  );
};

export default ChatMessage;
