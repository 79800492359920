import React, { useState, useEffect } from "react";
import InfiniteTopScroll from "components/InfiniteTopScroll";
import styles from "./ChatBoard.module.css";
import ChatMessage from "./ChatMessage";
import ReactPlayer from "react-player";

const ChatBoard = (props) => {
  const { chats, scrollId, userId, chattyping } = props;
  const { fetchNextChatPage, hasNextChatMore, isChatLoading } = props;
  const [activePopup, setActivePopup] = useState(null); // Combines showOptions and senderType

  const togglePopup = (chatId, sender) => {
    const uniqueKey = `${chatId}-${sender}`;
    setActivePopup((prev) => (prev === uniqueKey ? null : uniqueKey));
  };
  const user = "guest";

  const [istyping, setTyping] = useState(chattyping);
  const [displayedMessage, setDisplayedMessage] = useState("");

  useEffect(() => {
    console.log(chattyping, "typing");
    if (chattyping !== true) {
      if (chats.length > 0) {
        let latestChat = chats[0];

        if (
          latestChat.sender === "system" &&
          latestChat.chatType === "new-chat"
        ) {
          setTyping(true);
          let index = 0;
          const typingInterval = setInterval(() => {
            if (index < latestChat.message.length - 1) {
              setDisplayedMessage(
                (prevMessage) => prevMessage + latestChat.message[index]
              );
              index++;
            } else {
              clearInterval(typingInterval);
              setTyping(false);
            }
          }, 10);

          return () => clearInterval(typingInterval);
        } else {
          const latestChats = chats[0];
          console.log(latestChats, "latestChat");
          setDisplayedMessage(latestChats.message);
          console.log(latestChats, "latestChat");
        }
      }
    }
  }, [chats]);

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  const downloadFile = (base64Data, fileName) => {
    const blob = b64toBlob(base64Data, "application/doc");
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };

  const getChat = (chat) => {
    const isPopupActive = activePopup === `${chat.id}-${chat.sender}`;
    console.log(chat, "all-chats");
    let className = "";
    let wrapperClassName = "";
    let showOptionsMenu = false;
    let type;
    let showImage = false;
    let newFileUrl;

    if (user === chat?.sender) {
      className = styles.sender;
      wrapperClassName = styles.senderWrapper;
      showOptionsMenu = true;
      type = "sender";
      if (chat?.image !== "") {
        showImage = true;
        newFileUrl = `https://oneaichat.com/${chat?.image}`;
      }
    } else if (user !== chat?.sender && chat?.sender === "system") {
      className = styles.receiver;
      wrapperClassName = styles.receiverWrapper;
      showOptionsMenu = true;
      type = "receiver";
    }

    const isAudioMessage = chat?.message?.startsWith("data:audio");
    const isImageMessage = chat?.message?.startsWith("data:image");
    const isDocMessage = chat?.message?.startsWith("data:application/doc");
    let audioSrc = chat?.message;

    if (isAudioMessage) {
      const base64Content = chat?.message.split(";base64,").pop();
      const decodedContent = atob(base64Content);
      const uint8Array = new Uint8Array(decodedContent.length);
      for (let i = 0; i < decodedContent.length; ++i) {
        uint8Array[i] = decodedContent.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "audio/wav" });
      audioSrc = URL.createObjectURL(blob);
    }

    if (isDocMessage) {
      const docData = chat.message.split(";base64,");
      const docBlob = b64toBlob(docData[1], "application/doc");
      const docUrl = URL.createObjectURL(docBlob);
      const content = (
        <div>
          <button
            onClick={() => downloadFile(docData[1], "document.doc")}
            className={"ChatBoard_receiver__TSttg"}
          >
            Download Document
          </button>
        </div>
      );

      return (
        <div className={styles.chatMessage}>
          <div
            className={`${wrapperClassName} new wrapper`}
            id="recentchatboard"
            key={chat?.timestamp}
          >
            {showOptionsMenu && (
              <ChatMessage chat={chat} userId={userId} type={type} />
            )}
            {content}
          </div>
        </div>
      );
    }

    if (chat?.message !== "") {
      let content;
      if (isAudioMessage) {
        content = (
          <audio controls>
            <source src={audioSrc} type="audio/wav" />
            Your browser does not support the audio tag.
          </audio>
        );
      } else if (isImageMessage) {
        content = (
          <img
            src={chat?.message}
            alt="User uploaded image"
            height={"20%"}
            width={"20%"}
          />
        );
      } else if (showImage) {
        content = (
          <div className="pdf-main-div">
            <div className={className}>{chat?.message}</div>
            <div className="pdf-url">
              <a href={newFileUrl} target="_blank">
                {chat?.image}
              </a>
            </div>
          </div>
        );
      } else if (chat.message.includes("<video")) {
        content = <div dangerouslySetInnerHTML={{ __html: chat?.message }} />;
      } else if (chat.message.includes("ideogram.")) {
        // Render HTML video tag properly

        content = (
          <img
            src={chat?.message}
            alt="User uploaded image"
            height={"20%"}
            width={"20%"}
          />
        );
      } else if (chat?.message.includes("haiper")) {
        const isValidImageUrl = (url) => {
          try {
            new URL(url);
            return /\.(jpg|jpeg|png|gif|webp|svg)$/i.test(url); // Check for valid image extensions
          } catch (e) {
            return false;
          }
        };

        if (isValidImageUrl(chat.message)) {
          content = (
            <img
              src={chat.message}
              alt="chat content"
              style={{
                width: "200px",
                height: "200px",
                objectFit: "contain", // Ensures the full image is visible without cropping
                background: "#f0f0f0", // Adds a background color for empty spaces
              }}
            />
          );
        } else {
          content = <p>Invalid image URL</p>; // Provide fallback if invalid
        }
      } else if (chat.message.includes(".webm")) {
        console.log(chat.message, "chat-message-------");
        content = (
          <div>
            {/* <img
            src={'/generated_video.gif'}
            alt="Ai Video"
            height={"20%"}
            width={"20%"}
          /> */}
            {/* <ReactPlayer url={'/generated_video.gif'} playing={true} controls={true} width="640px" height="360px" /> */}
            {/* <ReactPlayerurl="/path-to-your/video-file.webm"playing={true}controls={true}width="640px"height="360px" /> */}

            <video width="300" height="200" controls>
              <source src={chat?.message} type="video/webm" />
            </video>
          </div>
        );
      } else {
        //display the coading format response
        // const renderMessageContent = (message, className) => {
        //   if (!message.includes("```")) {
        //     // If no backticks are found, return the message as a normal div
        //     return <div className={className}>{message}</div>;
        //   }

        //   // Split the response into text and code parts
        //   const [text, codeWithLanguage] = message.split("```");
        //   const codeLines = codeWithLanguage?.split("\n");
        //   const language = codeLines?.shift()?.trim(); // Extract language (e.g., 'php') if provided

        //   return (
        //     <div className={className}>
        //       {/* Render the descriptive text */}
        //       <p>{text.trim()}</p>

        //       {/* Render the code snippet */}
        //       <pre>
        //         <code className={`language-${language || "text"}`}>
        //           {codeLines.join("\n")}
        //         </code>
        //       </pre>
        //     </div>
        //   );
        // };

        // // Example usage
        // content = renderMessageContent(chat?.message, className);
        content = <div className={className}>{chat?.message}</div>;
      }
      return (
        <div className={styles.chatMessage}>
          <div
            className={`${wrapperClassName} new wrapper`}
            id="recentchatboard"
            key={chat?.timestamp}
            style={{
              marginBottom: isPopupActive && chat.sender === "guest" && "50px",
            }}
          >
            {showOptionsMenu && (
              <ChatMessage
                chat={chat}
                userId={userId}
                type={type}
                // showOptions={showOptions}
                // setShowOptions={setShowOptions}
                // senderType={senderType}
                // setSenderType={setSenderType}
                isPopupActive={isPopupActive}
                togglePopup={togglePopup}
              />
            )}
            {content}
          </div>
        </div>
      );
    }

    if (!chat?.message && chat.chatType === "new") {
      return (
        <div className={styles.chatMessage}>
          <div
            className={`${wrapperClassName} new wrapper`}
            id="recentchatboard"
            key={chat?.timestamp}
          >
            <div className={styles.error}>
              Sorry, there was an error processing your request. Please try
              again.
            </div>
          </div>
        </div>
      );
    }
  };

  let endMessage = <div />;

  if (isChatLoading) {
    endMessage = (
      <div className={styles.loader}>
        <i className="fa fa-spinner fa-pulse" />
      </div>
    );
  }

  return (
    <div
      className={styles.wrapper}
      style={{ height: chats.length === 0 ? "30vh" : "60vh" }}
    >
      <InfiniteTopScroll
        id={scrollId}
        endMessage={endMessage}
        classes={{ root: styles.container }}
        items={chats?.map((chat) => getChat(chat))}
        next={fetchNextChatPage}
        hasMore={hasNextChatMore}
      />
    </div>
  );
};

export default ChatBoard;
