
import router from "./routes";
import { RouterProvider } from "react-router-dom";

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;



// import router from "./routes";
// import { RouterProvider } from "react-router-dom";

// const App = () => {
//   return(
//     <div>
//       <h1>OneAIChat Is Under Maintenance</h1>
//     </div>
//   );
// };

// export default App;
