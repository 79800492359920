import React, { useState, useEffect, useRef } from "react";
import Rooms from "./components/Rooms";
import axios from "axios";
import {
  toolList,
  aiList as importedAiList,
  healthAiList,
  marketingAiList,
  videoAiList,
  scienceAiList,
  writingAiList,
  designAiList,
  codingAiList,
  musicAiList,
  mathAiList,
  faithAiList,
  financeAiList,
} from "data/ailist";
import ChatTextbox from "pages/Chat/components/ChatTextbox";
import useRoomCountOfAi from "hooks/useRoomCountOfAi";
import styles from "./Chat.module.css";
import Link from "components/Link";
import PaymentSuccess from "../PaymentSucess";
import Setting from "pages/Setting"; // Assuming you've exported your component.
import useChatRoomByUser from "hooks/useChatRoomByUser";
import { axiosInstance } from "config/axios";
import Chatheader from "components/Header/ChatHeader/Chatheader";
import Profile from "pages/Profile";
import { Collapse } from "react-bootstrap"; // Import Bootstrap Dropdown
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const ChatPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("Somebody");
  const location = useLocation();
  const [setNewCategory, setCategory] = useState("Somebody");
  const [isAiCategory, setAiCategory] = useState("OneAIChat-Somebody");
  const [aiList, setAiList] = useState(importedAiList); // Default to the full AI list
  const [chats, setChats] = useState([]);
  const [showRoombar, setShowRoombar] = useState(false);
  const [type, setType] = useState("Somebody");
  const [aiName, setAIName] = useState("OneAIChat-Somebody");
  const [selectedChatbot, setSelectedChatbot] = useState("");
  const isLoggedIn = localStorage.getItem("loggedIn");
  const [subscriptions, setSubscriptions] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [searchAiList, setSearchAiList] = useState([]);

  // billdesk from
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isStopRecurringButton, setIsRecurringButton] = useState("");

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // the below use effect is to desplay the stop recurring button

  useEffect(() => {
    const authUserString = localStorage.getItem("authUser");
    const authUser = authUserString ? JSON.parse(authUserString) : null;

    if (authUser) {
      const userEmail = authUser.email;
      const userid = authUser._id;

      console.log("this is the userEmail ", userEmail);
      console.log("this is teh user id ", userid);

      fetch("https://db.oneaichat.com/api/get-mandate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          userId: userid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data); // Log the success response
          setIsRecurringButton(data.data);
          if (data.success) {
            console.log("Mandate Status:", data.data); // Log the specific mandate status
          } else {
            console.log("Error Message:", data.message); // Log the error message
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []); // Empty dependency array means this effect runs once on mount

  // the above use effect is to desplay the stop recurring button

  //billdesk till

  useEffect(() => {
    axiosInstance
      .get(`/subscriptions`)
      .then((response) => {
        console.log("Response data:", response.data);
        if (response.data.status) {
          setSubscriptions(response.data.subscriptions);
        } else {
          console.error("Failed to fetch subscriptions");
        }
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await checkUserSubscription();
        checkFreeChatCount();

        if (!isLoggedIn) {
          navigate("/login");
        }

        // Check if there is state in the location object
        if (location.state && location.state.selectedCategory) {
          setSelectedCategory(location.state.selectedCategory);
        } else if (location.state && location.state.roomCategory) {
          setSelectedCategory(location.state.roomCategory);
        } else {
          setSelectedCategory(setNewCategory);
        }
      } catch (error) {
        console.error("Error in useEffect:", error);
        // Handle errors as needed
      }
    };

    fetchData(); // Call the asynchronous function

    // No dependencies needed for this useEffect
  }, [location.state]); // Empty dependency array to run the effect only once on mount
  useEffect(() => {
    // Get values from LocalStorage
    const storedCategory = localStorage.getItem("selectedCategory");
    const storedAiCategory = localStorage.getItem("selectedAiCategory");

    console.log("Stored Category:", storedCategory);
    console.log("Stored AI Category:", storedAiCategory);

    // Set the state based on LocalStorage values
    setSelectedCategory(storedCategory || "Somebody");
    setAIName(storedAiCategory || "OneAIChat-Somebody");
    console.log("Selected Category:", selectedCategory);
    console.log("AI Category:", isAiCategory);
  }, []); // Trigger effect only on component mount

  const typeHandler = (name) => {
    setType(name);
  };
  const [subscriptionPrompt, setSubscriptionPrompt] = useState(false);
  const navigate = useNavigate();
  const [rotate, setRotate] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isCatPopupVisible, setCatPopupVisible] = useState(false);
  const [isAichatPopupVisible, setAiChatPopupVisible] = useState(false);
  const [isNewRoomStatus, setNewRoomStatus] = useState(false);

  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showChatTextbox, setShowChatTextbox] = useState(true);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isSubscribed, setSubscribed] = useState(false);
  console.log(isSubscribed, "isSubscribed-status");
  const [chatCounts, setChatCounts] = useState({
    freeChat: null,
    imageCount: null,
    videoCount: null,
    audioCount: null,
    textCount: null,
    docCount: null,
  });
  {
    /* Dropdown setting start */
  }
  const [isDroppdownVisible, setDroppdownVisible] = useState(false);
  const [selectedDropdownItems, setSelectedDropdownItems] = useState([]);
  const [selectedAICategory, setSelectedAICategory] = useState(null);

  const handleToggleDropdown = (aiTitle) => {
    setSelectedAICategory((prev) => (prev === aiTitle ? null : aiTitle));
    setDroppdownVisible((prev) => !prev);
  };

  // const handleToggleItemSelection = (aiTitle) => {
  //   setSelectedDropdownItems((prevItems) => {
  //     if (prevItems.includes(aiTitle)) {
  //       return prevItems.filter((item) => item !== aiTitle);
  //     } else {
  //       return [...prevItems, aiTitle];
  //     }
  //   });
  // };

  const getOtherAiList = (aiCategory) => {
    const aiItem = aiList.find((ai) => ai.title === aiCategory);
    return aiItem
      ? (aiItem.otherAiList || []).sort(
          (a, b) => new Date(b.launchedAt) - new Date(a.launchedAt)
        )
      : [];
  };

  {
    /* Dropdown setting End */
  }
  const checkFreeChatCount = async () => {
    const userFreeChat = await axiosInstance.post(`/freechatcount`);
    //console.log(userFreeChat?.data,"freechat---------------");
    setChatCounts({
      freeChat: userFreeChat?.data?.chatcount,
      imageCount: userFreeChat?.data?.imagecount,
      videoCount: userFreeChat?.data?.videocount,
      audioCount: userFreeChat?.data?.audiocount,
      textCount: userFreeChat?.data?.textcount,
      docCount: userFreeChat?.data?.doccount,
    });
    setChatCount(userFreeChat?.data?.chatcount);
  };
  const { freeChat, imageCount, videoCount, audioCount } = chatCounts;
  const [isChatCount, setChatCount] = useState();
  const chatbot = aiName;
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const closeMessage = () => {
    setShowMessage(false);
  };

  const closeShowPopup = () => {
    setShowPopup(false);
  };

  const [showMessage, setShowMessage] = useState(false);

  const [isChatCategoryOpen, setChatCategoryOpen] = useState(true);

  const clearChat = () => {
    // Implement the logic to clear the chat
    console.log("Clearing chat...");
    setChats([]);
  };

  // from here the code of switchtosomebody

  const handleNewRoomTwo = (status, newcat) => {
    console.log("this i handle two room status ", status);
    console.log("this i steh new cat ", newcat);
    setNewRoomStatus(status);
    if (status != false) {
      setSelectedCategory(newcat);
      setAIName("OneAIChat-Somebody");
      // Load the corresponding AI list based on the selected category
      switch (newcat) {
        case "Somebody":
          setAiList(importedAiList);
          break;
        case "Health":
          setAiList(healthAiList);
          break;
        case "Marketing":
          setAiList(marketingAiList);
          break;
        case "Video":
          setAiList(videoAiList);
          break;
        case "Science":
          setAiList(scienceAiList);
          break;
        case "Writing":
          setAiList(writingAiList);
          break;
        case "Art/Design":
          setAiList(designAiList);
          break;
        case "Coding":
          setAiList(codingAiList);
          break;
        case "Audio/Music":
          setAiList(musicAiList);
          break;
        case "Mathematics":
          setAiList(mathAiList);
          break;
        case "Faith":
          setAiList(faithAiList);
          break;
        case "Finance":
          setAiList(financeAiList);
          break;
        // Add cases for other categories as needed
        default:
          setAiList(aiList);
      }
      localStorage.setItem("selectedCategory", "Somebody");
      setAIName("OneAIChat-Somebody");
      setAiChatPopupVisible(false);
      localStorage.setItem("selectedAiCategory", "OneAIChat-Somebody");

      navigate(`/chat`, { replace: true });
    }
    setCatPopupVisible(false);
  };

  const handleCategoryClickTwo = (category, status) => {
    console.log("this is the category ", category);
    console.log("this is the status ", status);
    // Update localStorage values
    localStorage.removeItem("selectedCategory");
    localStorage.removeItem("selectedAiCategory");
    localStorage.setItem("selectedCategory", "Somebody");
    localStorage.setItem("selectedAiCategory", "OneAIChat-Somebody");
    // setCatPopupVisible(true);
    handleNewRoomTwo(true, "Somebody"); // Call the function directly
    setCategory(category);
    setAiCategory("OneAIChat-Somebody");
  };

  // till here the code for swithc to somebody
  const handleNewRoom = (status) => {
    setNewRoomStatus(status);
    if (status != false) {
      setSelectedCategory(setNewCategory);
      setAIName("OneAIChat-Somebody");
      // Load the corresponding AI list based on the selected category
      switch (setNewCategory) {
        case "Somebody":
          setAiList(importedAiList);
          break;
        case "Health":
          setAiList(healthAiList);
          break;
        case "Marketing":
          setAiList(marketingAiList);
          break;
        case "Video":
          setAiList(videoAiList);
          break;
        case "Science":
          setAiList(scienceAiList);
          break;
        case "Writing":
          setAiList(writingAiList);
          break;
        case "Art/Design":
          setAiList(designAiList);
          break;
        case "Coding":
          setAiList(codingAiList);
          break;
        case "Audio/Music":
          setAiList(musicAiList);
          break;
        case "Mathematics":
          setAiList(mathAiList);
          break;
        case "Faith":
          setAiList(faithAiList);
          break;
        case "Finance":
          setAiList(financeAiList);
          break;
        // Add cases for other categories as needed
        default:
          setAiList(aiList);
      }
      localStorage.setItem("selectedCategory", selectedCategory);
      navigate(`/chat`, { replace: true });
    }
    setCatPopupVisible(false);
  };
  const selectRef = useRef(null);
  const handleCategoryClick = (category, status) => {
    setCatPopupVisible(true);
    setCategory(category);
  };

  const [loginPrompt, setLoginPrompt] = useState(false);

  const [plans, setPlans] = useState([]);
  const [userCountry, setUserCountry] = useState(null); // Initialize userCountry state
  useEffect(() => {
    async function fetchData() {
      try {
        const country = await getUserCountry();
        setUserCountry(country); // Set userCountry state after fetching
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  const getUserCountry = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      console.log(response, "country code ipppppp");
      return response.data.country_code;
    } catch (error) {
      console.error("Error fetching user country:", error);
      return null;
    }
  };

  const getExchangeRates = async (userCountry) => {
    try {
      const response = await axios.get(
        `https://api.exchangerate-api.com/v4/latest/${userCountry}`
      );
      return response.data.rates;
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
      return null;
    }
  };

  const updatePrices = (exchangeRates) => {
    if (!exchangeRates) return [];

    const pricesInLocalCurrency = {
      USD: 1, // Default currency is USD
      EUR: exchangeRates.EUR,
      // Add more currencies as needed
    };

    const subscriptionPlans = [
      { name: "Basic", price: 10 },
      { name: "Standard", price: 20 },
      { name: "Premium", price: 30 },
    ];

    return subscriptionPlans.map((plan) => {
      const priceInLocalCurrency = plan.price * pricesInLocalCurrency["USD"];
      const formattedPrice = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: navigator.language,
      }).format(priceInLocalCurrency);
      return { ...plan, price: formattedPrice };
    });
  };
  const handlePayClick = async (e, planId, planName, planPrice, stripe_id) => {
    // Check if the user is already subscribed

    let selectedStripeId;
    // Check if the user is already subscribed

    // Extract the country code from userCountry
    const countryCode = userCountry.toLowerCase(); // Assuming userCountry is in uppercase

    // Access the corresponding property from the stripe_id object based on the country code
    if (countryCode === "us") {
      selectedStripeId = stripe_id.stripe_id_us;
    } else if (countryCode === "ca") {
      selectedStripeId = stripe_id.stripe_id_ca;
    } else if (countryCode === "gb") {
      selectedStripeId = stripe_id.stripe_id_gb || stripe_id.stripe_id_uk;
    } else if (countryCode === "fr") {
      selectedStripeId = stripe_id.stripe_id_fr;
    } else if (countryCode === "de") {
      selectedStripeId = stripe_id.stripe_id_de;
    } else if (countryCode === "ru") {
      selectedStripeId = stripe_id.stripe_id_ru;
    } else if (countryCode === "cn") {
      selectedStripeId = stripe_id.stripe_id_cn;
    } else if (countryCode === "jp") {
      selectedStripeId = stripe_id.stripe_id_jp;
    } else if (countryCode === "br") {
      selectedStripeId = stripe_id.stripe_id_br;
    } else if (countryCode === "au") {
      selectedStripeId = stripe_id.stripe_id_au;
    } else if (countryCode === "it") {
      selectedStripeId = stripe_id.stripe_id_it;
    } else if (countryCode === "kr") {
      selectedStripeId = stripe_id.stripe_id_kr;
    } else if (countryCode === "mx") {
      selectedStripeId = stripe_id.stripe_id_mx;
    } else if (countryCode === "za") {
      selectedStripeId = stripe_id.stripe_id_za;
    } else if (countryCode === "se") {
      selectedStripeId = stripe_id.stripe_id_se;
    } else if (countryCode === "pl") {
      selectedStripeId = stripe_id.stripe_id_pl;
    } else if (countryCode === "es") {
      selectedStripeId = stripe_id.stripe_id_es;
    } else if (countryCode === "ch") {
      selectedStripeId = stripe_id.stripe_id_ch;
    } else if (countryCode === "at") {
      selectedStripeId = stripe_id.stripe_id_at;
    } else if (countryCode === "be") {
      selectedStripeId = stripe_id.stripe_id_be;
    } else if (countryCode === "nl") {
      selectedStripeId = stripe_id.stripe_id_nl;
    } else if (countryCode === "no") {
      selectedStripeId = stripe_id.stripe_id_no;
    } else if (countryCode === "dk") {
      selectedStripeId = stripe_id.stripe_id_dk;
    } else if (countryCode === "fi") {
      selectedStripeId = stripe_id.stripe_id_fi;
    } else if (countryCode === "cz") {
      selectedStripeId = stripe_id.stripe_id_cz;
    } else if (countryCode === "ua") {
      selectedStripeId = stripe_id.stripe_id_ua;
    } else if (countryCode === "hk") {
      selectedStripeId = stripe_id.stripe_id_hk;
    } else if (countryCode === "ar") {
      selectedStripeId = stripe_id.stripe_id_ar;
    } else if (countryCode === "cl") {
      selectedStripeId = stripe_id.stripe_id_cl;
    } else if (countryCode === "bd") {
      selectedStripeId = stripe_id.stripe_id_bd;
    } else if (countryCode === "eg") {
      selectedStripeId = stripe_id.stripe_id_eg;
    } else if (countryCode === "ng") {
      selectedStripeId = stripe_id.stripe_id_ng;
    } else if (countryCode === "ke") {
      selectedStripeId = stripe_id.stripe_id_ke;
    } else if (countryCode === "tn") {
      selectedStripeId = stripe_id.stripe_id_tn;
    } else if (countryCode === "bh") {
      selectedStripeId = stripe_id.stripe_id_bh;
    } else if (countryCode === "ee") {
      selectedStripeId = stripe_id.stripe_id_ee;
    } else if (countryCode === "is") {
      selectedStripeId = stripe_id.stripe_id_is;
    } else if (countryCode === "cy") {
      selectedStripeId = stripe_id.stripe_id_cy;
    } else if (countryCode === "bg") {
      selectedStripeId = stripe_id.stripe_id_bg;
    } else if (countryCode === "hr") {
      selectedStripeId = stripe_id.stripe_id_hr;
    } else if (countryCode === "pt") {
      selectedStripeId = stripe_id.stripe_id_pt;
    } else if (countryCode === "lv") {
      selectedStripeId = stripe_id.stripe_id_lv;
    } else if (countryCode === "lt") {
      selectedStripeId = stripe_id.stripe_id_lt;
    } else if (countryCode === "mt") {
      selectedStripeId = stripe_id.stripe_id_mt;
    } else if (countryCode === "ro") {
      selectedStripeId = stripe_id.stripe_id_ro;
    } else if (countryCode === "si") {
      selectedStripeId = stripe_id.stripe_id_si;
    } else if (countryCode === "rs") {
      selectedStripeId = stripe_id.stripe_id_rs;
    } else if (countryCode === "mk") {
      selectedStripeId = stripe_id.stripe_id_mk;
    } else if (countryCode === "me") {
      selectedStripeId = stripe_id.stripe_id_me;
    } else if (countryCode === "ba") {
      selectedStripeId = stripe_id.stripe_id_ba;
    } else if (countryCode === "sk") {
      selectedStripeId = stripe_id.stripe_id_sk;
    } else if (countryCode === "lu") {
      selectedStripeId = stripe_id.stripe_id_lu;
    } else if (countryCode === "ad") {
      selectedStripeId = stripe_id.stripe_id_ad;
    } else if (countryCode === "am") {
      selectedStripeId = stripe_id.stripe_id_am;
    } else if (countryCode === "az") {
      selectedStripeId = stripe_id.stripe_id_az;
    } else if (countryCode === "by") {
      selectedStripeId = stripe_id.stripe_id_by;
    } else if (countryCode === "ge") {
      selectedStripeId = stripe_id.stripe_id_ge;
    } else if (countryCode === "md") {
      selectedStripeId = stripe_id.stripe_id_md;
    } else if (countryCode === "kz") {
      selectedStripeId = stripe_id.stripe_id_kz;
    } else if (countryCode === "tj") {
      selectedStripeId = stripe_id.stripe_id_tj;
    } else if (countryCode === "tm") {
      selectedStripeId = stripe_id.stripe_id_tm;
    } else if (countryCode === "kg") {
      selectedStripeId = stripe_id.stripe_id_kg;
    } else if (countryCode === "uz") {
      selectedStripeId = stripe_id.stripe_id_uz;
    } else if (countryCode === "bt") {
      selectedStripeId = stripe_id.stripe_id_bt;
    } else if (countryCode === "mv") {
      selectedStripeId = stripe_id.stripe_id_mv;
    } else if (countryCode === "kh") {
      selectedStripeId = stripe_id.stripe_id_kh;
    } else if (countryCode === "la") {
      selectedStripeId = stripe_id.stripe_id_la;
    } else if (countryCode === "mm") {
      selectedStripeId = stripe_id.stripe_id_mm;
    } else if (countryCode === "vn") {
      selectedStripeId = stripe_id.stripe_id_vn;
    } else if (countryCode === "th") {
      selectedStripeId = stripe_id.stripe_id_th;
    } else if (countryCode === "my") {
      selectedStripeId = stripe_id.stripe_id_my;
    } else if (countryCode === "sg") {
      selectedStripeId = stripe_id.stripe_id_sg;
    } else if (countryCode === "ph") {
      selectedStripeId = stripe_id.stripe_id_ph;
    } else if (countryCode === "np") {
      selectedStripeId = stripe_id.stripe_id_np;
    } else if (countryCode === "lk") {
      selectedStripeId = stripe_id.stripe_id_lk;
    } else if (countryCode === "qa") {
      selectedStripeId = stripe_id.stripe_id_qa;
    } else if (countryCode === "jo") {
      selectedStripeId = stripe_id.stripe_id_jo;
    } else if (countryCode === "om") {
      selectedStripeId = stripe_id.stripe_id_om;
    } else if (countryCode === "ye") {
      selectedStripeId = stripe_id.stripe_id_ye;
    } else if (countryCode === "il") {
      selectedStripeId = stripe_id.stripe_id_il;
    } else if (countryCode === "pk") {
      selectedStripeId = stripe_id.stripe_id_pk;
    } else if (countryCode === "kw") {
      selectedStripeId = stripe_id.stripe_id_kw;
    } else if (countryCode === "sa") {
      selectedStripeId = stripe_id.stripe_id_sa;
    } else if (countryCode === "tr") {
      selectedStripeId = stripe_id.stripe_id_tr;
    } else if (countryCode === "ae") {
      selectedStripeId = stripe_id.stripe_id_ae;
    } else if (countryCode === "af") {
      selectedStripeId = stripe_id.stripe_id_af;
    } else if (countryCode === "al") {
      selectedStripeId = stripe_id.stripe_id_al;
    } else if (countryCode === "dz") {
      selectedStripeId = stripe_id.stripe_id_dz;
    } else if (countryCode === "ao") {
      selectedStripeId = stripe_id.stripe_id_ao;
    } else if (countryCode === "ai") {
      selectedStripeId = stripe_id.stripe_id_ai;
    } else if (countryCode === "aq") {
      selectedStripeId = stripe_id.stripe_id_aq;
    } else if (countryCode === "ag") {
      selectedStripeId = stripe_id.stripe_id_ag;
    } else if (countryCode === "bs") {
      selectedStripeId = stripe_id.stripe_id_bs;
    } else if (countryCode === "bb") {
      selectedStripeId = stripe_id.stripe_id_bb;
    } else if (countryCode === "bz") {
      selectedStripeId = stripe_id.stripe_id_bz;
    } else if (countryCode === "bj") {
      selectedStripeId = stripe_id.stripe_id_bj;
    } else if (countryCode === "bm") {
      selectedStripeId = stripe_id.stripe_id_bm;
    } else if (countryCode === "bo") {
      selectedStripeId = stripe_id.stripe_id_bo;
    } else if (countryCode === "bw") {
      selectedStripeId = stripe_id.stripe_id_bw;
    } else if (countryCode === "bn") {
      selectedStripeId = stripe_id.stripe_id_bn;
    } else if (countryCode === "bf") {
      selectedStripeId = stripe_id.stripe_id_bf;
    } else if (countryCode === "bi") {
      selectedStripeId = stripe_id.stripe_id_bi;
    } else if (countryCode === "cv") {
      selectedStripeId = stripe_id.stripe_id_cv;
    } else if (countryCode === "ky") {
      selectedStripeId = stripe_id.stripe_id_ky;
    } else if (countryCode === "cf") {
      selectedStripeId = stripe_id.stripe_id_cf;
    } else if (countryCode === "td") {
      selectedStripeId = stripe_id.stripe_id_td;
    } else if (countryCode === "co") {
      selectedStripeId = stripe_id.stripe_id_co;
    } else if (countryCode === "km") {
      selectedStripeId = stripe_id.stripe_id_km;
    } else if (countryCode === "cg") {
      selectedStripeId = stripe_id.stripe_id_cg;
    } else if (countryCode === "cr") {
      selectedStripeId = stripe_id.stripe_id_cr;
    } else if (countryCode === "ci") {
      selectedStripeId = stripe_id.stripe_id_ci;
    } else if (countryCode === "ga") {
      selectedStripeId = stripe_id.stripe_id_ga;
    } else if (countryCode === "gm") {
      selectedStripeId = stripe_id.stripe_id_gm;
    } else if (countryCode === "gh") {
      selectedStripeId = stripe_id.stripe_id_gh;
    } else if (countryCode === "gr") {
      selectedStripeId = stripe_id.stripe_id_gr;
    } else if (countryCode === "gd") {
      selectedStripeId = stripe_id.stripe_id_gd;
    } else if (countryCode === "gu") {
      selectedStripeId = stripe_id.stripe_id_gu;
    } else if (countryCode === "gt") {
      selectedStripeId = stripe_id.stripe_id_gt;
    } else if (countryCode === "gn") {
      selectedStripeId = stripe_id.stripe_id_gn;
    } else if (countryCode === "gw") {
      selectedStripeId = stripe_id.stripe_id_gw;
    } else if (countryCode === "gy") {
      selectedStripeId = stripe_id.stripe_id_gy;
    } else if (countryCode === "ht") {
      selectedStripeId = stripe_id.stripe_id_ht;
    } else if (countryCode === "hn") {
      selectedStripeId = stripe_id.stripe_id_hn;
    } else if (countryCode === "hu") {
      selectedStripeId = stripe_id.stripe_id_hu;
    } else if (countryCode === "dj") {
      selectedStripeId = stripe_id.stripe_id_dj;
    } else if (countryCode === "dm") {
      selectedStripeId = stripe_id.stripe_id_dm;
    } else if (countryCode === "do") {
      selectedStripeId = stripe_id.stripe_id_do;
    } else if (countryCode === "sv") {
      selectedStripeId = stripe_id.stripe_id_sv;
    } else if (countryCode === "gq") {
      selectedStripeId = stripe_id.stripe_id_gq;
    } else if (countryCode === "er") {
      selectedStripeId = stripe_id.stripe_id_er;
    } else if (countryCode === "sz") {
      selectedStripeId = stripe_id.stripe_id_sz;
    } else if (countryCode === "et") {
      selectedStripeId = stripe_id.stripe_id_et;
    } else if (countryCode === "fj") {
      selectedStripeId = stripe_id.stripe_id_fj;
    } else if (countryCode === "id") {
      selectedStripeId = stripe_id.stripe_id_id;
    } else if (countryCode === "iq") {
      selectedStripeId = stripe_id.stripe_id_iq;
    } else if (countryCode === "ie") {
      selectedStripeId = stripe_id.stripe_id_ie;
    } else if (countryCode === "jm") {
      selectedStripeId = stripe_id.stripe_id_jm;
    } else if (countryCode === "ki") {
      selectedStripeId = stripe_id.stripe_id_ki;
    } else if (countryCode === "lb") {
      selectedStripeId = stripe_id.stripe_id_lb;
    } else if (countryCode === "ls") {
      selectedStripeId = stripe_id.stripe_id_ls;
    } else if (countryCode === "cm") {
      selectedStripeId = stripe_id.stripe_id_cm;
    } else if (countryCode === "lr") {
      selectedStripeId = stripe_id.stripe_id_lr;
    } else if (countryCode === "ly") {
      selectedStripeId = stripe_id.stripe_id_ly;
    } else if (countryCode === "li") {
      selectedStripeId = stripe_id.stripe_id_li;
    } else if (countryCode === "mg") {
      selectedStripeId = stripe_id.stripe_id_mg;
    } else if (countryCode === "mw") {
      selectedStripeId = stripe_id.stripe_id_mw;
    } else if (countryCode === "ml") {
      selectedStripeId = stripe_id.stripe_id_ml;
    } else if (countryCode === "mr") {
      selectedStripeId = stripe_id.stripe_id_mr;
    } else if (countryCode === "mu") {
      selectedStripeId = stripe_id.stripe_id_mu;
    } else if (countryCode === "yt") {
      selectedStripeId = stripe_id.stripe_id_yt;
    } else if (countryCode === "mc") {
      selectedStripeId = stripe_id.stripe_id_mc;
    } else if (countryCode === "mn") {
      selectedStripeId = stripe_id.stripe_id_mn;
    } else if (countryCode === "ms") {
      selectedStripeId = stripe_id.stripe_id_ms;
    } else if (countryCode === "ma") {
      selectedStripeId = stripe_id.stripe_id_ma;
    } else if (countryCode === "mz") {
      selectedStripeId = stripe_id.stripe_id_mz;
    } else if (countryCode === "na") {
      selectedStripeId = stripe_id.stripe_id_na;
    } else if (countryCode === "nr") {
      selectedStripeId = stripe_id.stripe_id_nr;
    } else if (countryCode === "nc") {
      selectedStripeId = stripe_id.stripe_id_nc;
    } else if (countryCode === "nz") {
      selectedStripeId = stripe_id.stripe_id_nz;
    } else if (countryCode === "ni") {
      selectedStripeId = stripe_id.stripe_id_ni;
    } else if (countryCode === "ne") {
      selectedStripeId = stripe_id.stripe_id_ne;
    } else if (countryCode === "pw") {
      selectedStripeId = stripe_id.stripe_id_pw;
    } else if (countryCode === "pa") {
      selectedStripeId = stripe_id.stripe_id_pa;
    } else if (countryCode === "pg") {
      selectedStripeId = stripe_id.stripe_id_pg;
    } else if (countryCode === "py") {
      selectedStripeId = stripe_id.stripe_id_py;
    } else if (countryCode === "pe") {
      selectedStripeId = stripe_id.stripe_id_pe;
    } else if (countryCode === "pr") {
      selectedStripeId = stripe_id.stripe_id_pr;
    } else if (countryCode === "re") {
      selectedStripeId = stripe_id.stripe_id_re;
    } else if (countryCode === "rw") {
      selectedStripeId = stripe_id.stripe_id_rw;
    } else if (countryCode === "st") {
      selectedStripeId = stripe_id.stripe_id_st;
    } else if (countryCode === "sn") {
      selectedStripeId = stripe_id.stripe_id_sn;
    } else if (countryCode === "sc") {
      selectedStripeId = stripe_id.stripe_id_sc;
    } else if (countryCode === "sl") {
      selectedStripeId = stripe_id.stripe_id_sl;
    } else if (countryCode === "so") {
      selectedStripeId = stripe_id.stripe_id_so;
    } else if (countryCode === "ss") {
      selectedStripeId = stripe_id.stripe_id_ss;
    } else if (countryCode === "sd") {
      selectedStripeId = stripe_id.stripe_id_sd;
    } else if (countryCode === "sr") {
      selectedStripeId = stripe_id.stripe_id_sr;
    } else if (countryCode === "sj") {
      selectedStripeId = stripe_id.stripe_id_sj;
    } else if (countryCode === "tw") {
      selectedStripeId = stripe_id.stripe_id_tw;
    } else if (countryCode === "tz") {
      selectedStripeId = stripe_id.stripe_id_tz;
    } else if (countryCode === "tl") {
      selectedStripeId = stripe_id.stripe_id_tl;
    } else if (countryCode === "tg") {
      selectedStripeId = stripe_id.stripe_id_tg;
    } else if (countryCode === "to") {
      selectedStripeId = stripe_id.stripe_id_to;
    } else if (countryCode === "tt") {
      selectedStripeId = stripe_id.stripe_id_tt;
    } else if (countryCode === "tc") {
      selectedStripeId = stripe_id.stripe_id_tc;
    } else if (countryCode === "tv") {
      selectedStripeId = stripe_id.stripe_id_tv;
    } else if (countryCode === "ug") {
      selectedStripeId = stripe_id.stripe_id_ug;
    } else if (countryCode === "uy") {
      selectedStripeId = stripe_id.stripe_id_uy;
    } else if (countryCode === "vu") {
      selectedStripeId = stripe_id.stripe_id_vu;
    } else if (countryCode === "ve") {
      selectedStripeId = stripe_id.stripe_id_ve;
    } else if (countryCode === "wf") {
      selectedStripeId = stripe_id.stripe_id_wf;
    } else if (countryCode === "eh") {
      selectedStripeId = stripe_id.stripe_id_eh;
    } else if (countryCode === "zm") {
      selectedStripeId = stripe_id.stripe_id_zm;
    } else if (countryCode === "zw") {
      selectedStripeId = stripe_id.stripe_id_zw;
    }
    // from her eteh extra countries are added
    else if (countryCode === "ax") {
      selectedStripeId = stripe_id.stripe_id_ax;
    } else if (countryCode === "aw") {
      selectedStripeId = stripe_id.stripe_id_aw;
    } else if (countryCode === "ac") {
      selectedStripeId = stripe_id.stripe_id_ac;
    } else if (countryCode === "bv") {
      selectedStripeId = stripe_id.stripe_id_bv;
    } else if (countryCode === "io") {
      selectedStripeId = stripe_id.stripe_id_io;
    } else if (countryCode === "vg") {
      selectedStripeId = stripe_id.stripe_id_vg;
    } else if (countryCode === "bq") {
      selectedStripeId = stripe_id.stripe_id_bq;
    } else if (countryCode === "cd") {
      selectedStripeId = stripe_id.stripe_id_cd;
    } else if (countryCode === "ck") {
      selectedStripeId = stripe_id.stripe_id_ck;
    } else if (countryCode === "cw") {
      selectedStripeId = stripe_id.stripe_id_cw;
    } else if (countryCode === "fk") {
      selectedStripeId = stripe_id.stripe_id_fk;
    } else if (countryCode === "fo") {
      selectedStripeId = stripe_id.stripe_id_fo;
    } else if (countryCode === "gf") {
      selectedStripeId = stripe_id.stripe_id_gf;
    } else if (countryCode === "pf") {
      selectedStripeId = stripe_id.stripe_id_pf;
    } else if (countryCode === "tf") {
      selectedStripeId = stripe_id.stripe_id_tf;
    } else if (countryCode === "gi") {
      selectedStripeId = stripe_id.stripe_id_gi;
    } else if (countryCode === "gl") {
      selectedStripeId = stripe_id.stripe_id_gl;
    } else if (countryCode === "gp") {
      selectedStripeId = stripe_id.stripe_id_gp;
    } else if (countryCode === "gg") {
      selectedStripeId = stripe_id.stripe_id_gg;
    } else if (countryCode === "im") {
      selectedStripeId = stripe_id.stripe_id_im;
    } else if (countryCode === "je") {
      selectedStripeId = stripe_id.stripe_id_je;
    } else if (countryCode === "xk") {
      selectedStripeId = stripe_id.stripe_id_xk;
    } else if (countryCode === "mo") {
      selectedStripeId = stripe_id.stripe_id_mo;
    } else if (countryCode === "mq") {
      selectedStripeId = stripe_id.stripe_id_mq;
    } else if (countryCode === "nu") {
      selectedStripeId = stripe_id.stripe_id_nu;
    } else if (countryCode === "ps") {
      selectedStripeId = stripe_id.stripe_id_ps;
    } else if (countryCode === "pn") {
      selectedStripeId = stripe_id.stripe_id_pn;
    } else if (countryCode === "ws") {
      selectedStripeId = stripe_id.stripe_id_ws;
    } else if (countryCode === "sm") {
      selectedStripeId = stripe_id.stripe_id_sm;
    } else if (countryCode === "sx") {
      selectedStripeId = stripe_id.stripe_id_sx;
    } else if (countryCode === "sb") {
      selectedStripeId = stripe_id.stripe_id_sb;
    } else if (countryCode === "gs") {
      selectedStripeId = stripe_id.stripe_id_gs;
    } else if (countryCode === "bl") {
      selectedStripeId = stripe_id.stripe_id_bl;
    } else if (countryCode === "sh") {
      selectedStripeId = stripe_id.stripe_id_sh;
    } else if (countryCode === "kn") {
      selectedStripeId = stripe_id.stripe_id_kn;
    } else if (countryCode === "lc") {
      selectedStripeId = stripe_id.stripe_id_lc;
    } else if (countryCode === "mf") {
      selectedStripeId = stripe_id.stripe_id_mf;
    } else if (countryCode === "pm") {
      selectedStripeId = stripe_id.stripe_id_pm;
    } else if (countryCode === "vc") {
      selectedStripeId = stripe_id.stripe_id_vc;
    } else if (countryCode === "tk") {
      selectedStripeId = stripe_id.stripe_id_tk;
    } else if (countryCode === "ta") {
      selectedStripeId = stripe_id.stripe_id_ta;
    } else if (countryCode === "va") {
      selectedStripeId = stripe_id.stripe_id_va;
    } else {
      selectedStripeId = stripe_id.stripe_id; // Fallback to a default stripe id
    }

    console.log("Selected Stripe ID:", selectedStripeId);
    console.log("stripe_id ", stripe_id);
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    try {
      const subscriptionCheckResponse = await axiosInstance.post(
        "/check-user-subscription",
        { user_id: authUser?._id }
      );

      if (subscriptionCheckResponse?.data?.hasSubscription) {
        setSubscriptionPrompt(true); // Show the subscription prompt message
        setShowMessage(true);

        return;
      }

      // User is not subscribed, proceed with payment
      const isPurchaseCompleted = localStorage.getItem("purchaseCompleted");
      if (!isPurchaseCompleted) {
        localStorage.setItem("stripeId", selectedStripeId);
        localStorage.setItem("selectedPlanName", planName);
        localStorage.setItem("selectedPlanId", planId);
        localStorage.setItem("selectedPlanPrice", planPrice);
      }

      if (!authUser) {
        console.error("AuthUser not found in local storage");
        setLoginPrompt(true); // Show the login prompt message
        setShowMessage(true);
        e.preventDefault(); // Prevent form submission
        setTimeout(() => {
          window.location.href = "/login"; // Redirect to login page after 3 seconds
        }, 1000);
        return;
      } else {
        // Open the modal when pay button is clicked
        setShowPopup(true);
        setSelectedPaymentOption(null); // Reset selected payment option
      }
    } catch (error) {
      console.error("Error checking user subscription:", error);
      // Handle the error (show a message, log, etc.)
    }
  };

  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handlePaymentOptionClick = (paymentOption) => {
    setSelectedPaymentOption(paymentOption);
  };

  const handleProceed = () => {
    console.log("this is the handle proceed");
    console.log("this is the selected payment options ", selectedPaymentOption);
    if (selectedPaymentOption === "Stripe") {
      handleStripePayment();
    }

    //from
    else if (selectedPaymentOption === "One-Time Payment") {
      handleBilldeskPayment("One-Time Payment");
    } else if (selectedPaymentOption === "Recurring Payment") {
      handleBilldeskPayment("Recurring Payment");
    }
    //till
    else if (selectedPaymentOption === "PhonePe") {
      handlePhonePePayment();
    }
  };

  const handlePhonePePayment = async () => {
    try {
      const selectedPlanId = localStorage.getItem("selectedPlanId");
      const selectedPlanName = localStorage.getItem("selectedPlanName");
      const selectedPlanPrice = localStorage.getItem("selectedPlanPrice");
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const email = authUser.email;
      const userid = authUser._id;
      const url = `https://db.oneaichat.com/pay?planId=${selectedPlanId}&planName=${selectedPlanName}&planPrice=${selectedPlanPrice}&email=${email}&userid=${userid}`;
      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`Error initiating payment: ${response.statusText}`);
      }

      const responseData = await response.json();

      // Check if responseData has the expected structure
      if (responseData && responseData.redirectUrl) {
        window.location.href = responseData.redirectUrl;
      } else {
        console.error("Unexpected response format:", responseData);
        // Handle unexpected response format, show a message, or redirect to an error page
      }
    } catch (error) {
      console.error("Payment initiation error:", error);
      // Handle the error, show a message, or redirect to an error page
    }
  };

  {
    /*Billdesk starts  */
  }
  //   const [amount, setAmount] = useState('');
  //   const [orderId, setOrderId] = useState('');
  //   const [customerName, setCustomerName] = useState('');
  //   const [customerEmail, setCustomerEmail] = useState('');

  //  const handleBilldeskPayment = async () => {
  //   const selectedPlanId = localStorage.getItem('selectedPlanId');
  //   const selectedPlanName = localStorage.getItem('selectedPlanName');
  //   const selectedPlanPrice = localStorage.getItem('selectedPlanPrice');
  //   const authUserString = localStorage.getItem('authUser');

  //   const authUser = authUserString ? JSON.parse(authUserString) : null;

  //   // Access user information from the authUser object
  //   const userEmail = authUser.email;
  //   const userid=authUser._id;
  //  console.log(userid)
  //   try {
  //     const response = await fetch(`https://db.oneaichat.com/api/initiate-payment`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({selectedPlanPrice: parseFloat(selectedPlanPrice), orderId, customerName, customerEmail: userEmail, selectedPlanId: selectedPlanId,  selectedPlanName: selectedPlanName,userid:userid }),
  //     });

  //     const result = await response.json();

  //     const authToken = result.flowConfig.authToken;
  //     const bdOrderId = result.flowConfig.bdOrderId;

  //     const flow_config = {
  //       merchantId: "KALETECHPL",
  //       bdOrderId: bdOrderId,
  //       authToken: authToken,
  //       childWindow: false,
  //       returnUrl: "https://db.oneaichat.com/api/callback",
  //       retryCount: 0
  //     };
  //     var responseHandler = function(txn) {
  //       if (txn.response) {
  //           alert("callback received status:: ", txn.status);
  //           alert("callback received response:: ", txn.response)//response handler to be implemented by the merchant
  //         }
  //         console.log("txn.response"+txn.response)
  //       };
  //     const config = {
  //       responseHandler: responseHandler,

  //       flowConfig: flow_config,
  //       flowType: "payments"
  //     };

  //     window.loadBillDeskSdk(config);

  //     console.log("loadinggg");
  //   } catch (error) {
  //     console.error('Error initiating payment:', error);
  //     // alert('Internal server error. Please try again later.');
  //   }
  // }
  {
    /*Billdesk ends */
  }

  {
    /*Billdesk starts  */
  }
  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");

  const handleBilldeskPayment = async (type) => {
    const selectedPlanId = localStorage.getItem("selectedPlanId");
    const selectedPlanName = localStorage.getItem("selectedPlanName");
    const selectedPlanPrice = localStorage.getItem("selectedPlanPrice");
    const authUserString = localStorage.getItem("authUser");

    const authUser = authUserString ? JSON.parse(authUserString) : null;
    let routeUrl;
    if (type === "One-Time Payment") {
      routeUrl = `https://db.oneaichat.com/api/initiate-payment`;
    }

    if (type === "Recurring Payment") {
      routeUrl = `https://db.oneaichat.com/api/initiate-recurring-payment`;
    }

    if (type === "Stop Recurring Payment") {
      routeUrl = `https://db.oneaichat.com/api/stop-recurring`;
    }
    // Access user information from the authUser object
    const userEmail = authUser.email;
    const userid = authUser._id;
    console.log(userid);

    if (type === "One-Time Payment") {
      try {
        const response = await fetch(routeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPlanPrice: parseFloat(selectedPlanPrice),
            orderId,
            customerName,
            customerEmail: userEmail,
            selectedPlanId: selectedPlanId,
            selectedPlanName: selectedPlanName,
            userid: userid,
          }),
        });

        const result = await response.json();

        const authToken = result.flowConfig.authToken;
        const bdOrderId = result.flowConfig.bdOrderId;
        const MerchantID = result.flowConfig.merchantId;

        const flow_config = {
          merchantId: MerchantID,
          bdOrderId: bdOrderId,
          authToken: authToken,
          childWindow: false,
          returnUrl: "https://db.oneaichat.com/api/callback",
          retryCount: 0,
        };
        var responseHandler = function (txn) {
          if (txn.response) {
            alert("callback received status:: ", txn.status);
            alert("callback received response:: ", txn.response); //response handler to be implemented by the merchant
          }
          console.log("txn.response" + txn.response);
        };
        const config = {
          responseHandler: responseHandler,

          flowConfig: flow_config,
          flowType: "payments",
        };

        window.loadBillDeskSdk(config);

        console.log("loadinggg");
      } catch (error) {
        console.error("Error initiating payment:", error);
        // alert('Internal server error. Please try again later.');
      }
    }

    if (type === "Recurring Payment") {
      try {
        const response = await fetch(routeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPlanPrice: parseFloat(selectedPlanPrice),
            orderId,
            customerName,
            customerEmail: userEmail,
            selectedPlanId: selectedPlanId,
            selectedPlanName: selectedPlanName,
            userid: userid,
          }),
        });

        const result = await response.json();

        console.log("this is the result ");
        console.log(result);

        const authToken = result.flowConfig.authToken;
        const bdOrderId = result.flowConfig.bdOrderId;
        const MerchantID = result.flowConfig.merchantId;

        const flow_config = {
          merchantId: MerchantID,
          bdOrderId: bdOrderId,
          authToken: authToken,
          childWindow: false,
          returnUrl: "https://db.oneaichat.com/api/callback",
          // crossButtonHandling: "Y",
          retryCount: 0,
          prefs: {
            payment_categories: ["card", "nb", "upi"],
            allowed_bins: ["459150", "525211", "540000"],
          },
        };

        var responseHandler = function (txn) {
          if (txn.response) {
            alert("callback received status:: ", txn.status);
            alert("callback received response:: ", txn.response);
          }
        };

        var config = {
          responseHandler: responseHandler,
          flowConfig: flow_config,
          flowType: "payments",
        };

        window.loadBillDeskSdk(config);

        console.log("loadinggg");
      } catch (error) {
        console.error("Error initiating payment:", error);
        // alert('Internal server error. Please try again later.');
      }
    }

    if (type === "Stop Recurring Payment") {
      try {
        const response = await fetch(routeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPlanPrice: parseFloat(selectedPlanPrice),
            orderId,
            customerName,
            customerEmail: userEmail,
            selectedPlanId: selectedPlanId,
            selectedPlanName: selectedPlanName,
            userid: userid,
          }),
        });

        const result = await response.json();

        console.log("this is the result ");
        console.log(result);

        const authToken = result.flowConfig.authToken;
        const mandateTokenId = result.flowConfig.mandateTokenId;
        const MerchantID = result.flowConfig.merchantId;

        var flow_config = {
          merchantId: MerchantID,
          mandateTokenId: mandateTokenId,

          authToken: authToken,
          childWindow: true,
          returnUrl: "https://db.oneaichat.com/api/stop-callback",
          // crossButtonHandling: "Y",
          retryCount: 3,
        };
        console.log("this is the flow consig on front end", flow_config);

        var responseHandler = function (txn) {
          if (txn.response) {
            alert("callback received status:: ", txn.status);
            alert("callback received response:: ", txn.response);
          }
        };

        var config = {
          responseHandler: responseHandler,
          flowConfig: flow_config,
          flowType: "modify_mandate",
        };

        window.loadBillDeskSdk(config);

        console.log("loadinggg");
      } catch (error) {
        console.error("Error initiating payment:", error);
        // alert('Internal server error. Please try again later.');
      }
    }
  };
  {
    /*Billdesk ends */
  }

  const handleStripePayment = () => {
    const stripeId = localStorage.getItem("stripeId");

    const form = document.createElement("form");
    form.action = "https://stripe.oneaichat.com/create-checkout-session";
    form.method = "POST";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "stripeId";
    input.value = stripeId;

    form.appendChild(input);

    document.body.appendChild(form);

    form.submit();
  };
  // const aiNameHandler = (name, status) => {
  //   setAiChatPopupVisible(status)
  //   setAiCategory(isAiCategory);
  // };
  // // console.log(isAiCategory, "isAiCategory");
  // const handleNewchatBot = (status) => {
  //   if(status !=false){
  //     setAIName(isAiCategory);
  //     setAiChatPopupVisible(false);
  //   }
  //   else{
  //     setAiChatPopupVisible(false);
  //   }
  // }

  const aiNameHandler = (name, status) => {
    console.log("Clicked AI Name:", name);
    console.log("Popup Status:", status);
    setAiChatPopupVisible(status);
    setAiCategory(name);
  };
  const handleNewchatBot = (status) => {
    if (status != false) {
      setAIName(isAiCategory);
      setAiChatPopupVisible(false);
      localStorage.setItem("selectedAiCategory", isAiCategory);

      navigate(`/chat`, { replace: true });
    } else {
      setAiChatPopupVisible(false);
    }
  };

  let currentauthUser = "";
  const authUserString = localStorage.getItem("authUser");
  if (authUserString) {
    currentauthUser = JSON.parse(authUserString);
  }
  const { data: countOfRooms } = useRoomCountOfAi(currentauthUser._id, aiName);
  const checkUserSubscription = async () => {
    const userSubscriptionResponse = await axiosInstance.post(
      `/check-user-subscription`,
      { user_id: currentauthUser._id }
    );
    const userFreeChat = await axiosInstance.post(`/freechatcount`);
    setChatCounts({
      freeChat: userFreeChat?.data?.chatcount,
      imageCount: userFreeChat?.data?.imagecount,
      videoCount: userFreeChat?.data?.videocount,
      audioCount: userFreeChat?.data?.audiocount,
      textCount: userFreeChat?.data?.textcount,
      docCount: userFreeChat?.data?.doccount,
    });
    setChatCount(userFreeChat?.data?.chatcount);
    console.log(userSubscriptionResponse, "subcribed-------------");
    if (
      userSubscriptionResponse?.data?.hasSubscription &&
      userSubscriptionResponse?.data?.content?.payment_status != "pending"
    ) {
      console.log(userSubscriptionResponse, "userSubscriptionResponse");
      console.log(userSubscriptionResponse?.data?.hasSubscription);
      console.log(userSubscriptionResponse?.data?.content?.payment_status);
      console.log(isSubscribed, "Before setting isSubscribed");
      setSubscribed(true);
      console.log(isSubscribed, "After setting isSubscribed");
    }
  };

  const openRoombar = (name) => {
    if (selectedChatbot === name && showRoombar) {
      setShowRoombar(false);
    } else {
      setSelectedChatbot(name);
      setShowRoombar(true);
    }
  };

  const closeRoombar = (name) => {
    setShowRoombar(false);
  };

  const [activeNavItem, setActiveNavItem] = useState(""); // default to 'profile' or null

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isCategoryDropdownVisible, setCategoryDropdownVisible] =
    useState(false);
  const togggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleCategoryDropdown = () => {
    setCategoryDropdownVisible(!isCategoryDropdownVisible);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  const handleBlur = () => {
    setCategoryDropdownVisible(false);
  };

  const toggleRoombar = () => {
    setShowRoombar(!showRoombar);
  };

  const allOtherAiList = [];
  aiList.forEach((ai) => {
    // Add parent object to allOtherAiList
    allOtherAiList.push({
      id: ai.id,
      title: ai.title,
      subTitle: ai.subTitle,
      icon: ai.icon,
    });
    if (Array.isArray(ai.otherAiList)) {
      // Append the items from otherAiList to the allOtherAiList array
      ai.otherAiList.forEach((subAi) => {
        allOtherAiList.push(subAi);
      });
    }
  });
  const searchHandle = () => {
    // console.log("AI List", aiList);
    setSearchAiList([]);
    // console.log("inputSearch:", inputSearch); // Check the value of inputSearch
    // console.log("allother list", allOtherAiList);
    // console.log("hiii");
    // console.log("searchlist", searchAiList);

    const regex = new RegExp(`\\b${inputSearch}\\b`, "i");

    let filteredData = allOtherAiList.filter((res) => {
      let a = regex.test(res.title);
      let b = res.title.toLowerCase().startsWith(inputSearch.toLowerCase());

      if (inputSearch.toLowerCase() === "chat") {
        return res.title.toLowerCase().includes("chat");
      }
      if (inputSearch.toLowerCase() === "cla") {
        return res.title.toLowerCase().includes("cla");
      }
      if (inputSearch.toLowerCase() === "gem") {
        return res.title.toLowerCase().includes("gem");
      }

      if (inputSearch.toLowerCase() === "com") {
        return res.title.toLowerCase().includes("com");
      }
      if (inputSearch.toLowerCase() === "one") {
        return res.title.toLowerCase().includes("one");
      }

      if (inputSearch.toLowerCase() === "goo") {
        return res.title.toLowerCase().includes("goo");
      }

      if (inputSearch.toLowerCase() === "sta") {
        return res.title.toLowerCase().includes("sta");
      }

      if (inputSearch.toLowerCase() === "dal") {
        return res.title.toLowerCase().includes("dal");
      }

      if (b && inputSearch.length === 1) {
        return res.title.toLowerCase().startsWith(inputSearch.toLowerCase());
      } else {
        return regex.test(res.title);
      }
    });

    // console.log("Filtered Data:", filteredData); // Log the filtered data
    setSearchAiList(filteredData);
    // console.log("updated searchlist", searchAiList);
    filteredData = [];
    // console.log("Filter empty", filteredData);
    // Further processing or updating state can be done here
  };

  useEffect(() => {
    if (inputSearch !== "") {
      setSearchAiList([]);
      searchHandle();
    } else {
      setSearchAiList([]);
      // console.log("else searchlist",searchAiList)
    }
  }, [inputSearch]);

  // from here the stretchable sidebar

  const [sidebarWidth, setSidebarWidth] = useState(320); // Initial width
  const handleMouseDown = (e) => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    // Prevent text selection
    document.body.style.userSelect = "none";
  };
  const handleMouseMove = (e) => {
    let newWidth = e.clientX; // Assuming 1rem = 10px for this calculation
    // alert(newWidth)
    if (newWidth < 200) newWidth = 200; // Minimum width
    if (newWidth > 600) newWidth = 600; // Maximum width
    console.log("this is new width", newWidth);
    setSidebarWidth(newWidth);
  };
  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    // Allow text selection again
    document.body.style.userSelect = "auto";
  };

  // till here stretchable sidebar code

  return (
    <div>
      {/* <Helmet>
        <link rel="canonical" href="https://oneaichat.com/chat" />
      </Helmet> */}
      <Helmet>
        <title>
          All-in-One AI Platform | Generative AI Solutions | OneAIChat
        </title>
        <meta
          name="google-site-verification"
          content="TEb40LI-LMfGmAhUaRi9pVUoltbyYCM20BZJF5O_nk8"
        />

        <meta
          name="description"
          content="Boost productivity with OneAIChat – the all-in-one AI platform for chat, automation, and insights. Streamline workflows & enhance efficiency. Try now!"
        />
        <meta
          name="keywords"
          content="AI chatbot, Ai Chat online, AI Chat Website, Free Ai Chat Online, Free Chatbot Website, Ai Chatbot Website, Free AI Chatbot Online, Artificial Intelligence Chatbot, Free Artificial Intelligence Chatbot Online, Chat With AI, Top AI Chatbot, Best Chatbot website, Online Chatbot Website, AI Aggregator"
        />
        <link rel="canonical" href="https://oneaichat.com/chat" />
      </Helmet>
      <Chatheader />
      {/* Side bar */}

      <div className={styles.wrapper}>
        {/* Chat AI List */}

        {/* from here the stretchable sidebar code  */}

        <div className={styles.tools} style={{ width: `${sidebarWidth}rem` }}>
          <div
            style={{
              width: "20px",
              height: "100%",
              background: "transparent",
              cursor: "ew-resize",
              position: "absolute",
              top: "0%",
              right: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "large",
              borderRight: "5px solid grey",
              display: window.innerWidth <= 768 ? "none" : "block",
            }}
            onMouseDown={handleMouseDown}
          ></div>

          {isStopRecurringButton === "active" && (
            <div class="container my-1">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => handleBilldeskPayment("Stop Recurring Payment")}
              >
                Stop Recurring Payment
              </button>
            </div>
          )}
          <div className={styles.search}>
            {/* till here the stretchable side bar code is  */}

            {/* <div className={styles.textboxWrapper}>
              <input
                type="text"
                placeholder="Search for Bots"
                className={styles.textbox}
                id="search-bots"
              />
              <label htmlFor="search-bots">
                <i className="fa fa-search" />
              </label>
            </div> */}

            <div className={styles.textboxWrapper}>
              <input
                type="text"
                placeholder="Search for Bots"
                className={styles.textbox}
                id="search-bots"
                value={inputSearch}
                onChange={(e) => {
                  setInputSearch(e.target.value);
                  // searchHandle(); // Call the searchHandle function
                  // alert(inputSearch)
                }}
              />
              <label htmlFor="search-bots" onClick={searchHandle}>
                <i className="fa fa-search" />
              </label>
            </div>
          </div>

          {/* ... (other JSX) */}

          {/* Chat tools */}
          <div>
            <select
              className={styles.dropdown}
              value={aiName} // Assuming aiName is the selected value
              onChange={(e) => aiNameHandler(e.target.value, true)}
              disabled={!isSubscribed ? true : false}
            >
              <option value="" disabled>
                Select an chatbot
              </option>
              {aiList?.map((ai) => (
                <option
                  value={ai?.title}
                  key={ai?.id}
                  className={`${styles.aiCategory} ${
                    aiName === ai?.title ? styles.active : ""
                  }`}
                >
                  {ai?.title}
                </option>
              ))}
            </select>

            <div className={styles.chatsTools}>
              {inputSearch.trim() == "" && searchAiList.length == 0 ? (
                aiList?.map((ai) => {
                  const isDropdownItem = [
                    "Claude-2.0",
                    "Mistral-7b",
                    "GPT-3.5-Turbo-0613",
                    "Stable Diffusion Core",
                    "Dalle-3- HD-1024 * 1024",
                    "Ideogram-V2-Turbo",
                    "Command-R",
                    "Gemini-1.0-pro",
                  ].includes(ai?.title);
                  const isActive =
                    aiName === ai?.title ||
                    selectedDropdownItems.includes(ai?.title);
                  return (
                    <div key={ai?.id}>
                      {isDropdownItem ? (
                        <div>
                          <div
                            className={`${styles.aiCategory} ${
                              aiName === ai?.title ? styles.active : ""
                            }`}
                            onClick={() => {
                              if (aiName !== ai?.title) {
                                !isSubscribed
                                  ? setPopupVisible(true)
                                  : aiNameHandler(ai?.title, true);
                              }
                            }}
                            onMouseEnter={() => {
                              handleToggleDropdown(ai?.title);
                              console.log("this is my ai", ai);
                            }}
                            // onMouseLeave={() => handleToggleDropdown(ai?.title)}
                            style={{
                              cursor: !isSubscribed ? "not-allowed" : "pointer",
                            }}
                          >
                            <div className={styles.aiLogoWrapper}>
                              {ai?.icon}
                            </div>
                            <div className={styles.aiToolInfo}>
                              <div className={styles.aiTitle}>{ai?.title}</div>
                              <div className={styles.aiSubTitle}>
                                {ai?.subTitle}
                              </div>
                            </div>
                            <div className={styles.dropdownIcon}>
                              <i
                                className={`fa-solid fa-angle-${
                                  selectedAICategory === ai?.title
                                    ? "up"
                                    : "down"
                                } `}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleToggleDropdown(ai?.title);
                                }}
                              ></i>
                            </div>
                          </div>
                          <Collapse in={selectedAICategory === ai?.title}>
                            <div>
                              {getOtherAiList(ai?.title).map((otherAI) => (
                                <div
                                  key={otherAI.id}
                                  className={`${styles.aiCategory} ${
                                    aiName === otherAI.title
                                      ? styles.active
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (aiName !== otherAI.title) {
                                      !isSubscribed
                                        ? setPopupVisible(true)
                                        : aiNameHandler(otherAI.title, true);
                                    }
                                  }}
                                >
                                  <div className={styles.aiLogoWrapper}>
                                    {otherAI.icon}
                                  </div>
                                  <div className={styles.aiToolInfo}>
                                    <div className={styles.aiTitle}>
                                      {otherAI.title}
                                    </div>
                                    <div className={styles.aiSubTitle}>
                                      {otherAI.subTitle}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Collapse>
                        </div>
                      ) : (
                        <div
                          className={`${styles.aiCategory} ${
                            aiName === ai?.title ? styles.active : ""
                          }`}
                          onClick={() => {
                            if (aiName !== ai?.title) {
                              !isSubscribed
                                ? setPopupVisible(true)
                                : aiNameHandler(ai?.title, true);
                            }
                          }}
                          style={{
                            cursor: !isSubscribed ? "not-allowed" : "pointer",
                          }}
                        >
                          <div className={styles.aiLogoWrapper}>{ai?.icon}</div>
                          <div className={styles.aiToolInfo}>
                            <div className={styles.aiTitle}>{ai?.title}</div>
                            <div className={styles.aiSubTitle}>
                              {ai?.subTitle}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : searchAiList.length > 0 && inputSearch.length > 0 ? (
                searchAiList?.map((ai) => (
                  <React.Fragment key={ai?.id}>
                    <div
                      className={`${styles.aiCategory} ${
                        aiName === ai?.title ? styles.active : ""
                      }`}
                      onClick={() => {
                        if (aiName !== ai?.title) {
                          !isSubscribed
                            ? setPopupVisible(true)
                            : aiNameHandler(ai?.title, true);
                        }
                      }}
                      style={{
                        cursor: !isSubscribed ? "not-allowed" : "pointer",
                      }}
                    >
                      <div className={styles.aiLogoWrapper}>{ai?.icon}</div>
                      <div className={styles.aiToolInfo}>
                        <div className={styles.aiTitle}>{ai?.title}</div>
                        <div className={styles.aiSubTitle}>{ai?.subTitle}</div>
                      </div>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="text-center mt-5">Type More</div>
              )}
            </div>
          </div>
        </div>

        {/* Chat categories */}
        <div className={styles.categories}>
          <div className={styles.mobileCategoryDropdown}>
            <select
              ref={selectRef}
              className={styles.categorySelect}
              value={selectedCategory}
              onChange={(e) => handleCategoryClick(e.target.value, false)}
              onBlur={handleBlur}
            >
              <option value="" disabled>
                Select an category
              </option>

              {toolList?.map((tool) => (
                <option
                  value={tool?.name}
                  key={tool?.id}
                  className={`${styles.category} ${
                    selectedCategory === tool?.name ? styles.active : ""
                  }`}
                  disabled={
                    !isSubscribed
                      ? freeChat === null ||
                        (freeChat < 10 &&
                          (audioCount < 1 || audioCount == null) &&
                          tool?.name === "Audio/Music") ||
                        (freeChat < 10 && tool?.name === "Somebody") ||
                        (freeChat < 10 &&
                          (videoCount < 1 || videoCount == null) &&
                          tool?.name === "Video")
                        ? false
                        : true
                      : false
                  }
                >
                  {tool?.name}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.dropdownCategories}>
            <div className={styles.row}>
              {toolList?.slice(0, 6).map((tool) => (
                // <div
                //   className={`${styles.category} ${
                //     selectedCategory === tool?.name ? styles.active : ''
                //   }`}
                //   onClick={() => !isSubscribed
                //     ? (freeChat === null ||
                //         (freeChat < 10 &&
                //           (audioCount < 1 || audioCount == null) &&
                //           tool?.name === 'Audio/Music') ||
                //         (freeChat < 10 && tool?.name === 'Somebody') ||
                //         (freeChat < 10 && ( videoCount < 1 || videoCount == null) && tool?.name === 'Video'))
                //       ? handleCategoryClick(tool?.name, false)
                //       :  setPopupVisible(true)
                //     : handleCategoryClick(tool?.name, false)}
                //   key={tool?.id}
                //   style={{cursor: !isSubscribed
                //     ? (freeChat === null ||
                //         (freeChat < 10 &&
                //           (audioCount < 1 || audioCount == null) &&
                //           tool?.name === 'Audio/Music') ||
                //         (freeChat < 10 && tool?.name === 'Somebody') ||
                //         (freeChat < 10 && ( videoCount < 1 || videoCount == null) && tool?.name === 'Video'))
                //       ? ''
                //       : 'not-allowed'
                //     : ''}}
                // >

                <div
                  className={`${styles.category} ${
                    selectedCategory === tool?.name ? styles.active : ""
                  }`}
                  onClick={() => {
                    if (selectedCategory !== tool?.name) {
                      !isSubscribed
                        ? freeChat === null ||
                          (freeChat < 10 &&
                            (audioCount < 1 || audioCount == null) &&
                            tool?.name === "Audio/Music") ||
                          (freeChat < 10 && tool?.name === "Somebody") ||
                          (freeChat < 10 &&
                            (videoCount < 1 || videoCount == null) &&
                            tool?.name === "Video")
                          ? handleCategoryClick(tool?.name, false)
                          : setPopupVisible(true)
                        : handleCategoryClick(tool?.name, false);
                    }
                  }}
                  key={tool?.id}
                  style={{
                    cursor: !isSubscribed
                      ? freeChat === null ||
                        (freeChat < 10 &&
                          (audioCount < 1 || audioCount == null) &&
                          tool?.name === "Audio/Music") ||
                        (freeChat < 10 && tool?.name === "Somebody") ||
                        (freeChat < 10 &&
                          (videoCount < 1 || videoCount == null) &&
                          tool?.name === "Video")
                        ? ""
                        : "not-allowed"
                      : "",
                  }}
                >
                  <div>{tool?.icon}</div>
                  <div className={styles.toolTitle}>{tool?.name}</div>
                </div>
              ))}
            </div>
            <div className={styles.row}>
              {toolList?.slice(6, 12).map((tool) => (
                // <div
                //   className={`${styles.category} ${
                //     selectedCategory === tool?.name ? styles.active : ''
                //   }`}
                //   onClick={() => isSubscribed ? handleCategoryClick(tool?.name, false) :  setPopupVisible(true)}
                //   key={tool?.id}
                //   style={{cursor: isSubscribed ? "" : "not-allowed"}}
                // >

                <div
                  className={`${styles.category} ${
                    selectedCategory === tool?.name ? styles.active : ""
                  }`}
                  onClick={() => {
                    if (selectedCategory !== tool?.name) {
                      // console.log("this is my",selectedCategory);
                      // console.log("this is my",tool)
                      isSubscribed
                        ? handleCategoryClick(tool?.name, false)
                        : setPopupVisible(true);
                    }
                  }}
                  key={tool?.id}
                  style={{ cursor: isSubscribed ? "" : "not-allowed" }}
                >
                  <div>{tool?.icon}</div>
                  <div className={styles.toolTitle}>{tool?.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {showChatTextbox && (
          <ChatTextbox
            userId={currentauthUser._id}
            aiName={aiName}
            category={selectedCategory}
            roomStatus={isNewRoomStatus}
            isSubscribed={isSubscribed}
            chatCounts={chatCounts}
            handleCategoryClickTwo={handleCategoryClickTwo}
          />
        )}
        {showPaymentSuccess && (
          <div className="payment-success-overlay">
            <Setting />

            {/* This close button is to allow the user to close the payment success overlay */}
          </div>
        )}
        {showProfile && (
          <div className="payment-success-overlay">
            <Profile />

            {/* This close button is to allow the user to close the payment success overlay */}
          </div>
        )}
        {/* Room bar */}
        {showRoombar && (
          <Rooms
            userId={currentauthUser._id}
            ai={aiName}
            category={selectedCategory}
            onClose={closeRoombar}
          />
        )}
        {/* <Chatheader showRoombar={showRoombar} toggleRoombar={toggleRoombar}  /> */}

        {/* Popup subscription open */}

        {isPopupVisible && (
          <div className={styles.overlay}>
            <div className={styles.popup}>
              {/* <button className={styles.popp}  onClick={() => setPopupVisible(false)} >
    <i className="fas fa-times"></i>X
    </button> */}

              <button
                className={styles.popp}
                onClick={() => {
                  setShowPopup(false);
                  setPopupVisible(false);
                }}
              >
                X
              </button>

              {subscriptionPrompt && (
                <div
                  className={`subscription-box ${showMessage ? "active" : ""}`}
                >
                  <button
                    className="close-subscription-btn"
                    onClick={closeMessage}
                  >
                    &times;
                  </button>
                  User already has a subscription!
                </div>
              )}
              {subscriptions.map((subscription) => (
                <div
                  className="aai-price-table mb-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  key={subscription._id}
                >
                  <div className="row g-4 align-items-center justify-content-between">
                    <div className="col-xl-4 col-lg-6">
                      <div className="aai-price-pack">
                        <h3 className="aai-price-pack-name">
                          {subscription.plan_name}
                        </h3>
                        <p className="aai-price-pack-desc">
                          {subscription.plan_description}
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                      <ul className="aai-price-lists list-unstyled">
                        {/* ... your list items with SVGs ... */}
                      </ul>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                      <div
                        className="aai-price-value"
                        style={{ marginLeft: "-10px", fontSize: "2rem" }}
                      >
                        <h2
                          className="aai-price"
                          style={{ fontSize: "1.5rem" }}
                        >
                          {console.log("User Country:", userCountry)}
                          {userCountry === "AF" &&
                            `${subscription.plan_price_af}`}{" "}
                          {userCountry === "AL" &&
                            `${subscription.plan_price_al}`}{" "}
                          {userCountry === "DZ" &&
                            `${subscription.plan_price_dz}`}{" "}
                          {userCountry === "AD" &&
                            `${subscription.plan_price_ad}`}{" "}
                          {userCountry === "AO" &&
                            `${subscription.plan_price_ao}`}{" "}
                          {userCountry === "AI" &&
                            `${subscription.plan_price_ai}`}{" "}
                          {userCountry === "AQ" &&
                            `${subscription.plan_price_aq}`}{" "}
                          {userCountry === "AG" &&
                            `${subscription.plan_price_ag}`}{" "}
                          {userCountry === "AR" &&
                            `${subscription.plan_price_ar}`}{" "}
                          {userCountry === "AM" &&
                            `${subscription.plan_price_am}`}{" "}
                          {userCountry === "AU" &&
                            `${subscription.plan_price_au}`}{" "}
                          {userCountry === "AT" &&
                            `${subscription.plan_price_at}`}{" "}
                          {userCountry === "AZ" &&
                            `${subscription.plan_price_az}`}{" "}
                          {userCountry === "BS" &&
                            `${subscription.plan_price_bs}`}{" "}
                          {userCountry === "BH" &&
                            `${subscription.plan_price_bh}`}{" "}
                          {userCountry === "BD" &&
                            `${subscription.plan_price_bd}`}{" "}
                          {userCountry === "BB" &&
                            `${subscription.plan_price_bb}`}{" "}
                          {userCountry === "BY" &&
                            `${subscription.plan_price_by}`}{" "}
                          {userCountry === "BE" &&
                            `${subscription.plan_price_be}`}{" "}
                          {userCountry === "BZ" &&
                            `${subscription.plan_price_bz}`}{" "}
                          {userCountry === "BJ" &&
                            `${subscription.plan_price_bj}`}{" "}
                          {userCountry === "BM" &&
                            `${subscription.plan_price_bm}`}{" "}
                          {userCountry === "BT" &&
                            `${subscription.plan_price_bt}`}{" "}
                          {userCountry === "BO" &&
                            `${subscription.plan_price_bo}`}{" "}
                          {userCountry === "BA" &&
                            `${subscription.plan_price_ba}`}{" "}
                          {userCountry === "BW" &&
                            `${subscription.plan_price_bw}`}{" "}
                          {userCountry === "BR" &&
                            `${subscription.plan_price_br}`}{" "}
                          {userCountry === "BN" &&
                            `${subscription.plan_price_bn}`}{" "}
                          {userCountry === "BG" &&
                            `${subscription.plan_price_bg}`}{" "}
                          {userCountry === "BF" &&
                            `${subscription.plan_price_bf}`}{" "}
                          {userCountry === "BI" &&
                            `${subscription.plan_price_bi}`}{" "}
                          {userCountry === "KH" &&
                            `${subscription.plan_price_kh}`}{" "}
                          {userCountry === "CM" &&
                            `${subscription.plan_price_cm}`}{" "}
                          {userCountry === "CA" &&
                            `${subscription.plan_price_ca}`}{" "}
                          {userCountry === "CV" &&
                            `${subscription.plan_price_cv}`}{" "}
                          {userCountry === "CH" &&
                            `${subscription.plan_price_ch}`}{" "}
                          {userCountry === "KY" &&
                            `${subscription.plan_price_ky}`}{" "}
                          {userCountry === "CF" &&
                            `${subscription.plan_price_cf}`}{" "}
                          {userCountry === "TD" &&
                            `${subscription.plan_price_td}`}{" "}
                          {userCountry === "CL" &&
                            `${subscription.plan_price_cl}`}{" "}
                          {userCountry === "CN" &&
                            `${subscription.plan_price_cn}`}{" "}
                          {userCountry === "CO" &&
                            `${subscription.plan_price_co}`}{" "}
                          {userCountry === "KM" &&
                            `${subscription.plan_price_km}`}{" "}
                          {userCountry === "CG" &&
                            `${subscription.plan_price_cg}`}{" "}
                          {userCountry === "CR" &&
                            `${subscription.plan_price_cr}`}{" "}
                          {userCountry === "CI" &&
                            `${subscription.plan_price_ci}`}{" "}
                          {userCountry === "HR" &&
                            `${subscription.plan_price_hr}`}{" "}
                          {userCountry === "CY" &&
                            `${subscription.plan_price_cy}`}{" "}
                          {userCountry === "CZ" &&
                            `${subscription.plan_price_cz}`}{" "}
                          {userCountry === "DK" &&
                            `${subscription.plan_price_dk}`}{" "}
                          {userCountry === "DJ" &&
                            `${subscription.plan_price_dj}`}{" "}
                          {userCountry === "DM" &&
                            `${subscription.plan_price_dm}`}{" "}
                          {userCountry === "DO" &&
                            `${subscription.plan_price_do}`}{" "}
                          {userCountry === "EC" &&
                            `${subscription.plan_price_ec}`}{" "}
                          {userCountry === "EG" &&
                            `${subscription.plan_price_eg}`}{" "}
                          {userCountry === "SV" &&
                            `${subscription.plan_price_sv}`}{" "}
                          {userCountry === "GQ" &&
                            `${subscription.plan_price_gq}`}{" "}
                          {userCountry === "ER" &&
                            `${subscription.plan_price_er}`}{" "}
                          {userCountry === "EE" &&
                            `${subscription.plan_price_ee}`}{" "}
                          {userCountry === "SZ" &&
                            `${subscription.plan_price_sz}`}{" "}
                          {userCountry === "ET" &&
                            `${subscription.plan_price_et}`}{" "}
                          {userCountry === "FJ" &&
                            `${subscription.plan_price_fj}`}{" "}
                          {userCountry === "FI" &&
                            `${subscription.plan_price_fi}`}{" "}
                          {userCountry === "FR" &&
                            `${subscription.plan_price_fr}`}{" "}
                          {userCountry === "GA" &&
                            `${subscription.plan_price_ga}`}{" "}
                          {userCountry === "GM" &&
                            `${subscription.plan_price_gm}`}{" "}
                          {userCountry === "GE" &&
                            `${subscription.plan_price_ge}`}{" "}
                          {userCountry === "DE" &&
                            `${subscription.plan_price_de}`}{" "}
                          {userCountry === "GH" &&
                            `${subscription.plan_price_gh}`}{" "}
                          {userCountry === "GR" &&
                            `${subscription.plan_price_gr}`}{" "}
                          {userCountry === "GD" &&
                            `${subscription.plan_price_gd}`}{" "}
                          {userCountry === "GU" &&
                            `${subscription.plan_price_gu}`}{" "}
                          {userCountry === "GT" &&
                            `${subscription.plan_price_gt}`}{" "}
                          {userCountry === "GN" &&
                            `${subscription.plan_price_gn}`}{" "}
                          {userCountry === "GW" &&
                            `${subscription.plan_price_gw}`}{" "}
                          {userCountry === "GY" &&
                            `${subscription.plan_price_gy}`}{" "}
                          {userCountry === "HT" &&
                            `${subscription.plan_price_ht}`}{" "}
                          {userCountry === "HN" &&
                            `${subscription.plan_price_hn}`}{" "}
                          {userCountry === "HU" &&
                            `${subscription.plan_price_hu}`}{" "}
                          {userCountry === "HK" &&
                            `${subscription.plan_price_hk}`}{" "}
                          {userCountry === "IS" &&
                            `${subscription.plan_price_is}`}{" "}
                          {userCountry === "ID" &&
                            `${subscription.plan_price_id}`}{" "}
                          {userCountry === "IQ" &&
                            `${subscription.plan_price_iq}`}{" "}
                          {userCountry === "IE" &&
                            `${subscription.plan_price_ie}`}{" "}
                          {userCountry === "IL" &&
                            `${subscription.plan_price_il}`}{" "}
                          {userCountry === "IT" &&
                            `${subscription.plan_price_it}`}{" "}
                          {userCountry === "JM" &&
                            `${subscription.plan_price_jm}`}{" "}
                          {userCountry === "JP" &&
                            `${subscription.plan_price_jp}`}{" "}
                          {userCountry === "JO" &&
                            `${subscription.plan_price_jo}`}{" "}
                          {userCountry === "KZ" &&
                            `${subscription.plan_price_kz}`}{" "}
                          {userCountry === "KE" &&
                            `${subscription.plan_price_ke}`}{" "}
                          {userCountry === "KI" &&
                            `${subscription.plan_price_ki}`}{" "}
                          {userCountry === "KR" &&
                            `${subscription.plan_price_kr}`}{" "}
                          {userCountry === "KW" &&
                            `${subscription.plan_price_kw}`}{" "}
                          {userCountry === "KG" &&
                            `${subscription.plan_price_kg}`}{" "}
                          {userCountry === "LA" &&
                            `${subscription.plan_price_la}`}{" "}
                          {userCountry === "LV" &&
                            `${subscription.plan_price_lv}`}{" "}
                          {userCountry === "LB" &&
                            `${subscription.plan_price_lb}`}{" "}
                          {userCountry === "LS" &&
                            `${subscription.plan_price_ls}`}{" "}
                          {userCountry === "LR" &&
                            `${subscription.plan_price_lr}`}{" "}
                          {userCountry === "LY" &&
                            `${subscription.plan_price_ly}`}{" "}
                          {userCountry === "LI" &&
                            `${subscription.plan_price_li}`}{" "}
                          {userCountry === "LT" &&
                            `${subscription.plan_price_lt}`}{" "}
                          {userCountry === "LU" &&
                            `${subscription.plan_price_lu}`}{" "}
                          {userCountry === "MG" &&
                            `${subscription.plan_price_mg}`}{" "}
                          {userCountry === "MW" &&
                            `${subscription.plan_price_mw}`}{" "}
                          {userCountry === "MY" &&
                            `${subscription.plan_price_my}`}{" "}
                          {userCountry === "MV" &&
                            `${subscription.plan_price_mv}`}{" "}
                          {userCountry === "ML" &&
                            `${subscription.plan_price_ml}`}{" "}
                          {userCountry === "MT" &&
                            `${subscription.plan_price_mt}`}{" "}
                          {userCountry === "MR" &&
                            `${subscription.plan_price_mr}`}{" "}
                          {userCountry === "MU" &&
                            `${subscription.plan_price_mu}`}{" "}
                          {userCountry === "MK" &&
                            `${subscription.plan_price_mk}`}{" "}
                          {userCountry === "YT" &&
                            `${subscription.plan_price_yt}`}{" "}
                          {userCountry === "MX" &&
                            `${subscription.plan_price_mx}`}{" "}
                          {userCountry === "MD" &&
                            `${subscription.plan_price_md}`}{" "}
                          {userCountry === "MC" &&
                            `${subscription.plan_price_mc}`}{" "}
                          {userCountry === "MN" &&
                            `${subscription.plan_price_mn}`}{" "}
                          {userCountry === "ME" &&
                            `${subscription.plan_price_me}`}{" "}
                          {userCountry === "MS" &&
                            `${subscription.plan_price_ms}`}{" "}
                          {userCountry === "MA" &&
                            `${subscription.plan_price_ma}`}{" "}
                          {userCountry === "MZ" &&
                            `${subscription.plan_price_mz}`}{" "}
                          {userCountry === "MM" &&
                            `${subscription.plan_price_mm}`}{" "}
                          {userCountry === "NA" &&
                            `${subscription.plan_price_na}`}{" "}
                          {userCountry === "NR" &&
                            `${subscription.plan_price_nr}`}{" "}
                          {userCountry === "NP" &&
                            `${subscription.plan_price_np}`}{" "}
                          {userCountry === "NL" &&
                            `${subscription.plan_price_nl}`}{" "}
                          {userCountry === "NC" &&
                            `${subscription.plan_price_nc}`}{" "}
                          {userCountry === "NZ" &&
                            `${subscription.plan_price_nz}`}{" "}
                          {userCountry === "NI" &&
                            `${subscription.plan_price_ni}`}{" "}
                          {userCountry === "NE" &&
                            `${subscription.plan_price_ne}`}{" "}
                          {userCountry === "NG" &&
                            `${subscription.plan_price_ng}`}{" "}
                          {userCountry === "NO" &&
                            `${subscription.plan_price_no}`}{" "}
                          {userCountry === "OM" &&
                            `${subscription.plan_price_om}`}{" "}
                          {userCountry === "PK" &&
                            `${subscription.plan_price_pk}`}{" "}
                          {userCountry === "PW" &&
                            `${subscription.plan_price_pw}`}{" "}
                          {userCountry === "PA" &&
                            `${subscription.plan_price_pa}`}{" "}
                          {userCountry === "PG" &&
                            `${subscription.plan_price_pg}`}{" "}
                          {userCountry === "PY" &&
                            `${subscription.plan_price_py}`}{" "}
                          {userCountry === "PE" &&
                            `${subscription.plan_price_pe}`}{" "}
                          {userCountry === "PH" &&
                            `${subscription.plan_price_ph}`}{" "}
                          {userCountry === "PL" &&
                            `${subscription.plan_price_pl}`}{" "}
                          {userCountry === "PT" &&
                            `${subscription.plan_price_pt}`}{" "}
                          {userCountry === "PR" &&
                            `${subscription.plan_price_pr}`}{" "}
                          {userCountry === "QA" &&
                            `${subscription.plan_price_qa}`}{" "}
                          {userCountry === "RE" &&
                            `${subscription.plan_price_re}`}{" "}
                          {userCountry === "RO" &&
                            `${subscription.plan_price_ro}`}{" "}
                          {userCountry === "RU" &&
                            `${subscription.plan_price_ru}`}{" "}
                          {userCountry === "RW" &&
                            `${subscription.plan_price_rw}`}{" "}
                          {userCountry === "ST" &&
                            `${subscription.plan_price_st}`}{" "}
                          {userCountry === "SA" &&
                            `${subscription.plan_price_sa}`}{" "}
                          {userCountry === "SN" &&
                            `${subscription.plan_price_sn}`}{" "}
                          {userCountry === "RS" &&
                            `${subscription.plan_price_rs}`}{" "}
                          {userCountry === "SC" &&
                            `${subscription.plan_price_sc}`}{" "}
                          {userCountry === "SL" &&
                            `${subscription.plan_price_sl}`}{" "}
                          {userCountry === "SG" &&
                            `${subscription.plan_price_sg}`}{" "}
                          {userCountry === "SK" &&
                            `${subscription.plan_price_sk}`}{" "}
                          {userCountry === "SI" &&
                            `${subscription.plan_price_si}`}{" "}
                          {userCountry === "SO" &&
                            `${subscription.plan_price_so}`}{" "}
                          {userCountry === "ZA" &&
                            `${subscription.plan_price_za}`}{" "}
                          {userCountry === "SS" &&
                            `${subscription.plan_price_ss}`}{" "}
                          {userCountry === "ES" &&
                            `${subscription.plan_price_es}`}{" "}
                          {userCountry === "LK" &&
                            `${subscription.plan_price_lk}`}{" "}
                          {userCountry === "SD" &&
                            `${subscription.plan_price_sd}`}{" "}
                          {userCountry === "SR" &&
                            `${subscription.plan_price_sr}`}{" "}
                          {userCountry === "SJ" &&
                            `${subscription.plan_price_sj}`}{" "}
                          {userCountry === "SE" &&
                            `${subscription.plan_price_se}`}{" "}
                          {userCountry === "TW" &&
                            `${subscription.plan_price_tw}`}{" "}
                          {userCountry === "TJ" &&
                            `${subscription.plan_price_tj}`}{" "}
                          {userCountry === "TZ" &&
                            `${subscription.plan_price_tz}`}{" "}
                          {userCountry === "TH" &&
                            `${subscription.plan_price_th}`}{" "}
                          {userCountry === "TL" &&
                            `${subscription.plan_price_tl}`}{" "}
                          {userCountry === "TG" &&
                            `${subscription.plan_price_tg}`}{" "}
                          {userCountry === "TO" &&
                            `${subscription.plan_price_to}`}{" "}
                          {userCountry === "TT" &&
                            `${subscription.plan_price_tt}`}{" "}
                          {userCountry === "TN" &&
                            `${subscription.plan_price_tn}`}{" "}
                          {userCountry === "TR" &&
                            `${subscription.plan_price_tr}`}{" "}
                          {userCountry === "TM" &&
                            `${subscription.plan_price_tm}`}{" "}
                          {userCountry === "TC" &&
                            `${subscription.plan_price_tc}`}{" "}
                          {userCountry === "TV" &&
                            `${subscription.plan_price_tv}`}{" "}
                          {userCountry === "UG" &&
                            `${subscription.plan_price_ug}`}{" "}
                          {userCountry === "UA" &&
                            `${subscription.plan_price_ua}`}{" "}
                          {userCountry === "AE" &&
                            `${subscription.plan_price_ae}`}{" "}
                          {userCountry === "GB" &&
                            `${
                              subscription.plan_price_gb ||
                              subscription.plan_price_uk
                            }`}{" "}
                          {userCountry === "US" &&
                            `${subscription.plan_price_us}`}{" "}
                          {userCountry === "UY" &&
                            `${subscription.plan_price_uy}`}{" "}
                          {userCountry === "UZ" &&
                            `${subscription.plan_price_uz}`}{" "}
                          {userCountry === "VU" &&
                            `${subscription.plan_price_vu}`}{" "}
                          {userCountry === "VE" &&
                            `${subscription.plan_price_ve}`}{" "}
                          {userCountry === "VN" &&
                            `${subscription.plan_price_vn}`}{" "}
                          {userCountry === "WF" &&
                            `${subscription.plan_price_wf}`}{" "}
                          {userCountry === "EH" &&
                            `${subscription.plan_price_eh}`}{" "}
                          {userCountry === "YE" &&
                            `${subscription.plan_price_ye}`}{" "}
                          {userCountry === "ZM" &&
                            `${subscription.plan_price_zm}`}{" "}
                          {userCountry === "ZW" &&
                            `${subscription.plan_price_zw}`}{" "}
                          {/* from here the the extra countries are added  */}
                          {userCountry === "AX" &&
                            `${subscription.plan_price_ax}`}{" "}
                          {userCountry === "AW" &&
                            `${subscription.plan_price_aw}`}{" "}
                          {userCountry === "AC" &&
                            `${subscription.plan_price_ac}`}{" "}
                          {userCountry === "BV" &&
                            `${subscription.plan_price_bv}`}{" "}
                          {userCountry === "IO" &&
                            `${subscription.plan_price_io}`}{" "}
                          {userCountry === "VG" &&
                            `${subscription.plan_price_vg}`}{" "}
                          {userCountry === "BQ" &&
                            `${subscription.plan_price_bq}`}{" "}
                          {userCountry === "CD" &&
                            `${subscription.plan_price_cd}`}{" "}
                          {userCountry === "CK" &&
                            `${subscription.plan_price_ck}`}{" "}
                          {userCountry === "CW" &&
                            `${subscription.plan_price_cw}`}{" "}
                          {userCountry === "FK" &&
                            `${subscription.plan_price_fk}`}{" "}
                          {userCountry === "FO" &&
                            `${subscription.plan_price_fo}`}{" "}
                          {userCountry === "GF" &&
                            `${subscription.plan_price_gf}`}{" "}
                          {userCountry === "PF" &&
                            `${subscription.plan_price_pf}`}{" "}
                          {userCountry === "TF" &&
                            `${subscription.plan_price_tf}`}{" "}
                          {userCountry === "GI" &&
                            `${subscription.plan_price_gi}`}{" "}
                          {userCountry === "GL" &&
                            `${subscription.plan_price_gl}`}{" "}
                          {userCountry === "GP" &&
                            `${subscription.plan_price_gp}`}{" "}
                          {userCountry === "GG" &&
                            `${subscription.plan_price_gg}`}{" "}
                          {userCountry === "IM" &&
                            `${subscription.plan_price_im}`}{" "}
                          {userCountry === "JE" &&
                            `${subscription.plan_price_je}`}{" "}
                          {userCountry === "XK" &&
                            `${subscription.plan_price_xk}`}{" "}
                          {userCountry === "MO" &&
                            `${subscription.plan_price_mo}`}{" "}
                          {userCountry === "MQ" &&
                            `${subscription.plan_price_mq}`}{" "}
                          {userCountry === "NU" &&
                            `${subscription.plan_price_nu}`}{" "}
                          {userCountry === "PS" &&
                            `${subscription.plan_price_ps}`}{" "}
                          {userCountry === "PN" &&
                            `${subscription.plan_price_pn}`}{" "}
                          {userCountry === "WS" &&
                            `${subscription.plan_price_ws}`}{" "}
                          {userCountry === "SM" &&
                            `${subscription.plan_price_sm}`}{" "}
                          {userCountry === "SX" &&
                            `${subscription.plan_price_sx}`}{" "}
                          {userCountry === "SB" &&
                            `${subscription.plan_price_sb}`}{" "}
                          {userCountry === "GS" &&
                            `${subscription.plan_price_gs}`}{" "}
                          {userCountry === "BL" &&
                            `${subscription.plan_price_bl}`}{" "}
                          {userCountry === "SH" &&
                            `${subscription.plan_price_sh}`}{" "}
                          {userCountry === "KN" &&
                            `${subscription.plan_price_kn}`}{" "}
                          {userCountry === "LC" &&
                            `${subscription.plan_price_lc}`}{" "}
                          {userCountry === "MF" &&
                            `${subscription.plan_price_mf}`}{" "}
                          {userCountry === "PM" &&
                            `${subscription.plan_price_pm}`}{" "}
                          {userCountry === "VC" &&
                            `${subscription.plan_price_vc}`}{" "}
                          {userCountry === "TK" &&
                            `${subscription.plan_price_tk}`}{" "}
                          {userCountry === "TA" &&
                            `${subscription.plan_price_ta}`}{" "}
                          {userCountry === "VA" &&
                            `${subscription.plan_price_va}`}{" "}
                          {userCountry === "IN" &&
                            `₹${subscription.plan_price}`}{" "}
                        </h2>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-6">
                      <div>
                        <input
                          type="hidden"
                          name="stripeId"
                          value={
                            subscription[
                              `stripe_id_${
                                userCountry ? userCountry.toLowerCase() : ""
                              }`
                            ]
                          }
                        />
                        <button
                          className="aai-btn btn-pill-solid"
                          id="submit"
                          role="link"
                          onClick={(e) =>
                            handlePayClick(
                              e,
                              subscription.plan_id,
                              subscription.plan_name,
                              subscription.plan_price,
                              subscription
                            )
                          }
                        >
                          PAY
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* Popup for payment options */}
              {showPopup && (
                <div className="popup">
                  <h2>Select Payment Option</h2>
                  <br />
                  {/* <button className="popup-close" onClick={closeMessage} style={{fontSize:"larger",marginTop:"-150px",marginLeft:"355px",position:"fixed",color:'black'}}>X</button> */}

                  <button
                    className="popup-close"
                    onClick={closeShowPopup}
                    style={{
                      fontSize: "larger",
                      // marginTop: "-150px",
                      marginLeft: "355px",
                      position: "fixed",
                      color: "black",
                    }}
                  >
                    X
                  </button>

                  <button
                    onClick={() => handlePaymentOptionClick("PhonePe")}
                    className={
                      selectedPaymentOption === "PhonePe" ? "selected" : ""
                    }
                  >
                    PhonePe
                  </button>
                  {/* //billdesk from and above button is coommet */}
                  <button onClick={openModal}>BillDesk</button>
                  {/* till here */}
                  <button
                    onClick={() => handlePaymentOptionClick("Stripe")}
                    className={
                      selectedPaymentOption === "Stripe" ? "selected" : ""
                    }
                  >
                    Stripe
                  </button>
                  <br />
                  <br />
                  <br />

                  <center>
                    <button onClick={handleProceed}>Proceed</button>
                  </center>
                </div>
              )}

              {/* this code is for the modal for billdesk options  */}
              {isModalOpen && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  style={{ display: "block" }}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                    style={{ height: "80vh" }}
                  >
                    <div className="modal-content" style={{ height: "100%" }}>
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Payment Options
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={closeModal}
                          aria-label="Close"
                        ></button>
                      </div>
                      <div
                        className="modal-body"
                        style={{
                          maxHeight: "calc(80vh - 56px - 50px)", // Adjusted to account for the footer height
                          overflowY: "auto",
                        }}
                      >
                        <div className="d-flex flex-column align-items-center">
                          <div className="text-center mb-4">
                            <button
                              className={`btn mb-2 ${
                                selectedPaymentOption === "One-Time Payment"
                                  ? "btn-success"
                                  : "btn-primary"
                              }`}
                              onClick={() =>
                                handlePaymentOptionClick("One-Time Payment")
                              }
                            >
                              One-Time Payment
                            </button>
                            <p className="text-muted">
                              Use this option to make a single payment for your
                              purchase. This is ideal for one-off transactions
                              where you do not wish to commit to recurring
                              payments.
                            </p>
                          </div>
                          <div className="text-center mb-4">
                            <button
                              className={`btn mb-2 ${
                                selectedPaymentOption === "Recurring Payment"
                                  ? "btn-success"
                                  : "btn-primary"
                              }`}
                              onClick={() =>
                                handlePaymentOptionClick("Recurring Payment")
                              }
                            >
                              Recurring Payment
                            </button>
                            <p className="text-muted">
                              Choose this option if you prefer a
                              subscription-based model. Your account will be
                              automatically charged on a regular basis (e.g.,
                              monthly or yearly) for continued access to the
                              service.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* Footer with Proceed button */}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleProceed}
                          disabled={!selectedPaymentOption} // Disable if no option is selected
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* till here the modal for billdesk end */}
              <button
                className="aai-btn btn-pill-solid"
                onClick={() => setPopupVisible(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {/* Popup subscription close */}

        {/* Right Side bar */}
        <div className={styles.rightSidebar}>
          <div />
          <div>
            {countOfRooms?.map((room, index) => {
              const images = {
                oneAI: (
                  <img
                    src="/assets/img/chat/oneai.png"
                    alt="Chat sonic"
                    width="30px"
                  />
                ),
                "gpt-3.5-turbo-0613": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-0301": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-0125": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-vision-preview": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-1106-preview": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-0125-preview": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-turbo-preview": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-16k": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-1106": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-16k-0613": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),

                "gpt-3.5-turbo-instruct": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4-0613": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-3.5-turbo-instruct-0914": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),

                "gpt-4o": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4o-2024-05-13": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "gpt-4.5-preview": (
                  <img
                    src="/assets/img/chat/chatgpt.svg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "o1-mini": (
                  <img
                    src="/assets/img/logo/o1.jpeg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "o1-preview": (
                  <img
                    src="/assets/img/logo/o1.jpeg"
                    width="20px"
                    alt="Chat gpt"
                  />
                ),
                "o3-mini": (
                  <img
                    src="/assets/img/logo/o1.jpeg"
                    width="20px"
                    alt="Chat gpt-o3-mini"
                  />
                ),
                "gemini-1.0-pro": (
                  <img
                    src="/assets/img/chat/googlebard.png"
                    width="30px"
                    alt="Google Bard"
                  />
                ),
                "gemini-1.5-pro": (
                  <img
                    src="/assets/img/chat/googlebard.png"
                    width="30px"
                    alt="Google Bard"
                  />
                ),
                "claude-2.0": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claude"
                  />
                ),
                "claude-2.1": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claude"
                  />
                ),
                "claude-+--1.2": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claudeeee"
                  />
                ),
                "claude-3-opus-20240229": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claudeeee"
                  />
                ),
                "claude-3-sonnet-20240229": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claudeeee"
                  />
                ),
                "claude-3-haiku-20240307": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claudeeee"
                  />
                ),
                googlePalm: (
                  <img
                    src="/assets/img/chat/palm.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                mistral7b: (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-tiny-2312": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-tiny": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-small-2312": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "open-mixtral-8x7b": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-small": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-small-2402": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-small-latest": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-medium-latest": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-medium-2312": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-medium": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-large-latest": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "mistral-large-2402": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "open-mixtral-8x22b": (
                  <img
                    src="/assets/img/chat/mistral.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "llama 2 70b": (
                  <img
                    src="/assets/img/chat/llama.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion Core": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion 3": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion 3-Turbo": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion 1.6": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "stable Diffusion-XL 1.0": (
                  <img
                    src="/assets/img/chat/stability.png"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- HD-1024 * 1024": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- HD-1024 * 1792": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- Standard-1024 * 1024": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- Standard-1024 * 1792": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-3- Standard-1024 * 1792": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-2-1024 * 1024": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-2-512 * 512": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "dalle-2-256 * 256": (
                  <img
                    src="/assets/img/chat/dalle.jpg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "command-R": (
                  <img
                    src="/assets/img/chat/command.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),
                "command-R-Plus": (
                  <img
                    src="/assets/img/chat/command.jpeg"
                    width="30px"
                    alt="palm"
                  />
                ),

                "claude-3-5-sonnet-20240620": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claude-3.5"
                  />
                ),

                "claude-3-7-sonnet-20250219": (
                  <img
                    src="/assets/img/chat/claude.webp"
                    width="30px"
                    alt="claude-3.5"
                  />
                ),

                Haiper_AI: (
                  <img
                    src="/assets/img/chat/Haiper.jpeg"
                    width="30px"
                    alt="Haiper_AI"
                  />
                ),

                Haiper_Pic: (
                  <img
                    src="/assets/img/chat/Haiper.jpeg"
                    width="30px"
                    alt="Haiper_Pic"
                  />
                ),

                "Ideogram-V2-Turbo": (
                  <img
                    src="/assets/img/logo/ideogram.jpeg"
                    width="30px"
                    alt="Ideogram-V2-Turbo"
                  />
                ),

                "Ideogram-V2": (
                  <img
                    src="/assets/img/logo/ideogram.jpeg"
                    width="30px"
                    alt="Ideogram-V2"
                  />
                ),

                "Ideogram-V1-Turbo": (
                  <img
                    src="/assets/img/logo/ideogram.jpeg"
                    width="30px"
                    alt="Ideogram-V1-Turbo"
                  />
                ),

                "Ideogram-V1": (
                  <img
                    src="/assets/img/logo/ideogram.jpeg"
                    width="30px"
                    alt="Ideogram-V1"
                  />
                ),

                Beatoven: (
                  <img
                    src="/assets/img/logo/Beatoven.png"
                    width="30px"
                    alt="Beatoven"
                  />
                ),

                Stability_AI: (
                  <img
                    src="/assets/img/chat/stability.png"
                    alt="Stability_AI"
                    width="40px"
                  />
                ),
              };

              if (!room?._id) {
                return null;
              }
              return (
                <div
                  className={
                    index
                      ? "position-relative text-center mt-4 cursor-pointer"
                      : "position-relative cursor-pointer"
                  }
                  onClick={() => openRoombar(room?._id)}
                  key={room?._id}
                >
                  {images?.[room?._id]}
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                    {room?.total}
                  </span>
                </div>
              );
            })}
          </div>
          <div />
        </div>
      </div>

      {/* Popup category open */}
      {isCatPopupVisible && (
        <div className={styles.overlay}>
          <div className={styles.popup} id="cat-popup-main-div">
            {/* <button className="popup-close"  onClick={() => setCatPopupVisible(false)} style={{fontSize:"larger",marginTop:"-40px",marginLeft:"650px",position:"fixed",color:'white'}}>X</button> */}

            <button
              className="popup-close"
              onClick={() => setCatPopupVisible(false)}
              style={
                {}
                // {fontSize:"larger",marginTop:"-40px",marginLeft:"650px",position:"fixed",color:'white'}
              }
            >
              X
            </button>

            {subscriptionPrompt && (
              <div
                className={`subscription-box ${showMessage ? "active" : ""}`}
              >
                <button
                  className="close-subscription-btn"
                  onClick={closeMessage}
                >
                  &times;
                </button>
                User already has a subscription!
              </div>
            )}

            {/* <div className="aai-price-table mb-4"> */}
            <div className="aai-price-table">
              <div
                className="row align-items-center justify-content-between mb-4"
                style={{ color: "#04882b", marginLeft: "-3rem" }}
              >
                <h4>Do you want to switch the category?</h4>
              </div>
              <div className="g-2 align-items-center justify-content-between cat-popup-div">
                <span className="" style={{ marginRight: "1rem" }}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleNewRoom(true, setNewCategory)}
                  >
                    Yes
                  </button>
                </span>
                <span className="" style={{}}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleNewRoom(false, setNewCategory)}
                  >
                    No
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Popup category close */}

      {/* Popup Aichat open */}
      {isAichatPopupVisible && (
        <div className={styles.overlay}>
          <div className={styles.popup} id="cat-popup-main-div">
            {/* <button className="popup-close"  onClick={() => setAiChatPopupVisible(false)} style={{fontSize:"larger",marginTop:"-40px",marginLeft:"650px",position:"fixed",color:'white'}}>X</button> */}

            <button
              className="popup-close"
              onClick={() => setAiChatPopupVisible(false)}
              style={
                {}
                // {fontSize:"larger",marginTop:"-40px",marginLeft:"650px",position:"fixed",color:'white'}
              }
            >
              X
            </button>

            {subscriptionPrompt && (
              <div
                className={`subscription-box ${showMessage ? "active" : ""}`}
              >
                <button
                  className="close-subscription-btn"
                  onClick={closeMessage}
                >
                  &times;
                </button>
                User already has a subscription!
              </div>
            )}

            {/* <div className="aai-price-table mb-4"> */}
            <div className="aai-price-table">
              <div
                className="row align-items-center justify-content-between mb-4"
                style={{ color: "#04882b", marginLeft: "-3rem" }}
              >
                <h4>Do you want to switch the ChatBot?</h4>
              </div>
              <div className="g-2 align-items-center justify-content-between cat-popup-div">
                <span className="" style={{ marginRight: "1rem" }}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleNewchatBot(true)}
                  >
                    Yes
                  </button>
                </span>
                <span className="" style={{}}>
                  <button
                    className="aai-btn btn-pill-solid cat-popup"
                    onClick={() => handleNewchatBot(false)}
                  >
                    No
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Popup Aichat close */}
    </div>
  );
};
export default ChatPage;
