import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { Helmet } from "react-helmet";
 
const Blog=()=>{
    return(
        <div>
            <Helmet>
            <link rel="canonical" href="https://oneaichat.com/blog" />
            </Helmet>
 <Header />
  <main className="wrapper">
    {/* Breadcrumb Start */}
      <section
        className="aai-breadcrumb"
        // style={{
        //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="text-center">
                <h2 className="aai-page-title">Blog</h2>
                <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                  <li>
                    <a
                      href="/"
                      className="aai-breadcrumb-link text-decoration-underline"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 16L18 12M18 12L14 8M18 12L6 12"
                        stroke="#DADADA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>
                  <li>
                    <a href="" className="aai-breadcrumb-link"> Blog </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
   {/* Breadcrumb End */}
     {/* Blog Start */}
       <section className="aai-blog-posts pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-xl-8">
            <article
                className="aai-post-wrapper"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-post-item">
                  <a
                    href="https://www.newsbytesapp.com/news/science/indian-startup-oneaichat-launches-unified-llm-interface/story"
                    className="aai-post-thumb-wrapper d-block"
                  >
                    <img
                      src="assets/img/blog/bp-11.avif"
                      className="img-fluid aai-post-thumb"
                      alt=""
                    />
                  </a>
                  <div className="aai-post-content">
                    <div className="aai-post-meta d-flex flex-wrap gap-3">
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-user"></i>
                        <span>Admin</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-clock"></i>
                        <span>30 April, 2024</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-folder"></i>
                        <span>NewsBytes</span>
                      </a>
                    </div>
                    <h3 className="aai-post-title">
                      <a href="https://www.newsbytesapp.com/news/science/indian-startup-oneaichat-launches-unified-llm-interface/story">
                      Indian startup's AI aggregator platform brings together ChatGPT, Gemini, others
                      </a>
                    </h3>
                    <p className="aai-post-exerpt mb-3">
                    OneAIChat's standout feature, 'Focus Categories,' allows users to explore specific categories and find tailored multi-modal solutions.
                  An executive from the startup explained that the platform promises to cater to a wide range of needs - all on one platform.
                 "From health and audio/music to faith, marketing, video, art and design, and mathematics, this versatile platform promises to cater to a wide range of needs," he said.
                    </p>
                    <a
                      href="https://www.newsbytesapp.com/news/science/indian-startup-oneaichat-launches-unified-llm-interface/story"
                      className="aai-post-readmore d-flex align-items-center gap-2"
                    >
                      <span>Read More</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </article>
            <article
                className="aai-post-wrapper"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-post-item">
                  <a
                    href="https://www.thehindubusinessline.com/info-tech/indian-startup-oneaichat-unveils-unified-interface-for-leading-llms/article68124063.ece"
                    className="aai-post-thumb-wrapper d-block"
                  >
                    <img
                      src="assets/img/blog/bp-11.avif"
                      className="img-fluid aai-post-thumb"
                      alt=""
                    />
                  </a>
                  <div className="aai-post-content">
                    <div className="aai-post-meta d-flex flex-wrap gap-3">
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-user"></i>
                        <span>Admin</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-clock"></i>
                        <span>30 April, 2024</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-folder"></i>
                        <span>The Hindu Business Line</span>
                      </a>
                    </div>
                    <h3 className="aai-post-title">
                      <a href="https://www.thehindubusinessline.com/info-tech/indian-startup-oneaichat-unveils-unified-interface-for-leading-llms/article68124063.ece">
                      Indian startup OneAIChat unveils unified interface for leading LLMs.
                      </a>
                    </h3>
                    <p className="aai-post-exerpt mb-3">
                    An Indian startup has built a multi-modal AI aggregator to save you from the hassle 
                    of visiting different sites. The aggregator, OneAIChat, is bringing together a host of
                     LLMs, such as ChatGPT4, Claude 3, Gemini, DALL-E, and Mistral, allowing the users to use 
                     them on a unified interface.
                    </p>
                    <a
                      href="https://www.thehindubusinessline.com/info-tech/indian-startup-oneaichat-unveils-unified-interface-for-leading-llms/article68124063.ece"
                      className="aai-post-readmore d-flex align-items-center gap-2"
                    >
                      <span>Read More</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </article>
              <article
                className="aai-post-wrapper"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-post-item">
                  <a
                    href="https://www.gadgets360.com/ai/news/oneaichat-multimodal-ai-aggregator-platform-unveiled-gpt-4-gemini-5556572"
                    className="aai-post-thumb-wrapper d-block"
                  >
                    <img
                      src="assets/img/blog/bp-11.avif"
                      className="img-fluid aai-post-thumb"
                      alt=""
                    />
                  </a>
                  <div className="aai-post-content">
                    <div className="aai-post-meta d-flex flex-wrap gap-3">
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-user"></i>
                        <span>Admin</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-clock"></i>
                        <span>30 April, 2024</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-folder"></i>
                        <span>Gadget 360</span>
                      </a>
                    </div>
                    <h3 className="aai-post-title">
                      <a href="https://www.gadgets360.com/ai/news/oneaichat-multimodal-ai-aggregator-platform-unveiled-gpt-4-gemini-5556572">
                      OneAIChat Unveils Multimodal AI Aggregator Platform With GPT-4, Gemini and Other Models.
                      </a>
                    </h3>
                    <p className="aai-post-exerpt mb-3">
                    OneAIChat, an Indian startup, unveiled its new multimodal artificial intelligence (AI)
                     aggregation platform on Tuesday. The Mangalore-based startup is offering a single platform 
                     through which users can access multiple large language models (LLMs) at the same time. The company
                      says this will help users seamlessly interact and compare answers from various AI models.
                      Leveraging the capabilities of multiple models, the platform offers output in text, images,
                       and video formats. The platform will require purchasing a single subscription plan to access it.
                    </p>
                    <a
                      href="https://www.gadgets360.com/ai/news/oneaichat-multimodal-ai-aggregator-platform-unveiled-gpt-4-gemini-5556572"
                      className="aai-post-readmore d-flex align-items-center gap-2"
                    >
                      <span>Read More</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </article>
            <article
                className="aai-post-wrapper"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-post-item">
                  <a
                    href="https://www.theverge.com/23846048/google-search-memes-images-pagerank-altavista-seo-keywords"
                    className="aai-post-thumb-wrapper d-block"
                  >
                    <img
                      src="assets/img/blog/bp-5.webp"
                      className="img-fluid aai-post-thumb"
                      alt=""
                    />
                  </a>
                  <div className="aai-post-content">
                    <div className="aai-post-meta d-flex flex-wrap gap-3">
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-user"></i>
                        <span>Admin</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-clock"></i>
                        <span>28 August, 2023</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-folder"></i>
                        <span>Oneaichat Revolution</span>
                      </a>
                    </div>
                    <h3 className="aai-post-title">
                      <a href="https://www.theverge.com/23846048/google-search-memes-images-pagerank-altavista-seo-keywords">
                      The End Of The Googleverse.
                      </a>
                    </h3>
                    <p className="aai-post-exerpt mb-3">
                    The first thing ever searched on Google was the name Gerhard Casper, a former Stanford
                    president. As the story goes, in 1998, Larry Page and Sergey Brin demoed Google for computer 
                    scientist John Hennessy. They searched Casper’s name on both AltaVista and Google. The former 
                    pulled up results for Casper the Friendly Ghost; the latter pulled up information on Gerhard Casper the person.
                    </p>
                    <a
                      href="https://www.theverge.com/23846048/google-search-memes-images-pagerank-altavista-seo-keywords"
                      className="aai-post-readmore d-flex align-items-center gap-2"
                    >
                      <span>Read More</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </article>

              <article
                className="aai-post-wrapper"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-post-item">
                  <a
                    href="https://ai.gov/"
                    className="aai-post-thumb-wrapper d-block"
                  >
                    <div className="bp66">                  
                      <img
                      src="assets/img/blog/bp-6.webp"
                      className="img-fluid aai-post-thumb"
                      alt=""
                      width={"110%"}
                      
                  
                   />
                   </div>

                  </a>
                  <div className="aai-post-content">
                    <div className="aai-post-meta d-flex flex-wrap gap-3">
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-user"></i>
                        <span>Admin</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-clock"></i>
                        <span>28 August, 2023</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-folder"></i>
                        <span>Oneaichat Revolution</span>
                      </a>
                    </div>
                    <h3 className="aai-post-title">
                      <a href="https://ai.gov/">
                      Making AI Work for the American People.
                      </a>
                    </h3>
                    <p className="aai-post-exerpt mb-3">
                    AI is one of the most powerful technologies of our time. President Biden has been clear that
                    we must take bold action to harness the benefits and mitigate the risks of AI. The Biden-Harris
                    Administration has acted decisively to protect safety and rights in the age of AI, so that everyone
                    can benefit from the promise of AI.
                    </p>
                    <a
                      href="https://ai.gov/"
                      className="aai-post-readmore d-flex align-items-center gap-2"
                    >
                      <span>Read More</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </article>

              <article
                className="aai-post-wrapper"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-post-item">
                  <a
                    href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/mckinsey%20digital/our%20insights/ten%20unsung%20digital%20and%20ai%20ideas%20shaping%20business/ten-unsung-digital-and-ai-ideas-shaping-business.pdf?shouldIndex=false"
                    className="aai-post-thumb-wrapper d-block"
                  >
                        <div className="bp66">        
                    <img
                      src="assets/img/blog/bp-7.jpg"
                      className="img-fluid aai-post-thumb"
                      alt=""
                      width={"110%"}
                
                    />
                    </div>
                  </a>
                  <div className="aai-post-content">
                    <div className="aai-post-meta d-flex flex-wrap gap-3">
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-user"></i>
                        <span>Admin</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-clock"></i>
                        <span>1 January, 2024</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-folder"></i>
                        <span>Oneaichat Revolution</span>
                      </a>
                    </div>
                    <h3 className="aai-post-title">
                      <a href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/mckinsey%20digital/our%20insights/ten%20unsung%20digital%20and%20ai%20ideas%20shaping%20business/ten-unsung-digital-and-ai-ideas-shaping-business.pdf?shouldIndex=false">
                      Ten unsung digital and AI ideas shaping business.
                      </a>
                    </h3>
                    <p className="aai-post-exerpt mb-3">
                    If you wanted to boil down 2023 to one concept,
                    it would have to be generative AI (gen AI). Few of
                    us can remember a technology that has swept
                    through the business community with such speed
                    and impact. Hardly a day passes without a new
                    development hitting the headlines. While still
                    blanketed with caveats and unknowns, gen AI
                    stands to have a profound impact on how we live
                    and work. 
                    </p>
                    <a
                      href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/mckinsey%20digital/our%20insights/ten%20unsung%20digital%20and%20ai%20ideas%20shaping%20business/ten-unsung-digital-and-ai-ideas-shaping-business.pdf?shouldIndex=false"
                      className="aai-post-readmore d-flex align-items-center gap-2"
                    >
                      <span>Read More</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </article>

              <article
                className="aai-post-wrapper"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-post-item">
                  <a
                    href="https://www.theverge.com/2023/12/22/24012730/apple-ai-models-news-publishers"
                    className="aai-post-thumb-wrapper d-block"
                  >
                    <img
                      src="assets/img/blog/bp-8.webp"
                      className="img-fluid aai-post-thumb"
                      alt=""
                      width={"110%"}                      
                    />
                  </a>
                  <div className="aai-post-content">
                    <div className="aai-post-meta d-flex flex-wrap gap-3">
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-user"></i>
                        <span>Admin</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-clock"></i>
                        <span>23 December, 2023</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-folder"></i>
                        <span>Oneaichat Revolution</span>
                      </a>
                    </div>
                    <h3 className="aai-post-title">
                      <a href="https://www.theverge.com/2023/12/22/24012730/apple-ai-models-news-publishers">
                      Apple reportedly wants to use the news to help train its AI models.
                      </a>
                    </h3>
                    <p className="aai-post-exerpt mb-3">
                    Apple is talking with some big news publishers about licensing their news archives 
                    and using that information to help train its generative AI systems, The New York Times reports.
                    The company is apparently discussing “multiyear deals worth at least $50 million,” the NYT says,
                    and has been in touch with publications like Condé Nast, NBC News, and IAC.
                    </p>
                    <a
                      href="https://www.theverge.com/2023/12/22/24012730/apple-ai-models-news-publishers"
                      className="aai-post-readmore d-flex align-items-center gap-2"
                    >
                      <span>Read More</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </article>

              <article
                className="aai-post-wrapper"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-post-item">
                  <a
                    href="https://www.axelspringer.com/en/ax-press-release/axel-springer-and-openai-partner-to-deepen-beneficial-use-of-ai-in-journalism"
                    className="aai-post-thumb-wrapper d-block"
                  >
                    <img
                      src="assets/img/blog/bp-9.webp"
                      className="img-fluid aai-post-thumb"
                      alt=""                    
                    />
                  </a>
                  <div className="aai-post-content">
                    <div className="aai-post-meta d-flex flex-wrap gap-3">
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-user"></i>
                        <span>Admin</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-clock"></i>
                        <span>23 December, 2023</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-folder"></i>
                        <span>Oneaichat Revolution</span>
                      </a>
                    </div>
                    <h3 className="aai-post-title">
                      <a href="https://www.axelspringer.com/en/ax-press-release/axel-springer-and-openai-partner-to-deepen-beneficial-use-of-ai-in-journalism">
                      Axel Springer and OpenAI partner to deepen beneficial use of AI in journalism.
                      </a>
                    </h3>
                    <p className="aai-post-exerpt mb-3">
                    Axel Springer and OpenAI have announced a global partnership to strengthen independent journalism 
                    in the age of artificial intelligence (AI). The initiative will enrich users’ experience with ChatGPT
                    by adding recent and authoritative content on a wide variety of topics, and explicitly values the 
                    publisher’s role in contributing to OpenAI’s products. This marks a significant step in both 
                    companies’ commitment to leverage AI for enhancing content experiences and creating new financial
                    opportunities that support a sustainable future for journalism.  
                    </p>
                    <a
                      href="https://www.axelspringer.com/en/ax-press-release/axel-springer-and-openai-partner-to-deepen-beneficial-use-of-ai-in-journalism"
                      className="aai-post-readmore d-flex align-items-center gap-2"
                    >
                      <span>Read More</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                  
                </div>
              </article>
              <article
                className="aai-post-wrapper"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-post-item">
                  <a
                    href="https://www.axelspringer.com/en/ax-press-release/axel-springer-and-openai-partner-to-deepen-beneficial-use-of-ai-in-journalism"
                    className="aai-post-thumb-wrapper d-block"
                  >
                    <img
                      src="assets/img/blog/bp-10.jpg"
                      className="img-fluid aai-post-thumb"
                      alt=""                    
                    />
                  </a>
                  <div className="aai-post-content">
                    <div className="aai-post-meta d-flex flex-wrap gap-3">
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-user"></i>
                        <span>Admin</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-clock"></i>
                        <span>23 June, 2023</span>
                      </a>
                      <a href="#" className="d-flex align-items-center gap-2">
                        <i className="fa-regular fa-folder"></i>
                        <span>Oneaichat Revolution</span>
                      </a>
                    </div>
                    <h3 className="aai-post-title">
                      <a href="https://www.axelspringer.com/en/ax-press-release/axel-springer-and-openai-partner-to-deepen-beneficial-use-of-ai-in-journalism">
                      A leaderboard of AI startups, from OpenAI and Hugging Face to Cohere and Anthropic.
                      </a>
                    </h3>
                    <p className="aai-post-exerpt mb-3">
                    The world has been transfixed by the latest wave of artificial intelligence technology: It’s creating beautiful images, crunching health data and writing poetry. It’s also displacing human jobs and upending modern warfare.
                    </p>
                    <a
                      href="https://www.axelspringer.com/en/ax-press-release/axel-springer-and-openai-partner-to-deepen-beneficial-use-of-ai-in-journalism"
                      className="aai-post-readmore d-flex align-items-center gap-2"
                    >
                      <span>Read More</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                  
                </div>
              </article>
            
            </div>
            <div className="col-lg-5 col-xl-4">
              <aside className="aai-blog-sidebar">
                {/* Search */}
                <div className="aai-sidebar-widget mb-4">
                  <form action="">
                    <div className="aai-sidebar-search-from">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        placeholder="Search Here"
                      />
                      <button className="aai-sidebar-search-button">
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    </div>
                  </form>
                </div>
                {/* Recent Post */}
                <div className="aai-sidebar-widget mb-4">
                  <h3 className="aai-sidebar-title">Recent Post</h3>
                  <ul className="mt-4 aai-blog-lists d-flex flex-column gap-2">
                    <li className="aai-blog-list-item">
                      <a
                        href="/blogd"
                        className="d-flex align-items-center gap-3"
                      >
                        <i className="fa-solid fa-angle-right"></i>
                        <span>
                          Balancing Innovation and Responsibility With Oneaichat.
                        </span>
                      </a>
                    </li>
                    <li className="aai-blog-list-item">
                      <a
                        href="/blogd"
                        className="d-flex align-items-center gap-3"
                      >
                        <i className="fa-solid fa-angle-right"></i>
                        <span>Shaping the Future of Work and Society.</span>
                      </a>
                    </li>
                    <li className="aai-blog-list-item">
                      <a
                        href="/blogd"
                        className="d-flex align-items-center gap-3"
                      >
                        <i className="fa-solid fa-angle-right"></i>
                        <span>
                          Exploring Trends, Challenges, and Opportunities.
                        </span>
                      </a>
                    </li>
                    <li className="aai-blog-list-item">
                      <a
                        href="/blogd"
                        className="d-flex align-items-center gap-3"
                      >
                        <i className="fa-solid fa-angle-right"></i>
                        <span>The Evolution of Oneaichat.</span>
                      </a>
                    </li>
                  </ul>
                </div>
            {/* Post Category */}
                <div className="aai-sidebar-widget">
                  <h3 className="aai-sidebar-title">Category</h3>
                  <ul className="mt-4 aai-blog-lists d-flex flex-column gap-3">
                    <li className="aai-blog-list-item">
                      <a href="#" className="d-flex align-items-center gap-3">
                        <i className="fa-solid fa-angle-right"></i>
                        <span>Oneaichat Revolution(4)</span>
                      </a>
                    </li>
                    <li className="aai-blog-list-item">
                      <a href="#" className="d-flex align-items-center gap-3">
                        <i className="fa-solid fa-angle-right"></i>
                        <span>New Oneaichat</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    {/* Blog End */}
    {/* Cta Start */}
    <section
        className="aai-cta pb-120"
        // style={{
        //   background: "url('assets/img/bg/cta-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container" style={{"border":"1px solid #28c434","borderRadius":"25px"}}>
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg">
              <div className="secttionn">
                <h2 className="section-title mb-4">
                The Future Isn't What It Used To Be!&nbsp;
                  <span className="position-relative">
                  
                  </span>
                  
                </h2>
               
                <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                  <a href="/pricing" className="aai-btn btn-pill-solid">
                    Get Started
                  </a>
                  <a href="/about" className="aai-gradient-outline-btn">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-7 col-lg mt-5 mt-xl-0"
              data-aos="fade-up"
              data-aos-delay="80"
            >
              <div className="aai-cta-img">
                <img
                  src="assets/img/logo/2.jpeg"
                  className="img-fluuid"
                  alt=""
                  
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cta End */}
   </main>
  <Footer />
</div>
    )
}
export default Blog;