import { axiosInstance } from "config/axios";
import React, { useEffect, useState } from "react";
const Fail = () => {
  const [txnID, setTxnID] = useState("");
  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        // Get the transaction ID from the cookie
        const transactionId = getCookie("transactionId");
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        console.log("this is the testing invoice ", transactionId);
        console.log("this is the testing invoice ", authUser);
        // Make a POST request to the backend to get transaction details
        const response = await axiosInstance.post(
          "/api/failedtransactions/details",
          { transactionId, user_id: authUser?._id }
        );
        console.log("Response from /api/transactions/details:", response.data);
        setTxnID(response.data.transactionid);
      } catch (error) {
        console.error("Error fetching transaction details:", error);
      }
    };
    fetchTransactionDetails();
  }, []);
  // Function to get cookie by name
  const getCookie = (name) => {
    const match = document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]+)")
    );
    return match ? match[2] : null;
  };
  return (
    <div className="container py-5">
      <div className="text-center">
        <img
          src="/assets/img/logo/2.jpeg"
          alt="Logo"
          className="img-fluid"
          style={{ width: "175px", height: "165px" }}
        />
        <h2 className="my-4">Transaction Failed</h2>
        <p className="text-muted mb-4">
          Please note the transaction ID if your amount was debited and the
          transaction has failed.
        </p>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title text-danger">Payment Failure</h5>
              <table className="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td>Status Description:</td>
                    <td>
                      <strong>Payment Failed</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Payment Mode:</td>
                    <td>
                      <strong>Online Payment</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Transaction ID:</td>
                    <td>
                      <strong>{txnID}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <button
          className="btn btn-primary btn-lg"
          onClick={() => (window.location.href = "/")}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};
export default Fail;
