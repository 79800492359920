import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import "./Login.css";

const TermsUse = () => {
  const paragraphStyle = {
    fontSize: "16px", // Define the font size for all <p> tags
  };
  const subheading = {
    fontSize: "18px", // Define the font size for all <p> tags
  };
  return (
    <div>
      <Header />
      <main className="wrapper">
        {/* Breadcrumb Start */}
        <section
          className="aai-breadcrumb"
          // style={{
          //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover"
          // }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto">
                <div className="text-center">
                  <h1 className="aai-page-title">Terms Of Use</h1>
                  <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                    <li>
                      <a
                        href="/"
                        className="aai-breadcrumb-link text-decoration-underline"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 16L18 12M18 12L14 8M18 12L6 12"
                          stroke="#DADADA"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </li>

                    <li>
                      <a href="" className="aai-breadcrumb-link">
                        {" "}
                        Terms Of Use{" "}
                      </a>
                    </li>
                  </ul>
                  <div className="terms-use-class">
                    {/* <h>OneAIChat Terms of Use</h> */}
                    <p className="section-desc">Welcome to OneAIChat!</p>

                    <p className="section-desc">
                      These OneAIChat Terms of Use (“Terms”) are an agreement
                      entered between you and Kaletech, Private limited. and its
                      affiliates (collectively Kaletech,” “we,” or “us”) in
                      connection with your use of the OneAIChat service
                      ("OneAIChat"). In these Terms, "you" refers both to you as
                      an individual and to the entity you represent. By using
                      OneAIChat, you consent to these OneAIChat Terms of
                      Service.
                    </p>
                    <p className="section-desc">
                      Kaletech's Kaletech Platform Terms of Service also apply
                      to your use of OneAIChat. In the event of a conflict
                      between the Kaletech Platform Terms of Service and the
                      OneAIChat Terms of Service, the OneAIChat Terms of Service
                      will apply. If you purchase a subscription to OneAIChat,
                      then the Subscriber Terms shall apply as well. OneAIChat
                      is considered part of the “Kaletech Platform” for purposes
                      of the Kaletech Platform Terms of Service. Capitalized
                      terms used but not defined in these OneAIChat Terms of
                      Service have the meanings defined in the Kaletech Platform
                      Terms of Service.
                    </p>
                    <p className="section-desc">
                      Important arbitration notice: If you are in India, you
                      agree that disputes between you and Kaletech will be
                      resolved by binding, individual arbitration, and you waive
                      your right to participate in a class action lawsuit or
                      class-wide arbitration. We explain this process, some
                      exceptions, and how you can opt out of arbitration in
                      Section 10 of the Kaletech platform terms of service.
                    </p>

                    <ol>
                      <li>
                        <h>A Platform for Open Exploration (OneAIChat)</h>
                        <p className="section-desc">
                          OneAIChat is a platform that enables you to explore
                          and interact with various bots powered by third-party
                          Large Language Models (“LLMs”) and developers,
                          Kaletech Private limitedluding OpenAI and Anthropic.
                          OneAIChat may also allow you to create your own bots
                          powered by these third-party LLMs. You can use Bots
                          for a variety of purposes, from learning, writing
                          help, translation, programming help, summarization,
                          entertainment, to many other things. Each bot has its
                          own description in its profile and the name of the
                          company, developer, or individual who created the bot.
                          Usage guidelines for all bots can be found by visiting
                          https://oneaichat.com/ or in your settings. OneAIChat
                          is a platform that enables users to access various
                          third-party bots, but OneAIChat does not provide and
                          is not not responsible for the content or services
                          available from these bots.{" "}
                        </p>
                      </li>
                      <li>
                        <h>Age Requirements</h>
                        <p className="section-desc">
                          Use of OneAIChat by anyone under 13 years of age is
                          prohibited. You represent that you are at least the
                          age of majority in the jurisdiction where you live or,
                          if you are not, your parent or legal guardian must
                          consent to these OneAIChat Terms of Service and affirm
                          that they accept these Terms on your behalf and bear
                          responsibility for your use. Bots accessed via
                          OneAIChat may produce content that is not suitable for
                          minors. If you are accepting these OneAIChat Terms of
                          Service on behalf of someone else or an entity, you
                          confirm that you have the legal authority to bind that
                          person or entity to these Terms.{" "}
                        </p>
                      </li>
                      <li>
                        <h>Your Use of OneAiChat</h>
                        <p className="section-desc">
                          Subject to your compliance with these Terms, we grant
                          you a personal, non-exclusive, non-transferable right
                          to use OneAiChat and its bots.
                        </p>
                        <strong style={subheading}>
                          3.1 User Acknowledgments:
                        </strong>
                      </li>
                      <ol>
                        <li className="section-desc">
                          Bots accessed via OneAiChat should not be relied upon
                          during emergencies; bots may claim to perform actions
                          for you in the real world but may have not taken any
                          action besides responding to you;
                        </li>
                        <li className="section-desc">
                          Bots accessed via OneAiChat may not generate accurate
                          information and information provided may be out of
                          date. You should independently verify responses or
                          advice provided by any bots on OneAiChat. Kaletech
                          does not provide any warranties or guarantees as to
                          the accuracy of any information provided via
                          OneAiChat;
                        </li>
                        <li className="section-desc">
                          OneAiChat and bots on OneAiChat are subject to
                          modification and alteration, and may contain errors,
                          design flaws, or other issues;
                        </li>
                        <li className="section-desc">
                          Use of OneAiChat or bots on OneAiChat may result in
                          unexpected results, loss of data or communications, or
                          other anticipated or unanticipated damage or loss to
                          you;
                        </li>
                        <li className="section-desc">
                          OneAiChat or bots on OneAiChat may not operate
                          properly, be in final form, or be fully functional;
                          your access to OneAiChat or the bots is not guaranteed
                          and OneAiChat and the bots are provided on an AS IS
                          basis;
                        </li>
                      </ol>
                      <strong style={subheading}>3.2 Restricted Uses:</strong>{" "}
                      <br />
                      <span className="section-desc">
                        {" "}
                        You agree not to use OneAiChat or bots on OneAiChat to{" "}
                      </span>
                      :
                      <ol className="section-desc">
                        <li className="section-desc">
                          Violate the rights of another party or any applicable
                          laws;
                        </li>
                        <li className="section-desc">
                          Violate any of these Terms or our policies, or
                          policies of third-party providers that power the bots
                          on OneAiChat which can be found in the bot profile or
                          by visiting OneAiChat.com/about
                        </li>
                        <li className="section-desc">
                          Reverse assemble, reverse compile, decompile,
                          translate or otherwise attempt to discover the source
                          code or underlying components of models, algorithms,
                          and systems of OneAiChat, the bots, or their
                          underlying technologies;
                        </li>
                        <li className="section-desc">
                          Abuse, harm, interfere with, reverse engineer, or
                          disrupt OneAiChat, the bots, or their underlying
                          technologies, such as by accessing or using them in
                          fraudulent or deceptive ways, introducing malware, or
                          spamming, hacking, or bypassing any protective
                          measures;
                        </li>
                        <li className="section-desc">
                          Use OneAiChat or the bots in an automated fashion,
                          such as by exceeding any rate limits set forth by us
                          from time to time;
                        </li>
                        <li className="section-desc">
                          Use OneAiChat or the bots to develop products,
                          applications, services, foundation models or other
                          large scale models that compete with OneAiChat, the
                          bots, and their underlying technologies;
                        </li>
                        <li className="section-desc">
                          Use any method to extract data from OneAiChat or the
                          bots, Kaletech Private limitedluding web scraping, web
                          harvesting, or web data extraction methods, other than
                          as permitted by these OneAiChat Terms of Service; or
                        </li>
                        <li className="section-desc">
                          Represent that Bot-Generated Content (as defined
                          below) is human-generated.
                        </li>
                      </ol>
                      <li>
                        <h>Your Content</h>
                        <p className="section-desc">
                          <strong style={subheading}>4.1 Your Content:</strong>{" "}
                          <br />
                          <span>
                            {" "}
                            OneAiChat may allow you to ask questions, create
                            bookmarks, vote or like, post, or share bot
                            conversations within OneAiChat or outside of
                            OneAiChat. All material that you upload, publish, or
                            display within OneAiChat, and any results that you
                            receive in response to your prompts (or other
                            inputs) from bots accessible via OneAiChat
                            (“Bot-Generated Content”), will collectively be
                            considered “Your Content.” Other users may ask
                            questions and receive responses from bots on
                            OneAiChat that are similar to or the same as yours.
                            The content that other users receive are not
                            considered Your Content{" "}
                          </span>
                          :
                        </p>
                        <p className="section-desc">
                          <strong style={subheading}> 4.2 Your Bots: </strong>
                          <br />
                          <span>
                            {" "}
                            OneAiChat may allow you to create bots powered by
                            third-party LLMs or add your own bot to the
                            OneAiChat platform for users to interact with (each,
                            a “Your Bots”). Your Bots will be associated with
                            your OneAiChat profile name. You have no obligation
                            to create a bot, but if you choose to create a bot
                            you are responsible for ensuring the content is
                            lawful and does not violate these Terms, our
                            policies or the policies of any third-party LLMs
                            which power your bot. Kaletech may, in its sole
                            discretion, remove your bot if it violates these
                            Terms, our policies or the policies of any
                            third-party LLMs which power your bot or applicable
                            law. Kaletech takes no responsibility for your bots,
                            any of its content, or others use of your bots. If
                            you choose to connect Your Bots to OneAiChat using
                            our APIs, then the OneAiChat API Terms apply to your
                            use of such APIs. If you choose to participate in
                            the Creator Monetization Program, then the program
                            terms will apply to your participation in the
                            program.{" "}
                          </span>
                        </p>

                        <p className="section-desc">
                          <strong style={subheading}>4.3 Ownership:</strong>{" "}
                          <br />
                          <span>
                            You retain ownership of Your Content, subject to the
                            non-exclusive rights granted below.
                          </span>
                        </p>

                        <p className="section-desc">
                          <strong style={subheading}>
                            4.4 Your Responsibility:
                          </strong>{" "}
                          <br />
                          <span>
                            {" "}
                            You acknowledge and agree bots accessible via
                            OneAiChat answer your questions based on knowledge
                            derived from a variety of sources, and that Kaletech
                            does not create or generate any Bot-Generated
                            Content. OneAiChat provides access to several
                            underlying technologies, Kaletech Private
                            limitedluding third-party providers that use LLMs.
                            An LLM is a machine learning system that processes
                            and generates text. You agree that you are
                            responsible for Your Content and Your Bots, Kaletech
                            Private limitedluding for ensuring that they do not
                            violate any applicable law, these Terms (Kaletech
                            Private limitedluding the Restricted Uses in Section
                            3.3 above), our policies, or the policies of any
                            third-party LLMs which power bots within OneAiChat.
                            We reserve the right to block, remove, and/or
                            permanently delete Your Content or Your Bots if they
                            are in breach of these Terms, our policies, the
                            policies of any third-party LLMs which power bots
                            within OneAiChat, or violate any applicable law or
                            regulation, or if it creates risk for Kaletech or
                            OneAiChat or negatively impacts the experience other
                            OneAiChat users.{" "}
                          </span>
                        </p>
                        <p className="section-desc">
                          <strong style={subheading}>
                            4.5 Our Use of Your Content:
                          </strong>{" "}
                          <br />
                          <span>
                            {" "}
                            We may use Your Content and Your Bots to provide and
                            improve OneAiChat, Kaletech Private limitedluding:{" "}
                          </span>
                        </p>
                        <ol>
                          <li className="section-desc">
                            Providing your questions and prompts to third-party
                            LLMs to generate responses
                          </li>
                          <li className="section-desc">
                            Displaying Your Content to others if you use the
                            sharing features in OneAiChat
                          </li>
                          <li className="section-desc">
                            Making available Your Bots for others to use and
                            interact with within OneAiChat
                          </li>
                          <li className="section-desc">
                            Promoting your shared content or Your Bots to others
                          </li>
                          <li className="section-desc">
                            Understanding your use of OneAiChat to generally
                            improve the OneAiChat services
                          </li>
                        </ol>
                        <p className="section-desc">
                          We may also need to use or disclose Your Content to
                          comply with applicable laws, enforce these OneAiChat
                          Terms of Service and our policies, and to detect and
                          prevent fraud, security, or technical issues. By using
                          OneAiChat, you grant us a worldwide, non-exclusive,
                          royalty-free, transferable, and perpetual license to
                          use Your Content and Your Bots as stated above.
                        </p>

                        <li>
                          <h>Termination</h>
                          <p className="section-desc">
                            You may cease your use of OneAiChat or terminate
                            these OneAiChat Terms of Service at any time for any
                            reason or no reason by deleting your account in your
                            settings. We may terminate your access to OneAiChat
                            and/or these OneAiChat Terms of Service at any time
                            for any reason or no reason. Any Data collected
                            prior to termination may continue to be used in
                            accordance with these Terms. The following
                            provisions will survive expiration of termination of
                            these Terms, in addition to the provisions set forth
                            in Section 11(e) of the Kaletech Platform Terms of
                            Service: Section 3.4 (Feedback), Section 4.3, 4.4
                            and 4.5 (Your Content), this Section 5
                            (Termination), Section 7 (Disclaimers and Limitation
                            of Liability) and Section 8 (General).
                          </p>
                        </li>

                        <li>
                          <h>Privacy</h>
                          <p className="section-desc">
                            As detailed in the OneAiChat Privacy Policy, we may
                            collect certain personal information related to your
                            use of OneAiChat (Kaletech Private limitedluding
                            contact information, etc.). Your account information
                            is anonymized before being shared with third party
                            LLM providers and developers. The third party
                            developers and LLM providers may receive details
                            about your interactions with bots on OneAiChat
                            (Kaletech Private limitedluding the contents of your
                            chats, upvotes, etc.) to provide responses and to
                            generally improve the services. There is no need to
                            share sensitive personal information with the bots
                            (such as credit card information, social security
                            information, etc.). For more information about the
                            third party LLM providers, please see the respective
                            bot profiles. For more information about OneAiChat’s
                            privacy practices, please visit the OneAiChat
                            Privacy Policy.
                          </p>
                        </li>

                        <li>
                          <h>Disclaimers and limitation of liability</h>
                          <p className="section-desc">
                            Section 8 of the Kaletech Platform Terms of Service
                            is Kaletech Private limitedorporated herein by
                            reference, except that Section 8(e) of the Kaletech
                            Platform Terms of Service is replaced with the
                            following: Without limiting the foregoing,
                            Kaletech’s maximum aggregate liability to you for
                            losses or damages that you suffer related to or
                            arising out of your use of the OneAiChat services or
                            these terms is limited to the amount you paid to
                            Kaletech in the prior twelve (12) months for the
                            OneAiChat services giving rise to the claim. We
                            cannot guarantee the availability of any bots,
                            features, or services of OneAiChat or available
                            through OneAiChat. While we are continuing to
                            develop new features, add bots, and improve
                            OneAiChat, we may, without notice to you, add or
                            remove features or bots, limit the use or
                            availability of features or bots entirely (or just
                            in certain places or for some users) if they create
                            risk to Kaletech, users of OneAiChat, third parties
                            powering the bots, no longer make sense from a
                            business perspective, or for any reason in our sole
                            discretion. We also cannot guarantee that your use
                            of OneAiChat will be error-free, uninterrupted, or
                            that you will not experience service outages. We are
                            not liable for any errors, disruptions, or service
                            outages that may occur.
                          </p>
                        </li>

                        <li>
                          <h>General</h>
                          <p className="section-desc">
                            You agree that any claim not subject to Arbitration
                            as set forth in Section 10 of the Kaletech Platform
                            Terms of Service must be resolved in a competent
                            court in India (excluding conflict of laws rules)
                            will govern these Terms and any claim. These Terms
                            will be binding upon and will inure to the benefit
                            of your and our heirs, successors, and/or assignees.
                          </p>
                        </li>
                        <p className="section-desc">
                          Use of OneAIChat by anyone under 13 years of age is
                          prohibited. You represent that you are at least the
                          age of majority in the jurisdiction where you live or,
                          if you are not, your parent or legal guardian must
                          consent to these OneAIChat Terms of Service and affirm
                          that they accept these Terms on your behalf and bear
                          responsibility for your use. Bots accessed via
                          OneAIChat may produce content that is not suitable for
                          minors. If you are accepting these OneAIChat Terms of
                          Service on behalf of someone else or an entity, you
                          confirm that you have the legal authority to bind that
                          person or entity to these Terms.{" "}
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Breadcrumb End */}
      </main>
      <Footer />
    </div>
  );
};
export default TermsUse;
