import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteTopScroll from "components/InfiniteTopScroll";
import useGetPaginatedRooms from "hooks/useGetPaginatedRooms";
import useChatRoomByUser from "hooks/useChatRoomByUser";
import styles from "./Rooms.module.css";

const Rooms = (props) => {
  const { ai, onClose } = props;
  const navigate = useNavigate();
  const userId = props.userId;
  const { roomId } = useParams();
  console.log(
    useParams(),
    "useParams----------------------------------------------"
  );
  const [isRoomSelected, setRoomSelected] = useState("");
  const {
    data: rooms,
    isLoading: isRoomLoading,
    fetchNextPage: fetchNextRoomPage,
    isFetching: isRoomFetching,
    hasNextPage: hasNextRoomPage,
  } = useChatRoomByUser(userId, ai);
  // } = useGetPaginatedRooms(userId);

  const isRoomLoader = isRoomFetching || isRoomLoading;
  const chat_rooms = rooms?.data?.rooms;
  const openRoomHandler = (roomId, chatbot, category) => {
    if (roomId) {
      navigate(`/chat/${roomId}`, {
        replace: true,
        state: { roomCategory: category },
      });
    } else {
      navigate(`/chat`, { replace: true });
    }
  };

  useEffect(() => {
    setRoomSelected(roomId);
  }, [roomId]);

  let endMessage = <div />;

  if (!hasNextRoomPage && rooms?.length > 20) {
    endMessage = <div className={styles.endMessage}>No More Records</div>;
  } else if (isRoomLoader) {
    endMessage = (
      <div className={styles.loader}>
        <i className="fa fa-spinner fa-pulse" />
      </div>
    );
  } else if (!rooms?.length && !isRoomLoader) {
    endMessage = <div className={styles.endMessage}>No Record Found</div>;
  }

  return (
    <div className={styles.roombar}>
      <div className={styles.header}>
        <h4>Rooms</h4>

        <button
          className={styles.closeButton}
          data-title="close"
          onClick={onClose}
        >
          <i className="fa fa-close" />
        </button>
      </div>

      <div
        className={`${styles.roomTitle} bg-success`}
        onClick={() => openRoomHandler()}
      >
        New Room
      </div>
      <InfiniteTopScroll
        id="chat-rooms"
        inverse={false}
        endMessage={endMessage}
        // items={(rooms ?? [])?.map((room) => {
        items={(chat_rooms ?? [])?.map((room) => {
          return (
            <div
              key={room?._id}
              className={`${styles.roomTitle} ${
                isRoomSelected === room?._id ? "bg-success" : ""
              }`}
              onClick={() =>
                openRoomHandler(room?._id, room?.chatbot, room?.category)
              }
            >
              {room?.title}
            </div>
          );
        })}
        next={fetchNextRoomPage}
        hasMore={hasNextRoomPage}
      />
    </div>
  );
};

export default Rooms;
