import React from "react";
import Link from "components/Link";
import UserInfo from "./UserInfo";
import useGetAuthUser from "hooks/useGetAuthUser";
import { getUserName } from "utils/general";
import { useState,useEffect,useRef } from 'react';
import {  useLocation } from 'react-router-dom';

const Header = (props) => {
   const {  className, } = props;
   const authUser = useGetAuthUser();
   const [activeLink, setActiveLink] = useState('signup'); // default to signup
   const location = useLocation();
   const navbarRef = useRef(null);
   const [showMenu, setShowMenu] = useState(true);
  useEffect(() => {
    if (location.pathname === "/login") {
      setActiveLink('login');
    } else if (location.pathname === "/register") {
      setActiveLink('signup');
    }
  }, [location.pathname]); // Re-run the effect when pathname changes

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isMobile = window.innerWidth <= 767; // Adjust the breakpoint as needed for your design
      if (
        isMobile &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        !event.target.classList.contains('navbar-toggler') &&
        !(event.target.closest('.navbar-collapse.show'))
      ) {
        // Clicked outside the navbar or the toggle button, close it for mobile view
        setShowMenu(false);
      }
    };
    
    

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [navbarRef, setShowMenu]); // Include setShowMenu in the dependencies array
const togglemenu=()=>{
  setShowMenu(true);
}

  return (
    <header className={className}>
      <div className="container">
        <nav className="navbar navbar-expand-xl justify-content-between" ref={navbarRef}>
        <div className="logo">
          <Link to="/">
            <img src="/assets/img/logo/2.jpeg" alt="logo"  style={{ width: '70px', height: '75px', display: 'block',borderRadius:"35px"  }} />
          </Link>
          </div>
          {showMenu && (
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mx-auto" style={{"position":"relative","top":"2rem"}}>
                <li className="d-block d-xl-none">
                  <div className="logoo">
                    <Link to="/">
                    <img src="/assets/img/logo/2.jpeg" alt="logo"  style={{ width: '70px', height: '75px', display: 'block',borderRadius:"35px"}} />
                    </Link>
                  </div>
                </li>
              
                <li className="Staticnm" style={{
    color: "black",
    position: "fixed",
   
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "40px",
    fontWeight: "bold"
}}>
    OneAIChat
</li>

                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                  
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About Us
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/services">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/pricing">
                    Subscriptions
                  </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link" to="/blog">Blog</Link>
               </li> 
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
              <br/>
              <div className="d-flex align-items-center gap-4 mt-4">
                {authUser ? (
                  <div className="d-flex d-lg-none">
                    <UserInfo
                      name={getUserName(authUser)}
                      userId={authUser?._id}
                    
                    />
                  </div>
                ) : (
                  <div className="align-items-center aai-signup-in-links d-flex d-lg-none">
                    <Link to="/login" className="aai-gradient-outline-btn">Login / Signup</Link>
                    
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="navbar-right d-flex align-items-center gap-4">
            {authUser ? (
              <div className="d-none d-lg-flex">
                <UserInfo name={getUserName(authUser)} userId={authUser?._id} />
              </div>
            ) : (
              <div className="align-items-center aai-signup-in-links d-none d-lg-flex">
              <Link 
                to="/login" 
                className={ 'aai-gradient-outline-btn' } >
                Login / Signup
              </Link>
            </div>
            )}
            <button
            // className={`navbar-toggler d-block d-xl-none `}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={showMenu ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={togglemenu}
          >
            <span className="navbar-toggler-icon" style={{width:"2.5em"}}></span>
          </button>
                    </div>
                  </nav>
                </div>
              </header>
            );
};

Header.defaultProps = {
  className: "header-primary",
  showMenu: true,
  
};

export default Header;
