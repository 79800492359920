// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfiniteTopScroll_root__V8In3 {
  height: 94vh;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.InfiniteTopScroll_infiniteScroll__4etGq {
  width: 100%;
}

.InfiniteTopScroll_infiniteTopScroll__cyFIp {
  display: flex;
  flex-direction: column-reverse;
}

.InfiniteTopScroll_endMessage__LroF3 {
  text-align: center;
  font-size: 12px;
}

.InfiniteTopScroll_loader__3sLSO {
  text-align: center;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/InfiniteTopScroll/InfiniteTopScroll.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".root {\n  height: 94vh;\n  width: 100%;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n}\n\n.infiniteScroll {\n  width: 100%;\n}\n\n.infiniteTopScroll {\n  display: flex;\n  flex-direction: column-reverse;\n}\n\n.endMessage {\n  text-align: center;\n  font-size: 12px;\n}\n\n.loader {\n  text-align: center;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `InfiniteTopScroll_root__V8In3`,
	"infiniteScroll": `InfiniteTopScroll_infiniteScroll__4etGq`,
	"infiniteTopScroll": `InfiniteTopScroll_infiniteTopScroll__cyFIp`,
	"endMessage": `InfiniteTopScroll_endMessage__LroF3`,
	"loader": `InfiniteTopScroll_loader__3sLSO`
};
export default ___CSS_LOADER_EXPORT___;
