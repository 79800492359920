import React from "react";
import Header from "../components/Header"; // Adjust the import path as necessary
import Footer from "../components/Footer"; // Adjust the import path as necessary

const FAQs = () => {
  return (
    <div>
      <Header />
      <main className="wrapper">
        <section className="aai-faq py-120">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-5 col-lg">
                <div className="secttionn">
                  <h2 className="section-title mb-4">
                    Any <span className="gradient-text">Questions?</span> <br />
                    We have Answers!
                  </h2>
                  <p className="section-desc" style={{ textAlign: "center" }}>
                    <b>Don't find answers here? </b>
                  </p>
                  <div className="mt-5" style={{ marginLeft: "12rem" }}>
                    <a href="/contact" className="aai-gradient-outline-btn">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-6 col-lg mt-5 mt-xl-0"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="aai-accordions">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button shadow-none collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is OneAI-Chat?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="aai-accordion-text">
                            Deep learning, Machine learning and Artificial
                            Intelligence service company. Give a spin to our
                            native bot 'Somebody'.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button shadow-none"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          Tell me more about OneAI-Chat?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="aai-accordion-text">
                            OneAI-Chat is an AI aggregation platform with
                            multimodal offerings, distributed in focused
                            categories.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button shadow-none collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Why do I need this?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="aai-accordion-text">
                            To get brief answers to the big questions.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button shadow-none collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Why do I pay for this?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="aai-accordion-text">
                            Can get answers from OneAI's category focused
                            services and best in class global AI services, along
                            with necessary features, all on one platform.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button shadow-none collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Tell me more about features?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p className="aai-accordion-text">
                            Subscription service offers you to upload a
                            document, copy a message, share a message from
                            focused category. <br />
                            'Uploading a document' provides you to extract
                            important outputs from your own huge datasets or
                            files. 'Copying a message' adds on to your
                            productivity. <br />
                            'Sharing a message' adds on to your comfort.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default FAQs;
