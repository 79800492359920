import { axiosInstance } from 'config/axios';
import React, { useEffect, useState,Link} from 'react';

const Callback = () => {
  const [transactionDetails, setTransactionDetails] = useState(null);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        // Get the transaction ID from the cookie
        const transactionId = getCookie('transactionId');
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        // Make a POST request to the backend to get transaction details
        const response = await axiosInstance.post('/api/transactions/details', { transactionId,user_id: authUser?._id });
        console.log('Response from /api/transactions/details:', response.data);
        // Set the transaction details in the state
        setTransactionDetails(response.data);
      } catch (error) {
        console.error('Error fetching transaction details:', error);
      }
    };

    fetchTransactionDetails();
  }, []);

  // Function to get cookie by name
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  };

  return (
    <>
    <div>
      {transactionDetails ? (
        <div>
         
          <center>
            <img src="/assets/img/logo/2.jpeg" alt="logo" style={{ width: '175px', height: '165px', display: 'block' }} />
          </center>
          <table>
            <tbody>
              <tr>
                <td>Status Description:</td>
                <td>{transactionDetails.payment_sttatus|| transactionDetails.code}</td>
              </tr>
              <tr>
                <td>Payment Mode:</td>
                <td>Online Payment</td>
              </tr>
              <tr>
                <td>Transaction ID:</td>
                <td>{transactionDetails.transaction_id}</td>
              </tr>
              <tr>
                <td>Order ID:</td>
                <td>{transactionDetails.order_id ||transactionDetails.providerReferenceId}</td>
              </tr>
              <tr>
                <td>Customer Email:</td>
                <td>{transactionDetails.email}</td>
              </tr>
              <tr>
                <td>Transaction Date:</td>
                <td>{transactionDetails.transaction_date}</td>
              </tr>
              <tr>
                <td>Plan Price:</td>
                <td>{transactionDetails.amount}</td>
              </tr>
              <tr>
                <td>Authorization Status:</td>
                <td>{transactionDetails.authStatus|| '0300'}</td>
              </tr>
              <tr>
                <td>Plan Name:</td>
                <td>{transactionDetails.plan_name ||transactionDetails.planName}</td>
              </tr>
              <tr>
                <td>Plan Id:</td>
                <td>{transactionDetails.plan_id ||transactionDetails.planId}</td>
              </tr>
              <tr>
                <td>Start Date:</td>
                <td>{transactionDetails.startDate}</td>
              </tr>
              <tr>
                <td>End Date:</td>
                <td>{transactionDetails.endDate||"na"}</td>
              </tr>
              <tr>
                <td>Payment Method:</td>
                <td>{transactionDetails.payment_method}</td>
              </tr>
            </tbody>
          </table>
          
          {/* Add other fields as needed */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
               <center>
              <button className="retrieve-button"  onClick={() => window.location.href = '/'} >Back To Home</button>
              </center>
    </div>
    <script>
      {`
        fbq('track', 'Purchase', {currency: "USD", value: 1099.00});
        fbq('track', 'Purchase', {currency: "USD", value: 3099.00});
        fbq('track', 'Purchase', {currency: "USD", value: 7099.00});
        fbq('track', 'Purchase', {currency: "USD", value: 20999.00});
      `}
    </script>
    </>
  );
};

export default Callback;
