import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import './Login.css';


 const TermsUse = () => {
return(
 <div>
    {/* <Header /> */}
    <h1>HIi</h1>
 </div>
    )
 }
 export default TermsUse;