export const depricated=[
    {
        model:"Gpt-3.5-Turbo-0301",
        message:"OpenAI has discontinued this model from 13-09-2024 "
    },
    {
        model:"GPT-3.5-Turbo-0613",
        message:"OpenAI has discontinued this model from 13-09-2024 "
    },
    {
        model:"Gpt-3.5-Turbo-16k-0613",
        message:"OpenAI has discontinued this model from 13-09-2024 "
    },
    {
        model:"Google Palm",
        message:"Google AI  has discontinued this model from 20-08-2024 "
    },
]