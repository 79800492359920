import React from "react";

const FreeChat = (props) => {
  const { chatcount, isSubscribed } = props;
  const leftChatAttempt = 10 - chatcount; // No need to parse again, as chatcount is already an integer

  console.log(chatcount, "leftChatAttempt");
  console.log("subscription", isSubscribed);
  return (
    <div className="row align-items-center free-chat">
      {!isSubscribed && (
        <div>
          <span style={{ color: "#cb9512" }}>
            Daily (free) - {leftChatAttempt} left
          </span>
        </div>
      )}
    </div>
  );
};

export default FreeChat;
